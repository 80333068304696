.offerCardLink {
  max-width: unset;
  width: 100%;
  display: block;
  padding: 0;
  border: none;
}

.offersCardWrapper {
  background-color: var(--color-white);

  border: var(--border-base);
  border-radius: 8px;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .offersCardWrapper {
    display: flex;
    background-color: var(--color-white);

    border: var(--border-base);
    border-radius: 8px;
  }
}

.offersCardInfo {
  padding: 12px;
  width: 100%;
  min-height: 127px;
}

@media (min-width: 1024px) {
  .offersCardInfo {
    padding: 20px;
    width: calc(100% - 290px);
  }
}

.offersCardInfoHeader {
  margin-bottom: 6px;
}

@media (min-width: 1024px) {
  .offersCardInfoHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 10px;
  }
}

.offersCardInfoHeaderStats {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;

  color: #b9b9b9;
}

.offersCardInfoTitleWrapper {
  display: flex;
  align-items: center;
}

@media (min-width: 1024px) {
  .offersCardInfoTitleWrapper {
    max-width: 60%;
    width: 100%;
  }
}

.offersCardInfoDescription {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #404042;
  margin-bottom: 8px;
}

@media (min-width: 1024px) {
  .offersCardInfoDescription {
    margin-bottom: 15px;
  }
}

.offersCardStats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 12px;
  background-color: #f3f3f5;
  text-decoration: none;
  border-top: var(--border-base);
}

@media (min-width: 1024px) {
  .offersCardStats {
    width: 290px;
    padding: 20px;
    border-top: none;
  }
}

.offersCardStatsInfo {
  margin-right: 20px;
}

.offersCardStatsCount {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #404042;
}

.offersCardStatsPrice {
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;

  color: #000000;
}
