.input {
  background-color: var(--color-white);
  border: var(--border-base);
  border-radius: var(--border-radius-size-m);

  height: 40px;
  width: 100%;
  display: flex;

  position: relative;
}

.inputDisabled {
  background-color: #f3f3f5;
  cursor: not-allowed;
}
.inputDisabled .inputTag {
  color: #b9b9b9;
}

.inputTag::placeholder {
  color: rgba(64, 64, 64, 0.3);
}

.inputTag {
  padding: 12px;
  margin: 0;
  display: block;
  height: 100%;
  overflow: visible;
  border: 0;
  border-radius: 8px;
}

.inputTagIcon {
  padding-left: 0;
}

.customIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 4px 8px 8px;
}
