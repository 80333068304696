.profileMenuHeader {
  margin-bottom: 20px;

  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
}

@media (max-width: 768px) {
  .profileMenuHeader {
    margin-bottom: 8px;
  }
}

.profileMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #DFDFEB;
  padding-left: 50px;
  margin-top: 80px;
  margin-bottom: 50px;
  padding-right: 24px;
}

@media (max-width: 768px) {
  .profileMenu {
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 0;
  }
}


@media (max-width: 1024px) {
  .profileMenu {
    padding-left: 20px;
  }
}

@media (max-width: 768px) {
  .profileMenu {
    border: none;
    /*background-color:#F3F3F5;*/
    padding: 12px;
  }
}

.profileMenuList {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
}

.profileMenuList .profileMenuLinkActive {
  color: #404042;
  text-decoration: none;

  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.profileMenuList .profileMenuLink {
  padding: 5px 0;
  min-height: unset;
  font-size: 16px;
  line-height: 24px;
}

.activeMenu {
  color: #404042 !important;
}
