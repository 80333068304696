.tabListMain {
  width: 100%;
  min-width: 150px;
  margin-bottom: 8px;
}

.container {
  position: relative;
}

.filtersHeaderBottom {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}