.listItem {
    position: relative;
    border: none;
    background-color: transparent;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    cursor: pointer;
    text-align: left;
    text-decoration: none;
    color: #404042;
}

.listItem:visited {
    color: #404042;
}

.listItemIcon {
    margin-top: 3px;
    margin-right: 4px;
    height: min-content;
    display: inherit;
}

.listItemContent {
    flex-grow: 1;
}

.listItemBadge {
    margin-top: 2px;
    margin-left: 8px;
    height: min-content;
    display: inherit;
}

.listItem:global(.active) .listItemContent span {
  color: #4E6AFF;
}

.listItem:hover .listItemContent span {
  transition: all 0.3s ease;
  color: #4E6AFF;
}

.listItem:global(.active) {
  position: relative;
}

.listItem:global(.active)::before {
  content: "";
  display: block;
  width: 100%;
  height: 5px;
  background: #4e6aff;
  transition: all 0.3s ease;
  border-radius: 11px 11px 0 0;
  position: absolute;
  left: 0;
  bottom: -6px;
}
