
.wrapper {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    height: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;
    bottom: -1px;
}

.map {
    background-color: #FFFFFF;
    padding: 10px;
    width: 100%;
    height: calc(var(--vh-100) - 50px) !important;
    margin: 0 auto;
}
