
.desktopButtons {
  background-color: var(--background-color);
  display: none;
  justify-content: space-between;
  padding: 16px 32px 32px;
  border-radius: 0 0 24px 24px;
  border-top: 1px solid var(--border-filter-bottom);
}

.desktopButtonReset {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--border-icon-color);
  padding: 8px 12px;
  background: transparent;
  span {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
  }
}

.desktopButtonShow {
  border-radius: 8px;
  background: #445EE7;
  padding: 8px 12px;
}

@media (min-width: 1024px){
  .desktopButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
  }
}

.desktopOnly {
  display: none;
}

@media screen and (min-width: 1024px) {
  .desktopOnly {
    display: block;
  }
}


.mobileButtons {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 4px 16px 16px;
  display: grid;
  gap: 8px;
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);
  background: var(--background-color);
}

@media (min-width: 1024px){
  .mobileButtons {
    display: none;
  }
}
