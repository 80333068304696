.apartmentInfo {
  width: 100%;
  padding: 16px 32px 50px;
  background-color: #ffffff;
  page-break-inside: avoid;
  break-inside: avoid;
}

.note {
  color: #AFB4CB;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 24px;
}

.details {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 48px 24px;
  background-color: #F3F3F5;
  padding: 24px 32px 40px;
  border-radius: 24px;
}

.item {
  text-align: left;
}

.label {
  color: #333338;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 9px;
}

.value {
  color: #333338;
  font-size: 35px;
  font-weight: 700;
  line-height: normal;
}
