.complexPageModal {
  width: 100%;
}

@media (max-width: 1024px) {
  .complexPageModal {
    padding: 0 !important;
  }
}

.root {
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
}

.contentRoot {
  padding: 20px 40px 40px 40px;
  overflow-x: hidden;
}

@media(max-width: 768px) {
  .contentRoot {
    padding: 20px;
  }
}

@media(max-width: 540px) {
  .contentRoot {
    padding: 15px;
  }
}

.tagsWrapper {
  padding-left: 20px;
}


.mainInfoWrapper {
  display: grid;
  grid-column-gap: 60px;
  grid-template-columns: 100%;
  grid-template-areas:
        "complexInfo"
        "salesDepartmentsCard"
        "complexDescription"
}

@media (min-width: 1024px) {
  .mainInfoWrapper {
    padding-bottom: 20px;
    grid-column-gap: 30px;
    grid-template-columns: auto 320px;
    grid-template-areas:
        "complexInfo salesDepartmentsCard"
        "complexInfo salesDepartmentsCard"
        "complexDescription complexDescription"
  }
}

@media (min-width: 1280px) {
  .mainInfoWrapper {
    padding-bottom: 20px;
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: auto 320px;
    grid-template-areas:
        "complexInfo salesDepartmentsCard"
        "complexDescription salesDepartmentsCard";
  }
}

.complexPage_wrapper_complexInfo {
  grid-area: complexInfo;
}

.descriptionAndCatalog_wrapper {
  grid-area: complexDescription;
}

.descriptionAndCatalog_description_wrapper {
  margin-bottom: 40px;
}

.mainInfoHalf {
  width: 100%;
}

.salesDepartmentsCard_wrapper {
  grid-area: salesDepartmentsCard;
  width: 100%;
}


.toMainPageLink,
.toFlatsLink {
  display: none;
}

.map {
  width: 100%;
  height: calc(var(--vh) * 100 - var(--layout-header-height) - var(--layout-building-tabs-height));
  background-color: #fff;
}

@media (max-width: 1024px) {
  .toMainPageLink {
    display: block;
    order: -1;
    background-color: #F3F3F5;
  }

  .toFlatsLink {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: Circe;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #4E6AFF;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;

    margin: 8px 20px;
    max-width: max-content;
  }

  .buttonClosePopup {
    display: none;
  }
}
