.modalContent {
  --modal-width: 400px;
  width: var(--modal-width);
  height: 100%;
  background-color: var(--cottage-view-background);
}
.modalOverlay {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: var(--z-index-3);
  /* 80px - header height */
  height: calc(100vh - var(--layout-header-height) - var(--navbar-height));
}

.closeButton {
  position: absolute;
  top: 40px;
  left: -51px;
  transform: translateY(-50%);
  z-index: var(--z-index-3);
  border: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  filter: drop-shadow(-5px 0px 5px rgba(0, 0, 0, 0.15));
}

.simplebar {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  height: 100%;
}