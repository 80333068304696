.downloadAppPage {
  width: 100%;
  height: calc(var(--vh-100) - 52px);
  display: flex;
  flex-direction: column;
  background: #F1F1F1;
  text-align: center;
  padding-top: 12px;
  overflow: auto;
}

.tablistContainer {
  margin: 0 auto;
  padding: 2px 6px;
}

.indicator {
  background: #F1F1F1;
}

.tabList {
  background: #FFFFFF;
  padding: 2px 4px;
  min-width: 240px;
}

.tabBtn {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
}