@value label from "../FormFieldLabel/styles.module.css";
@value input from "../Input/styles.module.css";
@value textarea from "../Textarea/styles.module.css";

.isError .label {
    color: var(--color-red);
}

.isError .input {
    border-color: var(--color-red);
}
.isError .textarea {
    border-color: var(--color-red);
}

.errorMessage {
    display: inherit;
    margin-top: var(--indent-5);
    color: var(--color-red);
}
