.editNotesForClientWrapper {
  position: relative;
  margin-bottom: 20px;
}

.editOfferLoader {
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 16px;
  height: 16px;
}