.copyLinkFormWrapper {
  display: grid;
  gap: 8px;
  grid-template-columns: 100%;
}


@media (min-width: 1024px) {
  .copyLinkFormWrapper {
    display: grid;
    gap: 10px;
    grid-template-columns: auto 180px;
  }
}

@media (orientation: landscape) {
  .copyLinkFormWrapper {
    display: grid;
    gap: 10px;
    grid-template-columns: auto 180px;
  }
}