.transparent {
    background-color: transparent;
    border-color: transparent;
    color: #404042;
}

.default {
    border-style: solid;
    border-color: var(--border-filter-bottom);
    background-color: var(--background-color);
    color: #404042;
}

.disable {
    border-style: solid;
    border-color: #DFDFEB;
    background-color: #fff;
    opacity: 0.3;
    color: #404042;
}

.primary {
    border: 0;
    background-color: #4E6AFF;
    color: #ffffff;
}

.normal {
    border: 0;
    background-color: #F3F3F5;
    color: #404042;
}


.primary[disabled], .default[disabled], .normal[disabled] , .transparent[disabled] {
    pointer-events: none;
    opacity: 0.3;
}

.text {
    /*padding: 0;*/
    min-height: auto;
    border: none;
    background-color: transparent;
    border-radius: 0;
    text-decoration: none;

    font-style: normal;
    font-weight: bold;
    font-size: var(--size-text-m);
    line-height: var(--size-text-l);
}


.text.default {
    color: #000000;
}

.text.primary {
    color: var(--color-blue);
}

.text.alert {
    color: var(--color-red);
}
