.village {
  font-family: Inter;
  background: var(--village-wrapper-background);
  padding: 5px 20px 24px;
  height: calc(100vh - 134px);
  overflow: scroll;
  @media screen and (max-width: 768px) {
    padding: 0 0 32px;
    height: calc(var(--vh-100) - 205px);
  }
}

.villageFont {
  font-family: Inter;
  background: var(--village-wrapper-background);
}

