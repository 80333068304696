.wrapper {
  display: block;
  width: 100%;
  max-width: 700px;
  height: calc(var(--vh-100) - 80px);
  background-color: var(--background-color);
  margin: 40px auto;
  border-radius: 24px;
  @media screen and (max-width: 768px) {
    margin-top: 0;
    height: auto;
    padding-top: 8px;
    border-radius: 0;
  }
}

.modalOverlay {
  z-index: var(--z-index-14);
  position: fixed;
  inset: 0;
  top: 0;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
}

.modal {
  padding: 0;
  width: 100%;
}

.filtersWrapper {
  width: inherit;
  padding: 0;
  font-family: Inter;
  box-sizing: border-box;
  margin-top: 68px;
}
@media (min-width: 1024px){
  .filtersWrapper {
    padding: 32px 0 0;
    margin-top: 0;
  }
}

.headerMobileFilters {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 1;
}

.mobileFiltersReset {
  position: absolute;
  top: 0;
  right: 16px;
  color: #4E6AFF;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

