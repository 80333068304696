.main {
  height: 100%;
}

.list {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 8px;
  padding: 20px 0 10px;
}

@media (min-width: 768px) {
  .list {
    gap: 10px;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
}

@media (min-width: 1024px) {
  .list {
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 40px;
  }
}
@media (min-width: 1440px) {
  .list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1600px) {
  .list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.listItem {
  width: 100%;
  max-width: 499px;
  list-style: none;
}

.imageWrapper {
  display: flex;
  position: relative;
  height: 300px;
  overflow: hidden;
}

.image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.apartmentsFavoritesImage {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 8px;
  border-radius: 24px;
  background: #F3F3F5;
}

.apartmentsFavoritesImage span {
  display: flex;
  width: 24px;
  height: 24px;
}

.apartmentsFavoritesImage span img {
  width: 24px;
  height: 24px;
  max-width: 24px;
}

.initial path:first-of-type {
  fill: none;
}

.initial path:last-of-type {
  stroke: #ffffff;
}


.card {
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #F3F3F5;
  background: #FFF;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
}

.infoWrapper {
  padding: 24px 0 0;
}

.squareListWrapper {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  padding-left: 0;
}

.squareListWrapper li {
  text-align: center;
}

.squareItemLabel {
  color: #AFB4CB;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.squareItemContent {
  color: var(--text-color);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
}

@media (min-width: 768px) {
  .squareItemContent {
    font-size: 16px;
    line-height: 24px;
  }
}

.mainApartmentInfo {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 8px;
}

.right {
  justify-self: end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.left {
  display: flex;
  flex-direction: column;
  justify-self: end;
  align-items: flex-start;
}

.status {
  color: #FFF;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
  text-transform: uppercase;
}

.status div div {
  padding: 4px 12px;
}

.apartmentPrice {
  color: #4E6AFF;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
}

.priceForMeter {
  color: #AFB4CB;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 2px;
}

.flatName {
  color: var(--text-color);
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;

  margin-top: 2px;
  display: inline-block;
}

.apartmentComplexAddress {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #404042;

  margin-bottom: 4px;
}

.apartmentComplexLink {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;

  text-decoration: none;

  color: #4E6AFF;
}
