.filterButton {
  height: 46px;
  border-radius: 8px;
  background: #E3E3FF;
  color: #4E6AFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.resetButton {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #DFDFEB;
  color: var(--text-color);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.filtersText {
  padding: 0 4px;
}

.filtersMobileBlock {
  position: relative;
  display: flex;
  align-items: center;
}

.filtersCountMobile {
  position: absolute;
  right: 9px;
  top: 5px;
  border-radius: 12px!important;
  color: var(--Text-four, #FFF);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 12.6px */
  text-transform: uppercase;
}