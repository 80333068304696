.cardWrapper {
  background-color: var(--color-blue-50);
  border-radius: var(--border-radius-size-m);

  display: grid;
  gap: 20px;

  padding: var(--indent-20);
  max-width: 330px;
}

@media (max-width: 1280px) {
  .cardWrapper {
    max-width: unset;
  }
}

.footerWrapper {
  padding: var(--indent-20);
  max-width: 330px;
  margin: auto;
}

@media (max-width: 768px) {
  .footerWrapper {
    margin: auto;
  }
}
