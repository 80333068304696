.offersContactWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.offersContactClientIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid var(--border-icon-color);
}

.offersContactBlock {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
}

.offersContactClientName {
  overflow: hidden;
  text-overflow: ellipsis;
}

.offersContactRight {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;

  color: #404042;
  width: 60%;
}

.offersContactRight > * {

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}