.buildingObjectsTitle {
  margin-left: 5px;
  border-radius: 20px;
}

.skeletonItem {
  height: 270px;

  display: flex;
  flex-direction: column;

  background: linear-gradient(
    90deg,
    rgba(35, 46, 73, 0.2) 0%,
    rgba(35, 46, 73, 0.05) 47.4%,
    rgba(35, 46, 73, 0.2) 100%
  );

  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.04),
    0 41.7776px 33.4221px rgba(0, 0, 0, 0.0287542),
    0 22.3363px 17.869px rgba(0, 0, 0, 0.0238443),
    0 12.5216px 10.0172px rgba(0, 0, 0, 0.02),
    0 6.6501px 5.32008px rgba(0, 0, 0, 0.0161557),
    0 2.76726px 2.21381px rgba(0, 0, 0, 0.0112458);
}

.cardTitle {
  margin-top: auto;
  padding: 10px;
}

.cardTitleItem {
  border-radius: 18px;
}

.cardTitleItem:not(:last-of-type) {
  margin-bottom: 8px;
}

.flatsWrapper {
  height: 60px;
  padding: 10px;

  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  grid-column-gap: 40px;

  background-color: #ffffff;
}

.flatsTitle {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #404042;

  min-width: 40px;
}

.flatsTitle:not(:last-of-type) {
  margin-right: 43px;
}

.skeletonFlatsWrapper {
  flex: 1 0 auto;
  justify-content: space-between;
}

.skeletonFlatsItem {
  flex: 0 0 auto !important;
}
