
.layoutContainerWrapper {
  display: block;
}

@media (min-width: 768px) {
  .layoutContainerFloorPlanGrid {
    display: grid;
    grid-template-areas:
    "sidebar nav"
    "sidebar main";
    grid-template-columns: min-content auto;
  }
}


.layoutContainerFloorSidebar {
  grid-area: sidebar;
  width: 120px;
  overflow: hidden;
  height: calc(var(--vh) * 100 - 136px);
  background-color: #FFFFFF;
  box-shadow: -1px 0 0 #DFDFEB, 0 -1px 0 #DFDFEB, inset -1px 0 0 #DFDFEB, inset 0 -1px 0 #DFDFEB;
}

.layoutContainerNav {
  grid-area: nav;
}

.layoutContainerMain {
  grid-area: main;
  height: calc(var(--vh-100) - 186px);
}

.layoutContainerMain div[data-testid="FloorPlans_wrapper"] {
  height: calc(var(--vh-100) - 186px);
}

.buildingNavBarDesktopWrapper {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}

.buildingNavBarDesktopWrapper > *:first-child {
  flex-shrink: 1;
}

.buildingNavBarDesktopWrapper > * {
  flex-shrink: 0;
}

.selectGroup {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
}


.layoutContainerBuildingsAndSortWrapper {
  background-color: #F3F3F5;
  padding: 0 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 49px;
  border-bottom: 1px solid #F3F3F5;
}

@media screen and (max-width: 768px) {
  .layoutContainerBuildingsAndSortWrapper {
    background: #FFFFFF;
  }
}

.layoutContainerBuildingsTablet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
}

.layoutContainerBuildingNavBarWrapper {
  margin: 0;
}