.callToActionStatus {
  display: flex;
  align-items: center;
}

.statusBadge {
  width: 8px;
  height: 8px;
  margin-right: 4px;
}
