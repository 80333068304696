.form {
  width: 100%;
  padding: 20px;
  overflow-y: auto;
  height: 100%;
  display: flex;
}

.formWrapper {
  max-width: 525px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: auto;
  width: 100%;
}

.fieldGroup {
  width: 100%;
  margin: 0 auto;
}

.fieldGroup > * {
  margin-bottom: var(--indent-20);
}

.fieldGroup textarea {
  resize: none;
  min-height: 130px;
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;

  color: #000000;

  margin-bottom: 8px;
}

@media screen and (min-width: 1024px) {
  .title {
    font-size: 32px;
    line-height: 47px;
    margin-bottom: 10px;
  }
}

.subTitle {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;

  color: #000000;

  margin-bottom: 24px;
}
