.sectionFirst {
    position: sticky;
    top: 0;
    left: 0;
    z-index: var(--z-index-3);

    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 1;
    border-right: var(--border-base);
    border-bottom: var(--border-base);
    background-color: #F1F1F1;
}

.sectionTitle {
    position: sticky;
    top: 0;
    z-index: var(--z-index-3);
    padding: 4px 8px;
    grid-row-start: 1;
    grid-row-end: 1;
    color: #404042;
    border-left: var(--border-base);
    border-bottom: var(--border-base);
    font-weight: 700;
    background-color: #F1F1F1;
}

.sectionTitleBlock {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sectionTitleText {
  color: var(--text-color);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sectionTitleBadge {
  border-radius: 4px;
  background: #20CB6A;
  color: #FFF;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 1px 4px;
}

.sectionItem {
    position: sticky;
    z-index: var(--z-index-3);
    color: #B9B9B9;

    border-left: var(--border-base);
    border-bottom: var(--border-base);

    grid-row-start: 2;
    grid-row-end: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F1F1F1;
}

.sectionItemText {
  color: #AFB4CB;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.borderRight {
    border-right: var(--border-base);
}

.borderLeftNone {
    border-left: none;
}
