.small {
    min-height: 20px;
    padding: 4px;
    border-width: 0;
    border-radius: 4px;

    font-size: 12px;
    font-weight: normal;
    line-height: 12px;
}

.medium {
    min-height: 40px;
    min-width: 40px;
    padding: 12px;
    border-width: 1px;

    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}

.large {
    min-height: 57px;
    padding: 12px;
    border-width: 2px;

    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}
