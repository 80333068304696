/* Затемнение всего сайта, кроме ComplexList */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20;
}

.complexesTutorial {
  filter: none;
  pointer-events: none;
  z-index: 21;
  position: relative;
}

.root {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  transition: width 0.3s linear;
}

.content {
  position: relative;
  width: 100vw;
  height: calc(var(--vh-100) - var(--navbar-height));
  overflow: hidden;

  display: grid;
  /* На мобильных (по умолчанию) одна колонка = 100vw */
  grid-template-columns: 100vw;
  grid-template-rows: var(--search-filter-bar-height) calc(var(--vh-100) - var(--search-filter-bar-height) - var(--navbar-height));
  grid-template-areas:
    "search search"
    "map complexes";
}

@media (min-width: 1024px) {
  .content {
    /* На десктопе: одна колонка (map) "1fr" + вторая колонка (complexes) "auto" */
    grid-template-columns: 1fr auto;
    grid-template-rows: 62px auto;
    grid-template-areas:
      "search complexes"
      "map complexes";
  }
}

.searchAndFilter {
  grid-area: search;
  height: var(--search-filter-bar-height) !important;
  width: 100vw;
}

@media (min-width: 1024px) {
  .searchAndFilter {
    width: 100%; /* можно оставить так для десктопа */
  }
}

.map {
  grid-area: map;
  position: relative;
  width: 100% !important; /* Карта занимает свою колонку 1fr */
  height: calc(var(--vh-100) - var(--navbar-height) - var(--search-filter-bar-height)) !important;
  transition: transform 0.15s linear;
}

.complexes {
  grid-area: complexes;
  /* Важный момент: убираем !important с width,
     чтобы inline-стиль (style={{ width: ... }}) работал! */
  width: auto;
  height: calc(var(--vh-100) - var(--navbar-height)) !important;
  transition: transform 0.15s linear;
}

@media (max-width: 1024px) {
  .complexes {
    height: calc(
      var(--vh-100) - var(--navbar-height) - var(--search-filter-bar-height)
    ) !important;
  }
}

/* Анимация "свайпа" списка и карты (только на мобильных) */
.complexes_show {
  transform: translateX(-100%);
}
.complexes_hide {
  transform: translateX(0);
}
.map_show {
  transform: translateX(0);
}
.map_hide {
  transform: translateX(-100%);
}

/* На десктопе "свайп" не нужен, всё на месте */
@media (min-width: 1024px) {
  .complexes {
    transform: translateX(0);
  }
  .map {
    transform: translateX(0);
  }
}

.toggleViewComplexesAndMap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 24px;
  left: 24px;
  z-index: 13;
  background: #445EE7;
  box-shadow: 0 2px 7px 0 rgba(78, 106, 255, 0.40);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: none;
}

.toggleViewComplexesAndMap img {
  width: 24px;
  min-width: 24px;
  height: 24px;
}

@media (min-width: 768px) {
  width: 64px;
  height: 64px;
  bottom: 32px;
  left: 32px;

  .toggleViewComplexesAndMap img {
    width: 32px;
    height: 32px;
  }
}

@media (min-width: 1024px) {
  .toggleViewComplexesAndMap {
    display: none;
  }
}