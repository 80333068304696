.header {
}

.headerTop {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.headerTitle {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 47px;

  color: #000000;
}
