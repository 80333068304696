.small {
    min-width: 8px;
    min-height: 8px;
    border-radius: 4px;
    padding: 2px 2px 1px 2px;
}

.medium {
    min-width: 16px;
    height: 16px;
    padding: 6px 4px 2px 4px;
    border-radius: 8px;

    font-size: 12px;
    line-height: 12px;
}

.large {
    min-width: 24px;
    min-height: 24px;
    border-radius: 12px;
    padding: 8px;

    font-size: 12px;
    line-height: 12px;
}

.content {
    border-radius: 4px;
}

.isCircle.medium {
    padding: 2px 6px 2px 6px;
    border-radius: 20px;
}
.isCircle.large {
    border-radius: 37px;
    padding: 8px 11px 8px 11px;
}