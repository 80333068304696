.bannerSlider {
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  .swiper {
    width: 100%;
  }

  .swiper-slide {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Отключаем стандартные точки Swiper */
  :global(.swiper-pagination) {
    display: none !important;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 6px;
  margin-top: 0;
  margin-bottom: 10px;
}

.bullet {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #afb4cb; /* Серый для неактивной точки */
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
}

.activeBullet {
  background: #4e6aff; /* Синий цвет активной точки */
  width: 17px;
  height: 6px;
  border-radius: 30px;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%; /* Исправляем: теперь стартует с 0 */
  height: 100%;
  background: #2e48d5; /* Цвет заполнения */
  border-radius: 4px;
  animation: progressAnimation 9s linear forwards; /* Запускаем анимацию */
}

@keyframes progressAnimation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

