.modalOverlay {
  z-index: var(--z-index-14);
  position: fixed;
  inset: 0px;
  top: 0px;
  width: 100%;
}

.modal {
  padding: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 12px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid var(--border-icon-color);
  background: var(--background-input-color);
}

.inputWrapper:focus-within {
  border: 2px solid #4E6AFF;
}

@media screen and (max-width: 768px) {
  .inputWrapper {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    height: 40px;
    padding: 0 11px;
  }

  .inputWrapper input {
    width: 100%;
    min-width: 0;
    box-sizing: border-box;
  }
}

.inputTag {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  padding: 0 8px;
  font-size: 14px;
}

.inputTagWithSuffix {
  padding-right: 40px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

@media screen and (max-width: 768px) {
  .inputTagWithSuffix {
    padding-right: 34px;
    font-size: 14px;
  }
}

.prefixText {
  color: var(--plan-toggle-count-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

@media screen and (max-width: 768px) {
  .prefixText {
    font-size: 14px;
  }
}

.suffixIcon {
  position: absolute;
  right: 16px;
  color: var(--plan-toggle-count-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.suffixIcon {
  font-size: 14px;
}

.suffixIconClose {
  right: 44px;
}

@media screen and (max-width: 768px) {
  .suffixIconClose {
    right: 32px;
  }
}

.clearButton {
  position: absolute;
  right: 8px;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .clearButton {
    font-size: 14px;
  }
}

.checkboxWrapper {
  border-radius: 24px;
  outline: 1px solid var(--border-icon-color);
  background: transparent;
  padding: 10px 16px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  transition: all 0.3s;
  border: none;
}

@media screen and (max-width: 768px) {
  .checkboxWrapper {
    outline: 1px solid var(--border-icon-color);
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 500;
  }
}

@media screen and (min-width: 1024px) {
  .checkboxWrapper:hover {
    outline: 1px solid #DFDFEB;
    background: rgba(78, 106, 255, 0.1);
    color: #4E6AFF;
  }
}

.checkboxTag {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.selected {
  color: #4E6AFF;
  outline: 2px solid #4E6AFF;
  background: rgba(78, 106, 255, 0.1);
  border: none;
}

.selected:hover {
  outline: 2px solid #4E6AFF;
}

@media screen and (max-width: 768px) {
  .selected {
    outline: 1px solid #4E6AFF;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

