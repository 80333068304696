.textDescription {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  @media screen and (max-width: 768px) {
    margin-top: 16px;
  }

  .title {
    color: var(--text-color);
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 4px;
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  .text {
    color: var(--text-color);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  .toggleWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;

    &::before,
    &::after {
      content: '';
      flex: 1;
      height: 1px;
      background-color: #DFDFEB;
    }

    &::before {
      margin-right: 8px;
    }

    &::after {
      margin-left: 8px;
    }
  }

  .toggleButton {
    color: #4E6AFF;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .arrowDown {
    width: 12px;
    height: 12px;
    transform: rotate(0deg);
    transition: transform 0.3s;
  }

  .arrowUp {
    width: 12px;
    height: 12px;
    transform: rotate(180deg);
    transition: transform 0.3s;
  }
}
