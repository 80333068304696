:root {
    --show-full-info-button-height: 12px;
}
.wrapper {
    display: none;
}

.isOpen {
    display: block;
}

.headerWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--z-index-14);
    transition: transform .2s ease-in-out;
    transform: translateY(calc(var(--navbar-height) * -1));
}

.headerWrapperShow {
    transform: translateY(0);
}

.contentWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--z-index-13);
    /*height: calc(100vh - var(--navbar-height) + var(--show-full-info-button-height));*/
    height: 100%;
    transition: transform .2s ease-in-out;
    background-color: #fff;
    transform: translateY(100vh);

    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.15));
}

.contentWrapperIsOpen {
}
.contentWrapperFull {
    transform: translateY(calc(var(--navbar-height) - var(--show-full-info-button-height)));
}
.contentScrollbar {
    height: calc(100vh - var(--navbar-height) + var(--show-full-info-button-height) - 12px);
}
.showFullInfoButton {
    margin-top: calc(var(--show-full-info-button-height) * -1);
    height: calc(var(--show-full-info-button-height) * 2);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    background-color: transparent;
    border: none;
}

.showFullInfoButton:after {
    content: '';
    width: 48px;
    height: 4px;
    border-radius: 2px;
    background-color: #DFDFEB;
}

@media screen and (max-width: 768px) {
  .contentWrapper {
    border-radius: 24px 24px 0 0;
  }
}
