
.wrapper {
  display: flex;
  flex-direction: column-reverse;
  background-color: #FFFFFF;
}

@media (min-width: 1024px) {
  .wrapper {
    display: grid;
    grid-template-columns: auto 290px;
  }
}

.content {
  padding: 32px 12px 64px;
}

@media (min-width: 768px) {
  .content {
    padding: 40px 20px;
  }
}

@media (min-width: 1024px) {
  .content {
    padding: 80px 50px;
  }
}

.title {
  margin-bottom: 8px;

  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
}


@media (min-width: 1024px) {
  .title {
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 47px;
  }
}

.subtitle {
  margin-bottom: 32px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #000000;
}
@media (min-width: 1024px) {
  .subtitle {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
  }
}

.formWrapper {
  max-width: 330px;
  width: 100%;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: auto;
  height: calc(var(--vh) * 40);
  overflow: hidden;
  background-color: #bdcada;
}

@media (min-width: 1024px) {
  .imageWrapper {

    width: 290px;
    height: 100%;
    overflow: hidden;
  }

}

.image {
  display: block;
  max-height: 100%;
  object-fit: contain;
}