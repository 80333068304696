
.swiperDescription {
  max-width: 500px;
  flex: 1 0 30%;
  margin: 40px 20px 20px;
  padding: 10px;
}

.swiperDescription h3 {
  font-weight: bold;
  font-size: 32px;
  line-height: 47px;
  margin-bottom: 10px;
}

@media (max-width: 1024px) {
  .swiperDescription {
    align-self: center;
    justify-self: center;
    width: 100%;
    overflow-y: auto;
    z-index: 222222;
    flex: 1 0 auto;
    margin: 0;
  }

  .swiperDescription h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
  }
}

.swiperDescriptionCottage {
  display: block;
  width: 100%;
  max-width: 1000px;
  position: absolute;
  padding: 8px;
  bottom: 52px;
  left: 50%;
  transform: translateX(-50%);
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.50);
  z-index: 9;
}

@media (max-width: 768px) {
  .swiperDescriptionCottage {
    bottom: 8%;
    z-index: 2;
  }
}