.overlay {
    min-width: 180px;
    width: 100vw;
    max-height: 300px;
    overflow: auto;
    background-color: #fff;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}

@media (min-width: 540px){
    .overlay {
        width: 100%;
    }
}

.item {
  padding: 0 10px;
  cursor: pointer;
  color: var(--text-color);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.item button {
  padding: 2px 0;
}

@media screen and (max-width: 768px) {
  .item button div div {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
}

.itemActive {
  color: #4E6AFF;
  background: var(--toggle-active-background);
}

.itemActive button {
  padding: 2px 0;
}

.item:hover,
.itemActive {
  background: var(--toggle-active-background);
}

.item:hover *,
.itemActive *{
  color: #4E6AFF;
}

.bottomSheetHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.bottomSheetHeader h2 {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
}

.closeBtn {
  background: none;
  border: none;
  cursor: pointer;
}

.mobileTabsSelect {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;
}
