
.apartmentInfoTooltipWrapper {
  display: grid;
  gap: 10px;
  grid-template-columns: 90px 140px;
  background-color: #fff;
  width: 240px;
  height: 100px;
}

.apartmentInfoTooltipLeft {
  width: 90px;
  height: 100px;

  overflow: hidden;
}

.apartmentInfoTooltipPlan {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.apartmentInfoTooltipRight {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Circe;
  color: #404042;
  font-weight: 700;
}