.bannerWrapper {
  width: 100%;
  position: relative;
  font-family: Inter;
  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
}

.banner {
  display: block;
  background: url("~assets/images/tg-background.png"), #0C6EFF;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: overlay, normal;
  color: #fff;
  border-radius: 16px;
  padding: 12px;
  text-decoration: none;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  margin-bottom: 14px;
  font-family: Inter;
  @media screen and (max-width: 1440px) {
    padding: 12px 8px;
  }

  @media screen and (max-width: 768px) {
    padding: 16px;
    height: 149px;
  }
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 12px;
  right: 12px;
  background: transparent;
  border-radius: 8px;
  border: 1px solid #DFDFEB;
  cursor: pointer;
  font-family: Inter;
  z-index: 2;

  img {
    width: 16px;
    height: 16px;
    min-width: 16px;
  }
}

.content {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1440px) {
    align-items: flex-start;
  }
}

.qrContainer {
  width: 133px;
  min-width: 133px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 0;
  @media screen and (max-width: 1440px) {
    margin-right: 4px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

  img {
    width: 100%;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  .logo {
    width: 86px;
    height: 30px;
  }

  .title {
    color: #FFF;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  .description {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
}
