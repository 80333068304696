.wrapper {
    width: 30px;
    height: 30px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    background-color: #DFDFEB;
}

.img {
    color: transparent;
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-align: center;
}

.avatarSize30 {
    width: 30px;
    height: 30px;
}

.avatarSize60 {
    width: 60px;
    height: 60px;
}