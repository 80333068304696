.sliderWrapper {
  position: relative;
}

.swiperSlide {
  width: auto !important;
}

.swiperImageLink {
  text-decoration: none;
}

.swiperImage {
  height: 300px;
  width: auto;
}

@media (max-width: 768px) {
  .swiperImage {
    height: 210px;
  }
}

.badgesWrapper{
  height: 90px;
}

.badges {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  z-index: var(--z-index-3);
  top: 24px;
  left: 24px;
  width: 300px;
}
