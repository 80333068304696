.dropdown {
  overflow: hidden;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.25);
}

.dropdownButton {
  padding-right: 0;
  height: 24px;
}

.dropdownButton img {
  transform: rotate(0);
  transition: all .3s ease;
}

img.dropdownButtonImg {
  transform: rotate(180deg);
  transition: all .3s ease;
}

.sortItemsWrapper {
  border-top: 1px solid #cfcfcf;
  margin-top: 4px;
  padding: 4px 0 0;
}

.sortWrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.sortWrapper button {
  color: #4E6AFF;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  padding-left: 4px;
}

.overlay {
  width: 200px;
  border-radius: 8px;
  padding: 16px;
}

.item {
  text-align: left;
  width: 100%;
  border: none;
  margin: 0;
  padding: 8px;
  background: none;
  cursor: pointer;
  color: var(--text-color);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.item:hover,
.itemActive {
  border-radius: 4px;
  background: #F3F3F5;
  color: #4E6AFF;
}

.emptyDataWrapper {
  margin-top: -40px;
}

.label {
  display: none;
}
@media screen and (min-width: 768px) {
  .label {
    display: inherit;
  }
}

@media screen and (min-width: 1024px) {
  .label {
    display: none;
  }
}

@media screen and (min-width: 1440px) {
  .label {
    display: inherit;
  }
}
