.th {
    position: sticky;
    top: 0;
    height: 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0;
    background-color: #ffffff;
    z-index: var(--z-index-3);
    border-bottom: var(--border-base);
}

th:before {
    background-color: #ffffff;
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: calc(100% + 20px + 60px);
    height: 100%;
    z-index: var(--z-index-1);
}

.content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    z-index: var(--z-index-2);
}

.sortedIcon {
    margin-left: 4px;
}
