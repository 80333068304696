.wrapper {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  cursor: pointer;
}

.avatar {
  margin-top: -4px;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;

  color: #4e6aff;

  padding-right: 10px;
  padding-left: 5px;
}

.avatarEmail {
  color: #AFB4CB;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 16ch;
}
