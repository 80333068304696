.wrapper {
    display: inline-flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
}

.avatar_item {
    margin-left: -8px;
}

.avatar_item:first-child {
    margin-left: 0;
}
