
.floorList {
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}

.floorList > * {
  margin-bottom: 10px;
}