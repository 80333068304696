.headerWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    width: 100%;
    height: var(--navbar-height);
    background-color: var(--background-color);
}
@media screen and (min-width: 768px) {
    .headerWrapper {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: var(--navbar-height);
    border-bottom: 1px solid var(--border-filter-bottom);
}

.headerContent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: var(--text-color);
}

.closeButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: 2;
}

@media screen and (min-width: 768px) {
    .closeButton {
        left: 12px;
    }
}