.overlay {
    min-width: 180px;
    width: 100vw;
    max-height: 300px;
    overflow: auto;
    background-color: #fff;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}

@media (min-width: 540px){
    .overlay {
        width: 100%;
    }
}