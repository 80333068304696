
.root {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.navbarWrapper {
  width: 100%;
  height: var(--navbar-height);
  position: relative;
}
