.linkButton {
  color: #4E6AFF;

  font-weight: bold;

  padding: 0;
  border: 0;
  background: inherit;

  cursor: pointer;
}
