
.offersCardInfoTitleIconOpen {
    transform: rotate(180deg);
}

.offersCardInfoTitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #404042;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0;
}

.offersCardInfoTitle:hover span {
    opacity: 0.8;
}
