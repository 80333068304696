.pageWrapper {
  position: relative;
  background: #F3F3F5;

  overflow-y: auto;
  height: calc(var(--vh) * 100);
  display: flex;
  justify-content: center;
}

.container {
  padding: 0 60px;
}

@media (max-width: 1280px) {
  .container {
    padding: 0 40px;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 0 12px;
  }
}

.nearbyMap {
  margin-top: 0;
}

.wrapper {
  max-width: 1288px;
  width: 100%;
  padding: 0 30px;
}

@media (max-width: 768px) {
  .wrapper {
    padding: 0;
  }
}

.toOffersLink {
  margin: 30px 0;
}

@media (max-width: 767px) {
  .toOffersLink {
    display: none;
  }
}

.flat {
  background: #FFFFFF;

  box-shadow:
    -1px 0 0 #DFDFEB,
    0 -1px 0 #DFDFEB,
    inset -1px 0 0 #DFDFEB,
    inset 0 -1px 0 #DFDFEB;
}
.mainInfoWrapper {
  padding-top: 20px;
}

@media (min-width: 1024px) {

  .mainInfoWrapper {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .mainInfoWrapper > :first-child {
    margin-right: 20px;
    max-width: calc(100% - 20px - 230px);
  }
}

@media (min-width: 1280px) {
  .mainInfoWrapper > :first-child {
    margin-right: 60px;
    max-width: calc(100% - 60px - 250px);
  }
}

.desktopApartmentActions {
  display: none;
}

@media (min-width: 1024px) {
  .desktopApartmentActions {
    display: block;
    min-width: 230px;
  }
}

.mobileApartmentActions {
  margin-bottom: 32px;
}

@media (min-width: 1024px) {
  .mobileApartmentActions {
    display: none;
  }
}

.allFlatsInfo {
  /*margin: 20px 0;*/
}

.descriptionWrapper {
  white-space: break-spaces;
  max-width: 650px;
  overflow: hidden;
}

@media (min-width: 1280px) {
  .descriptionWrapper {
    max-width: 830px;
  }
}

.toNextOfferWrapper {
  width: 100%;
  height: 140px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('nextOffer.jpg');
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .toNextOfferWrapper {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('nextOfferMini.jpg');
    background-size: cover;
    height: 100px;
  }
}

.toNextOfferLink {
  background: transparent;
  border: none;
  font-weight: bold;
  font-size: 20px;
  line-height: 18px;
  color: #FFFFFF;
  max-width: unset;
}

.toNextOfferLink div {
  margin-left: 10px;
}
