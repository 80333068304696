.complexCardMapButton {
  position: relative;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.complexCardMapWrapper {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 8px;
  background-color: #f3f3f5;
  flex-shrink: 0;
  pointer-events: none;
}


.complexCardMapLoader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: var(--z-index-400);
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
