:root {
  /* Ваши существующие переменные */
  --vh: 1vh;
  --vh-100: calc(var(--vh) * 100);
  /*--navbar-height: 68px;*/
  --navbar-height: 52px;
  --search-height: 68px;
  --layout-header-height: 80px;
  --layout-building-tabs-height: 50px;

  --breakpoint-desktop-lg: 1280px;
  --breakpoing-desktop-sm: 1024px;
  --breakpoint-tablet: 880px;

  /* Размер текста */
  --size-text-xxs: 10px;
  --size-text-xs: 12px;
  --size-text-s: 14px;
  --size-text-m: 16px;
  --size-text-l: 18px;
  --size-text-xl: 20px;
  --size-text-2xl: 22px;
  --size-text-3xl: 32px;
  --size-text-4xl: 48px;

  /* Цвета */
  --color-black: #000000;
  --color-white: #ffffff;

  --color-gray-900: #404042;
  --color-gray-300: #B9B9B9;
  --color-gray-200: #DFDFEB;
  --color-gray-100: #F1F1F1;

  --color-blue: #4E6AFF;
  --color-blue-200: #DFDFEB;
  --color-blue-100: #F1F6FC;
  --color-blue-50: #F3F3F5;
  --color-blue-700: #445EE7;

  --color-red: #DD6465;
  --color-red-700: #EE5656;

  --color-green: #20CB6A;

  --color-status-reserved: #AFB4CB;

  /* Отступы */
  --indent-2: 2px;
  --indent-5: 5px;
  --indent-10: 10px;
  --indent-12: 12px;
  --indent-15: 15px;
  --indent-20: 20px;
  --indent-25: 25px;
  --indent-30: 30px;
  --indent-35: 35px;
  --indent-40: 40px;
  --indent-60: 60px;

  /* Границы */
  --border-size-1: 1px;
  --border-size-2: 2px;
  --border-size-3: 3px;

  --border-radius-size-xs: 4px;
  --border-radius-size-m: 8px;
  --border-radius-size-l: 10px;
  --border-radius-size-xl: 24px;

  --border-base: var(--border-size-1) solid var(--color-blue-200);
  --border-shadow: 0 var(--border-size-1) 0 var(--color-blue-200);

  /* z-index */
  --z-index-1: -1;
  --z-index-2: 0;
  --z-index-3: 1;
  --z-index-4: 2;
  --z-index-5: 3;
  --z-index-6: 4;
  --z-index-7: 5;
  --z-index-8: 6;
  --z-index-9: 7;
  --z-index-10: 8;
  --z-index-11: 9;
  --z-index-12: 10;
  --z-index-13: 11;
  --z-index-14: 12;
  --z-index-15: 13;
  --z-index-16: 14;
  --z-index-17: 15;
  --z-index-18: 16;
  --z-index-19: 17;
  --z-index-20: 18;

  --z-index-400: 400;
  --z-index-1000: 1000;

  --breakpoints-sm: 320px;
  --breakpoints-sx: 540px;
  --breakpoints-md: 768px;
  --breakpoints-lg: 1024px;
  --breakpoints-xl: 1280px;
  --breakpoints-xxl: 1440px;

@media screen and (max-width: 880px) {
  :root {
    --search-height: 60px;
  }
}
@media (min-width: 1200px) {
  :root {
    --layout-header-height: 80px;
  }
}

/* Дополнительные медиа-запросы */

@media screen and (min-width: 0px) {
  :root {
    --navbar-height: 56px;
  }
}

@media screen and (min-width: 768px) {
  :root {
    --navbar-height: 72px;
  }
}

@media screen and (min-width: 1024px) {
  :root {
    --navbar-height: 68px;
  }
}

  /* Переменные темы */
  --background-color: var(--color-white); /* #FFFFFF */
  --village-wrapper-background: var(--color-white); /* #FFFFFF */
  --text-color: #333338;
  --header-text-color: var(--color-status-reserved); /* #AFB4CB */
  --border-color: var(--color-gray-200); /* #DFDFEB */
  --available-color: var(--color-green); /* #20CB6A */
  --reserved-color: #AFB4CB; /* #AFB4CB */
  --sold-color: var(--color-red-700); /* #EE5656 */
  --price-color: var(--color-blue-700); /* #445EE7 */
  --table-row-background: transparent;
  --plan-toggle-separator-color: #DFDFEB;
  --plan-toggle-count-color: #AFB4CB;
  --hover-toogle-plan: #f3f3f5;
  --cottage-view-background: var(--color-white);
  --stepper-background: #f3f3f5;
  --modal-content-background: #FFFFFF;
  --toggle-modal-background: #f3f3f5;
  --border-icon-color: #DFDFEB;
  --background-input-color: #F8F8F8;
  --background-select-color: #E3E6E8;
  --background-dropdown-color: #ffffff;
  --border-filter-bottom: #F3F3F5;
  --checkbox-background: #FFFFFF;
  --toggle-active-background: #F3F3F5;
  --bottom-sheet-background: #FFFFFF;
  --zoom-background: #FFFFFF;
  --switch-checked-background: #4e6aff;
  --switch-off-background: #afb4cb;
  --floors-number-background: #F1F1F1;
}

/* Тёмная тема */
.dark-theme {
  --background-color: #1F1F20;
  --village-wrapper-background: #1F1F20;
  --text-color: #E1E4EC;
  --header-text-color: #FFFFFF;
  --border-color: #494952;
  --available-color: #22B863;
  --reserved-color: #585B68;
  --table-row-background: transparent;
  --plan-toggle-separator-color: #494952;
  --plan-toggle-count-color: #5B5B61;
  --hover-toogle-plan: #2C2C2E;
  --cottage-view-background: #2C2C2E;
  --stepper-background: #3D3D3E;
  --modal-content-background: #131314;
  --toggle-modal-background: #151515;
  --border-icon-color: #39393E;
  --background-input-color: #151515;
  --background-select-color: #2E2E2F;
  --background-dropdown-color: #242425;
  --border-filter-bottom: #28282A;
  --checkbox-background: #3D3D3E;
  --toggle-active-background: #353536;
  --bottom-sheet-background: #2C2C2E;
  --zoom-background: #28282A;
  --switch-checked-background: #445fe7;
  --switch-off-background: #585b68;
  --floors-number-background: #131314;
}
