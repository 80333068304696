.infoCardViewButtons {
  display: flex;
  justify-content: space-between;
}

.infoCardViewButtons > button {
  margin-right: 10px;
}

.infoCardViewButtons > button:last-child {
  margin-right: 0;
}