

.photoCount {
  color: #000000;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
}

.photoCountCottage {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: #AFB4CB;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
}