.loader {
    display: inline-block;
    vertical-align: top;
}
.loader:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid transparent;
    animation: loader 1.2s linear infinite;
}
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader_size_small {
    width: 16px;
    height: 16px;
}
.loader_size_small:after {
    width: 14px;
    height: 14px;
    margin: 2px;
    border-width: 1.5px;
}

.loader_size_normal {
  width: 20px;
  height: 20px;
}
.loader_size_normal:after {
  width: 18px;
  height: 18px;
  margin: 2px;
  border-width: 3px;
}

.loader_size_medium {
    width: 32px;
    height: 32px;
}
.loader_size_medium:after {
    width: 28px;
    height: 28px;
    margin: 4px;
    border-width: 3px;
}

.loader_color_default:after {
    border-color: var(--color-gray-900) transparent var(--color-gray-900) transparent;

}
.loader_color_primary:after {
    border-color: var(--color-white) transparent var(--color-white) transparent;
}
