.albumsListItem {
  height: 120px;
  flex: 0 0 180px;

  margin: 0 20px 20px 0;
}

.albumLink {
  text-decoration: none;
  width: 100%;
  height: 100%;

  display: flex;
}

.albumItem {
  width: inherit;
  height: inherit;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-shadow: 0 -100px 100px 0 rgba(0, 0, 0, 0.55) inset;
  background-size: cover;

  padding: 8px;
}

.albumInfo {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.albumCount {
  color: white;
  font-size: 12px;
  line-height: 12px;

  display: flex;
  align-items: baseline;
}

.albumName {
  color: white;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
}
