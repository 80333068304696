

.fieldList {
    padding: 0;
    margin: 0;
    display: grid;
    gap: 20px;

}

.fieldItem {
    list-style: none;
    display: grid;
    grid-template-columns: auto 40px;
    gap: 20px;
}