.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  padding: 12px;
}

.container h2 {
  color: #000000;

  font-weight: bold;
  font-size: 24px;
  line-height: 35px;

  margin-bottom: 8px;
}

@media (min-width: 1024px) {
  .container h2 {
    font-size: 32px;
    line-height: 47px;

    margin-bottom: 20px;
  }
}

.container p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #404042;

  text-align: center;
}
