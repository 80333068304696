.baseButton {
    position: relative;
    box-sizing: border-box;
    border-radius: 8px;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    font-style: normal;
    letter-spacing: 0;
    text-align: center;

    text-decoration: none;

    max-width: 300px;
}

.baseButton span {
  display: flex;
  align-items: center;
  justify-content: center;
}

button.baseButton span svg {
  width: auto;
  height: auto;
}

.baseButton:disabled {
    cursor: default;
}

.selected {
    background-color: #F1F6FC;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2);
}

.fullWidth {
    width: 100%;
    max-width: 100%;
}

.badgeWrapper {
    transform: translateY(-8px);
}

.iconWrapperLeft {
    display: inherit;
    margin-right: 4px;
}
.iconWrapperRight {
    margin-left: 4px;
    display: inherit;
}
