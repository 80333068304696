.viewCountWrapper {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  color: #b9b9b9;
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(3, min-content);
}

.viewCountWrapperDark {
  color: #404042;
}

.viewCountIcon {
  order: 1;
}

.viewCountCount {
  order: 2;
}

.viewCountLabel {
  order: 2;
  /* компенсация выравнивания по вертикали*/
  transform: translateY(-1px);
}

.viewCountWrapperRevers .viewCountIcon {
  order: 3;
}

.viewCountWrapperRevers .viewCountCount {
  order: 2;
}

.viewCountWrapperRevers .viewCountLabel {
  order: 1;
}
