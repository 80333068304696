.leaflet-container {
  height: 100%;
  width: 100%;
}
.leaflet-tooltip {
  display: flex;
  padding: 0;
}
.leaflet-tooltip {
  opacity: 0;
}

.leaflet-div-icon {
  background-color: transparent !important;
  border: none !important;
}
.leaflet-control-container .leaflet-left, .leaflet-control-container .leaflet-right {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}
.leaflet-tooltip.leaflet-tooltip-left, .leaflet-tooltip.leaflet-tooltip-right {
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 12px;
  opacity: 1;
  backdrop-filter: blur(20px);
}
