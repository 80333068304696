
.shareOfferLinkPopup {

}

.shareOfferLinkPopupBody {
  padding: 26px 12px 40px;
}

@media (min-width: 1024px) {

  .shareOfferLinkPopupBody {
    padding: 60px 60px 40px;
  }

}


.shareOfferLinkPopupTitle {
  display: none;
  margin-bottom: 32px;

  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  align-items: center;

  color: #000000;
}

@media (min-width: 1024px) {
  .shareOfferLinkPopupTitle {
    display: flex;
  }
}

.shareOfferLinkPopupFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 12px;
  background-color: #F3F3F5;
}

@media (min-width: 1024px) {
  .shareOfferLinkPopupFooter {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 20px 60px;
  }
}

.shareOfferLinkPopupSocialButtonGroup {
  display: flex;
  flex-direction: row;
}
.shareOfferLinkPopupSocialButtonGroup > button {
  margin-right: 10px;
}
