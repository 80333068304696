.description {
  padding: 50px 32px;
}

.sectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #333338;
  font-size: 65px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 68px;
  border-bottom: 8px solid #333338;
  padding-bottom: 32px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px 32px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  color: #AFB4CB;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
}

.value {
  color: #333338;
  font-size: 28px;
  font-weight: 600;
  line-height: 140%;
}
