.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: var(--indent-20);
}

@media (max-width: 1280px) {
  .wrapper {
    flex-direction: column;
  }
}

.complexTitle {
  word-break: break-word;
  font-weight: bold;
  font-style: normal;

  font-size: 22px;
  line-height: 32px;
}


@media (min-width: 540px) {
  .complexTitle {
    font-size: 24px;
    line-height: 35px;
  }
}

@media (min-width: 1024px) {
  .complexTitle {
    font-size: 32px;
    line-height: 47px;
    margin-top: 8px;
  }
}

.left_content {
  margin-right: var(--indent-20);
  margin-bottom: 10px;
}

@media (max-width: 1024px) {
  .left_content {
    margin-right: 0;
    margin-bottom: 4px;
  }
}


.right_content {
  padding-top: var(--indent-20);
  margin: 0;
}

@media (max-width: 1024px) {
  .right_content {
    padding: 0;
    margin: 0 0 0 70px;
  }
}

.toFlatsLink {
  padding-left: 0px;
}

@media (max-width: 1024px) {
  .toFlatsLink {
    display: flex;
    justify-content: flex-start;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
}
