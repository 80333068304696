.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: var(--navbar-height);
  border-bottom: 1px solid var(--border-filter-bottom);
}
.mobileWrapper {
  z-index: var(--z-index-3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: var(--navbar-height);
  background: var(--background-color);
}

@media screen and (min-width: 768px) {
  .mobileWrapper {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media screen and (min-width: 1024px) {
  .mobileWrapper {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    width: 87px;
    height: 29px;
  }
}