.modalOverlay {
  z-index: var(--z-index-14);
  position: fixed;
  inset: 0;
  top: 0;
  width: 100%;
}

.modal {
  padding: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid var(--border-icon-color);
  background: var(--background-input-color);
  box-sizing: border-box;
}

.inputWrapper:focus-within {
  outline: 2px solid #4E6AFF;
}

.inputWrapperError {
  outline: 2px solid red;
}

.inputWrapperError:focus-within {
  outline: 2px solid red;
}

@media screen and (max-width: 768px) {
  .inputWrapper {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    height: 40px;
    padding: 0 11px;
  }

  .inputWrapper input {
    width: 100%;
    min-width: 0;
    box-sizing: border-box;
  }
}

.inputTag {
  /*flex: 1;*/
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  padding: 0 8px;
  font-size: 14px;
}

.inputTagWithSuffix {
  /*padding-right: 40px;*/
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

@media screen and (max-width: 768px) {
  .inputTagWithSuffix {
    padding-right: 34px;
    font-size: 14px;
  }
}

.prefixText {
  color: var(--plan-toggle-count-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .prefixText {
    font-size: 14px;
  }
}

.suffixIcon {
  position: absolute;
  right: 16px;
  color: var(--plan-toggle-count-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.suffixIcon {
  font-size: 14px;
}

.suffixIconClose {
  right: 44px;
}

@media screen and (max-width: 768px) {
  .suffixIconClose {
    right: 32px;
  }
}

.clearButton {
  position: absolute;
  right: 8px;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .clearButton {
    font-size: 14px;
  }
}

.errorText {
  color: red;
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  bottom: -22px;
  left: 0;
}


