.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
}

.avatar {
  width: 64px;
  height: 64px;
  margin-right: 16px;
}

@media screen and (min-width: 1024px) {
  .avatar {
    width: 120px;
    height: 120px;
    margin-right: var(--indent-20);
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  width: calc(100% - 120px - 20px);
}

.contacts {
  display: grid;
  grid-template-columns: max-content;
  gap: 12px;
}
@media screen and (min-width: 1024px) {
  .contacts {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 65px;
  }
}

.fio {
  margin-bottom: 4px;

  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
@media screen and (min-width: 1024px) {
  .fio {
    font-size: 22px;
    line-height: 28px;
  }
}

.description {
  margin-bottom: 12px;

  font-size: 14px;
  line-height: 18px;
  color: #404042;
}
@media screen and (min-width: 1024px) {
  .description {
    font-size: 16px;
    line-height: 24px;
  }
}

.rank {
  margin-bottom: 12px;

  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #404042;
}
@media screen and (min-width: 1024px) {
  .rank {
    margin-bottom: 10px;
  }
}
