.textarea {
    composes: input from "../Input/styles.module.css";
    display: block;
    padding: var(--indent-10);
    height: auto;
    font-size: var(--size-text-m);
    line-height: var(--size-text-l);
}
.textarea::placeholder {
    padding-top: 0;
    font-size: var(--size-text-m);
    line-height: var(--size-text-l);
    color: rgba(64, 64, 64, .3);
}
