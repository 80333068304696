
.icon {
    display: inline-block;
    vertical-align: top;
}

.icon_size_10 {
    height: 10px;
    max-width: 10px;
}

.icon_size_16 {
    height: 16px;
    max-width: 16px;
}
.icon_size_20 {
    height: 20px;
    max-width: 20px;
}
.icon_size_24 {
    height: 24px;
    max-width: 24px;
}
.icon_size_32 {
    height: 32px;
    width: 32px;
}
.icon_size_120 {
    height: 120px;
    max-width: 120px;
}
