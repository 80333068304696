.visibilitySettingFormWrapper {
  display: grid;
  grid-template-columns: 100%;
  gap: 15px;
  margin-bottom: 40px;
}

@media (min-width: 1024px) {
  .visibilitySettingFormWrapper {
    grid-template-columns: calc(50% - 30px) calc(50% - 30px);
    gap: 15px 60px;
    margin-bottom: 40px;
  }
}

.visibilitySettingFormListItem {
  font-weight: bold;
  padding: 0;
}