.flatEdit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px;

  background: #F3F3F5;
  border-radius: 12px;

  height: max-content;
}

.developerLogo {
  border: 1px solid #DFDFEB;
  box-sizing: border-box;
  border-radius: 8px;
  width: 60px;
  height: 60px;
  object-fit: cover;

  margin-right: 10px;
}

.developerTitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
}

.developer {
  display: flex;
  align-items: center;

  margin-bottom: 20px;
}

.developerInfoWrapper {
  display: flex;
  flex-direction: column;
}

.developerInfo {
  font-size: 12px;
  line-height: 14px;

  margin-top: 3px;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  color: #404042;

  margin-bottom: 6px;
}

.apartmentPrice {
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  color: #000000;

  margin-bottom: 10px;
}

.priceForMeter {
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #000000;

  margin-bottom: 25px;
}


.apartmentGroup {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.apartmentGroup > * {
  margin-bottom: 10px;
}

.apartmentGroup > *:last-child {
  margin-bottom: 0;
}

.apartmentLink {
  font-weight: bold;
  padding: 0;
}
