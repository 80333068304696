.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-bottom: var(--border-base);
  color: #404042;
  background-color: #fff;
}

.wrapper:hover {
  background-color: #F3F3F5;
}

.isActive, .isActive:hover {
  background-color: #4E6AFF;
  color: #ffffff;
}
.isActive .grayText, .isActive:hover .grayText{
  color: #ffffff;
  opacity: 0.5;
}

.left {
  width: 50%;
  text-align: left;
}

.right {
  text-align: right;
  width: 50%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row:first-child {
  margin-bottom: 4px;
}
.row:last-child {
  margin-top: 8px;
}

.left .row {
  justify-content: flex-start;
}

.right .row {
  justify-content: flex-end;
}

.text {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;

}

.grayText {
  color: #b9b9b9;
}

.floor {
  height: 16px;
  min-width: 16px;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #f3f3f5;
  margin-right: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;

  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #404042;
}

.typeRoom {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;

  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-right: 4px;
}
