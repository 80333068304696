:global(.ReactModal__Overlay) {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

:global(.ReactModal__Overlay--after-open) {
  opacity: 1;
}

:global(.ReactModal__Overlay--before-close) {
  opacity: 0;
}

.drawerOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: var(--z-index-13);

  display: flex;
  align-items: flex-end;

  width: 100vw;
  height: var(--vh-100);

  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 768px) {
  .drawerOverlay {
    z-index: 12;
  }
}

.drawerContent {
  position: relative;
  display: grid;
  grid-template-rows: 1fr 4fr;

  width: 100%;
  max-height: calc(var(--vh) * 80);
  background-color: #fff;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  transform: translateY(100%);

  transition: all 250ms ease-in-out;
}

@media screen and (max-width: 1024px) {
  .drawerContent {
    max-height: 100%;
    grid-template-rows: min-content;
  }
}

.drawerContentScroll {
  overflow: auto;
}

:global(.ReactModal__Overlay--after-open) .drawerContent {
  transform: translateY(0);
}

:global(.ReactModal__Overlay--before-close) .drawerContent {
  transform: translateY(100%);
}

.drawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px 13px 24px 24px;
}
.drawerHeaderSticky {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: var(--z-index-3);
}

.drawerHeaderContent {
  width: calc(100% - 40px);
  overflow: hidden;
}
