#boxSwiper .swiper-button-prev,
#boxSwiper .swiper-button-next {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background-size: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
}

#boxSwiper {
  min-height: 396px;
  .swiper-slide {
    height: auto;
  }
}

@media (max-width: 1024px) {

  #boxSwiper {
    width: calc(100% + 50px);
  }

  #boxSwiper .swiper-button-prev,
  #boxSwiper .swiper-button-next {
    display: none;
  }
}

#boxSwiper .swiper-button-next{
  box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.15);
  right: -25px;
}

#boxSwiper .swiper-button-prev {
  box-shadow: -10px 6px 8px 0px rgba(0, 0, 0, 0.15);
  left: -25px;
}

#boxSwiper .swiper-button-prev::after,
#boxSwiper .swiper-button-next::after {
  content: "none";
}

#boxSwiper .swiper-button-prev.swiper-button-disabled,
#boxSwiper .swiper-button-next.swiper-button-disabled {
  display: none;
}

.swiper-container {
  position: static;
}

#boxSwiper .swiper-button-prev {

  background-image: url('assets/darkArrowLeft.svg');
}

#boxSwiper .swiper-button-next {
  background-image: url('assets/darkArrowRight.svg');
}

#boxSwiper .swiper-button-prev,
#boxSwiper .swiper-button-next {
  background-repeat: no-repeat;
  background-position: center;
}


#boxSwiperPicture .swiper-pagination-bullets{
  bottom: 110px;
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #4E6AFF;
    margin: 0 8px;
  }
}