.title {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;

    color: #000000;

    padding-bottom: 12px;
}

.subTitle {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    color: #404042;
}

.wrapper {
    width: 100%;
    height: calc(var(--vh) * 100 - var(--navbar-height));
    padding: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (min-width: 1024px) {
    .wrapper {
        display: block;
        padding: 60px;
        height: 100%;
    }
}