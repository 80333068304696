.desktopWrapper {
  display: none;

  background-color: var(--background-color);
  /*box-shadow: inset 0 -1px 0 #DFDFEB;*/

  border-bottom: 1px solid var(--border-color);
  padding: 6px 20px;
  height: var(--navbar-height);
  justify-content: space-between;
  align-items: center;

  z-index: var(--z-index-12);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}

@media screen and (min-width: 1024px) {
  .desktopWrapper {
    display: flex;
  }
}

.logo {
  min-width: 70px;
  margin-right: 40px;
}

.navListWrapper {
  margin-left: 80px;
}
@media screen and (max-width: 1280px) {
  .navListWrapper {
    margin-left: 40px;
  }
}

.leftContent {
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.rightContent {

}