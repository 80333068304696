.main {
  height: 100%;
}

.list {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 8px;
  padding: 20px 0 10px;
}

@media (min-width: 768px) {
  .list {
    gap: 10px;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (min-width: 1024px) {
  .list {
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
}

@media (min-width: 1300px) {
  .list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1700px) {
  .list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.listItem {
  width: 100%;
  list-style: none;
  max-width: 400px;
}

.card {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid #F3F3F5;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
  text-decoration: none;
  padding: 4px;
}

.card:hover {
  background-color: var(--color-blue);
  color: #ffffff;
  transition: all .3s ease;
}

.card:hover span {
  color: #ffffff;
}

.imageFavorites {
  max-height: 210px;
}

.noDataFavorites {
  height: calc(100% - 42px);
}
