.list {

}

.list > * {
    border-bottom: var(--border-base) !important;
}

.list > *:last-child {
    border-bottom: none !important;
}