.swiper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.swiperWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - 100px);
  overflow: hidden;
  padding: 10px 0;
}

@media (max-width: 1024px) {
  .swiperWrap {
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
  }

  .swiper {
    box-shadow: none;
    min-height: 300px;
  }
}

.swiperSlide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-height: calc(var(--vh-100) - 210px);
}

.swiperSlide > img {
  margin: 10px 0;
  max-height: 100%;
  object-fit: cover;
}

.swiperWrapCottage {
  position: relative;
  padding-top: 0;
  max-height: calc(var(--vh-100) - 158px);
}

@media (max-width: 1024px) {
  .swiperWrapCottage {
    max-height: calc(var(--vh-100) - 158px);
  }
}

.swiperWrapCottage .swiperSlide > img {
  margin: 0;
  max-height: 100%;
  width: 85%;
  border-radius: 8px;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .swiperWrapCottage .swiperSlide > img {
    margin: 10px 0;
    max-height: 92%;
  }
}

@media (max-width: 768px) {
  .swiperWrapCottage .swiperSlide > img {
    width: 100%;
    height: 65vh;
    max-height: none;
    object-fit: cover;
    border-radius: 0;
  }
}

.swiperSlide > iframe {
  margin: 10px 0;
  width: 80%;
  height: calc(var(--vh-100) - 170px);
}

.swiperSlideFeedback {
  background-color: #404042;
}

.swiperSlideFeedback > div {
  margin: 10px 0;
  background-color: #fff;
  overflow: hidden;
}

.swiperSlideFeedback > div > * {
  position: absolute;
}

.swiperSlideFeedback > div > img {
  height: calc(var(--vh-100) - 170px);
  filter: blur(5px);
  opacity: 0.3;
  transform: scale(1.1);
  position: relative;
}

.topSwiperSlide {
  width: auto !important;
  transform: translateY(-11px);
}

@media (min-width: 1024px) {
  .topSwiperSlide {
    transform: translateY(0);
  }
}



.topSwiperSlide div {
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 8px;
}

.topSwiperSlide div::first-letter {
  text-transform: uppercase;
}

.topSwiperSlideActive {
  border-bottom: 3px solid #4E6AFF;
}

.thumbsSwiper {
  height: 100px;
  background-color: #F3F3F5;
  padding: 10px !important;
  overflow-x: auto !important;
  display: flex;
}

.thumbsSwiperCottage {
  background-color: transparent;
  height: 100px;
  padding: 23px 10px 0 !important;
}

.thumbsSwiperCottage .thumbsSwiperSlide {
  width: 122px !important;
  min-width: 122px;
  height: 70px;
  border-radius: 4px;
}

.thumbsSwiperSlide {
  height: 80px;
  width: 120px !important;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;

  cursor: pointer;
  overflow: hidden;
}

.thumbsSwiperSlideActive {
  border: 4px solid #4E6AFF;
}

.miniThumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.abs {
  display: flex;
}

@media screen and (max-width: 768px) {
  .showSwiper {
    opacity: 1;
    transition: opacity .3s ease-in;
  }

  .hiddenSwiper {
    opacity: 0;
    transition: opacity .3s ease-in;
  }
}
