.downloadPage {
  position: relative;
  padding: 23px 20px 92px;
  background-color: #F1F1F1;

  @media screen and (max-width: 768px) {
    padding: 16px 27px 94px;
    overflow-x: hidden;
  }

  .downloadPageImg {
    min-width: 557px;
    position: absolute;
    top: 304px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @media screen and (max-width: 768px) {
      top: 208px;
    }
  }

  .container {
    display: flex;
    background: transparent;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  .logo {
    width: 60px;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      margin-bottom: 29px;
    }
  }

  .content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
    width: 100%;
  }

  .imageWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 371px;
    margin-bottom: 6px;

    @media screen and (max-width: 768px) {
      max-width: 271px;
    }
  }

  .phoneImage {
    max-width: 100%;
    height: auto;
  }

  .textBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    color: var(--text-color);
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 8px;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  .downloadLink {
    margin-bottom: 24px;
    @media screen and (max-width: 768px) {
      padding: 12px;
      border-radius: 16px;
      background: #FFF;
    }
  }

  .desktopLogo {
    display: block;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .mobileLogo {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
      width: 181px;
      border: 1px solid #000000;
      border-radius: 8px;
    }
  }

  .description {
    color: var(--text-color);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    max-width: 586px;
    margin-bottom: 34px;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      margin-bottom: 29px;
      font-weight: 500;
    }
  }

  .link {
    color: var(--text-color);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    text-decoration: underline;
  }

  .downloadButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 323px;
    gap: 4px;
    padding: 16px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    color: #4E6AFF;
    background: transparent;
    border-radius: 8px;
    border: 1px solid var(--border-blue, #4E6AFF);
    text-decoration: none;
    transition: opacity 0.3s ease-in-out;

    @media screen and (max-width: 768px) {
      padding: 10px 12px;
      width: 264px;
    }

    &:hover {
      opacity: .9;
    }
  }

  .setupAppText {
    width: 100%;
    max-width: 586px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 34px;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      margin-top: 16px;
      margin-bottom: 20px;
    }
  }

  .setupAppButton {
    background: none;
    border: none;
    color: #4E6AFF;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    text-decoration-line: underline;
    margin-bottom: 4px;
    cursor: pointer;
    padding: 0;
    transition: color 0.2s ease;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      font-weight: 500;
    }

    &:hover {
      color: darken(#4E6AFF, 10%);
    }

    &[aria-expanded="true"] {
      margin-bottom: 8px;
    }
  }

  .setupAppText1 {
    color: #4E6AFF;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    text-decoration-line: underline;
    margin-bottom: 4px;
  }

  .setupAppText2 {
    color: #AFB4CB;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .setupApp {
    width: 100%;
    max-width: 432px;
    display: flex;
    flex-direction: column;
    gap: 34px;
    animation: fadeIn 0.3s ease-out;
    margin-bottom: 50px;

    @media screen and (max-width: 768px) {
      gap: 32px;
      max-width: 312px;
      margin-bottom: 30px;
    }
  }

  .setupAppBlock {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      max-width: 283px;

      @media screen and (max-width: 768px) {
        max-width: 214px;
      }
    }
  }

  .setupAppNum {
    position: absolute;
    top: 0;
    left: 0;
    color: #AFB4CB;
    font-family: Inter;
    font-size: 80px;
    font-weight: 400;
    line-height: 120%;

    @media screen and (max-width: 768px) {
      font-size: 50px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}