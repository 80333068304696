.generalPlanMap {
  font-family: Inter;
  position: relative;
  width: 100%;
  height: calc(100vh - 194px);
}

//.generalPlanMapContainer {
//  width: 100%;
//  background: var(--village-wrapper-background);
//}

.generalPlanMapContainer {
  position: relative;
  width: 100%;
}

.generalPlanMapButtons {
  @media screen and (max-width: 768px) {
    bottom: 8px;
    left: 8px;
    top: auto;
    transform: none;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(40px);
  z-index: 1;
  pointer-events: none;
}

//.generalPlanMapContainer > :not(.backgroundImage) {
//  position: relative;
//  z-index: 2;
//}

.genPlanToggle {
  width: 100%;
  margin-bottom: 0;
  padding: 5px 20px 0;
  @media screen and (max-width: 768px) {
    padding: 8px 16px;
  }
}

.generalPlanTooltip {
  padding: 16px!important;
  border-radius: 16px!important;
  background: var(--village-wrapper-background)!important;
  border: none!important;
  @media screen and (max-width: 768px) {
    display: none;
  }

  &:before {
  }
}

@media screen and (max-width: 768px) {
  .generalPlanMap {
    height: calc(var(--vh-100) - 239px);
  }
}
