.editSidebarHeader {
  display: grid;
  gap: 15px;
  border-bottom: 1px solid #dfdfeb;
  padding-top: 10px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.editSidebarOfferDescription {
  margin-bottom: 20px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #404042;
}

.editSidebarFooter {
  display: grid;
  gap: 10px;
}

.editSidebarFooterButtonGroup {
  display: grid;
  gap: 10px;
  grid-template-columns: auto 40px;
}

.editSidebarFooterContactWrapper {
  display: flex;
  font-weight: bold;
}

