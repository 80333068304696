.wrapper {
  height: calc(var(--vh-100) - var(--navbar-height));
  display: grid;

  grid-template-rows: auto max-content;
}

.footer {
  padding: 16px 12px;
}

.address {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #404042;

  margin-bottom: 8px;
}
