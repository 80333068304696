.wrapper {
  display: none;
}

@media (max-width: 767px) {
  .wrapper {
    margin: 12px;
    display: flex;

    flex-direction: column;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
  }

  .logoWrapper {
    margin-bottom: 0;
  }

  .toFlatsLink {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: Circe;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;

    margin-left: 0;
    max-width: max-content;
  }

  .complexTitle {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;

    color: #000000;

    margin-bottom: 3px;
    margin-top: 10px;
  }

  .complexText {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #000000;
  }

}
