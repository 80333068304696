.form {

}

@media (min-width: 1024px) {
    .form {
        margin-bottom: 40px;
    }
}

.title {
    display: none;
    text-align: center;

    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;

    color: var(--color-black);

    margin-bottom: 20px;
}

.fieldGroups {
    display: grid;
    gap: 24px;
    padding-bottom: 24px;
}

@media (min-width: 1024px) {
    .fieldGroups {
        gap: 20px;
        padding-bottom: 20px;
    }
}

.fieldNameGroups {
    display: grid;
    gap: 24px;
}

@media (min-width: 1024px) {
    .fieldNameGroups {
        gap: 20px;
        grid-template-columns: repeat(2, calc(50% - 10px));
    }
}