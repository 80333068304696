
.currentCity {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.currentCity p {
  color: #4E6AFF;

  font-weight: bold;
  font-size: 16px;
  line-height: 16px;

  margin-left: 7px;
}
