.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.planWrapper {
  margin-bottom: 8px;
  width: 120px;
  height: 120px;
  background: #ffffff;
  @media screen and (max-width: 768px) {
    width: 84px;
    height: 84px;
  }
}

.plan {
  //background-color: var(--cottage-view-background);
  max-width: 120px;
  height: 120px;
  object-fit: contain;
  @media screen and (max-width: 768px) {
    max-width: 84px;
    height: 84px;
  }
}
