.apartmentCardUpdateDate {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: left;

  color: #b9b9b9;
}

@media (min-width: 768px) {
  .apartmentCardUpdateDate {
    text-align: right;
  }
}
