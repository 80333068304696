.wrapper {
    display: grid;
    gap: 20px;
}
.realtorInfoList {
  display: grid;
  gap: 10px;

  text-align: left;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: #000000;
}
