.wrapper {
  padding: var(--indent-10) var(--indent-40) var(--indent-30) var(--indent-20);

  background-color: var(--color-blue-50);
  border-radius: var(--border-radius-size-m);

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

@media (max-width: 1280px) {
  .wrapper {
    padding: var(--indent-20);
  }
}

.leftWrapper {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 680px;
  margin-right: 5%;
}

@media (max-width: 1280px) {
  .leftWrapper {
    display: none;
  }
}

.rightWrapper {
  display: flex;
  max-width: 300px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}


@media (max-width: 1280px) {
  .rightWrapper {
    justify-content: flex-start;
  }
}

.rightWrapper > * {
  flex: 1 0 auto;
}


.filterButton {
  margin-right: var(--indent-10);
}

.resetButton {
  margin-right: var(--indent-30);
}

@media (max-width: 540px) {
  .resetButton span:last-of-type {
    display: none;
  }

  .resetButton span:first-of-type {
    margin-right: 0;
  }

  .rightWrapper > * {
    flex: 0 0 auto;
  }

  .toAppartmentsLink {
    margin-left: auto;
  }

  .rightWrapper {
    max-width: none;
  }
}

.toAppartmentsLink {
  max-width: 170px;
  flex: 1 0 auto;
}

@media (max-width: 540px) {
  .toAppartmentsLink {
    max-width:  max-content;
  }
}
