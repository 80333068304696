.floorButton {
  cursor: pointer;
  background-color: var(--color-white);
  border: 2px solid var(--color-blue-200);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
}

.floorButtonActive {
  border-color: var(--color-blue);
}