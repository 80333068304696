.wrapper {
    display: flex;
    flex-direction: column;
}

.grayText {
    color: #B9B9B9;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 11px;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.layoutsWrapper {
    margin-left: auto;
}

/* STATUSES */
.marginRight10 {
    margin-right: var(--indent-10);
}

.typeRoom {
  color: var(--text-color);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-transform: uppercase;
  opacity: 0.35;
}

.active .typeRoom {
  color: #FFFFFF;
}

.numberApartment {
  color: var(--text-color);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.active .numberApartment {
  color: #FFFFFF;
}

.roomsApartment {
  color: var(--text-color);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.active .roomsApartment {
  color: #FFFFFF;
}

.finishing {
  color: var(--text-color);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.active .finishing {
  color: #FFFFFF;
}

.priceM {
  color: var(--text-color);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.35;
}

.active .priceM {
  color: #FFFFFF;
}

.price {
  color: #4E6AFF;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.active .price {
  color: #FFFFFF;
}