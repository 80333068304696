@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500@600@700@900&display=swap');

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 100%;
  background: var(--background-color, #fff);
  margin: 40px auto;
  border-radius: 24px;
  height: auto;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  padding: 32px;
  font-family: Inter;
  @media screen and (max-width: 768px) {
    margin: 0;
    overflow: hidden;
    height: 100%;
    max-height: none;
    padding: 0 0 16px;
    border-radius: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    flex-direction: row-reverse;
    align-items: center;
    padding: 14px 16px 16px;
    margin-bottom: 14px;
    border-bottom: 1px solid var(--border-filter-bottom);
  }
}

.headerMobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-14);
  position: fixed;
  inset: 0;
  top: 0;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
}

.modal {
  padding: 0;
  width: 100%;
  outline: none;
}

.closeIcon {
  cursor: pointer;
}

.textClassName {
  @media screen and (max-width: 768px) {
    padding: 0;
    border: none;
    width: 16px;
    height: 16px;
    min-width: auto;
    min-height: auto;
    background: transparent;
  }
}

.title {
  color: var(--text-color);
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
}

.subHeader {
  color: #AFB4CB;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  margin-top: -8px;
  margin-bottom: 24px;
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
}

.columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-bottom: 96px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 24px;
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    margin-bottom: 40px;
    padding: 0 16px 40px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.switchLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.switch {
  appearance: none;
  width: 35px;
  height: 20px;
  background: var(--switch-off-background);
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: background 0.3s;
  @media screen and (max-width: 768px) {
    width: 50px;
    height: 30px;
  }

  &:checked {
    background: var(--switch-checked-background);
  }

  &::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background: var(--background-color);
    border-radius: 50%;
    top: 2px;
    left: 1px;
    transition: transform 0.3s;
    @media screen and (max-width: 768px) {
      width: 23px;
      height: 23px;
      top: 3px;
      left: 6px;
    }
  }

  &:checked::before {
    transform: translateX(16px);
  }
}

.actions {
  width: 100%;
  padding: 16px 32px 32px;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid var(--border-filter-bottom);
  background: var(--background-color, #fff);
  @media screen and (max-width: 768px) {
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);
    padding: 4px 16px 16px;
  }
}

.resetButton {
  background: #fff;
  padding: 10px 11px;
  border-radius: 8px;
  border: 1px solid #DFDFEB;
  cursor: pointer;
  color: #333338;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.applyButton {
  border-radius: 8px;
  background: #4E6AFF;
  color: #fff;
  border: none;
  padding: 10px 11px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.disabled input {
  //background: #E7E8F0;
  opacity: .3;
}

.disabled span {
  //color: #C2C2C3;
  opacity: .3;
}
