.wrapper {
    display: grid;
    gap: 60px;
    grid-template-columns: repeat(2, calc(50% - 30px));
    height: calc(200px + 10px);
    overflow: hidden;
}

.open {
    height: auto;
}

.iconWithTextWrapper {
    text-decoration: none;
}