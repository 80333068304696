.wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.logo {
    flex-shrink: 0;
}

.content {
    flex-shrink: 1;
}
