.cityList {
  padding: 0 16px;
}

.regionBlock {
  padding: 8px 0;
  border-bottom: 1px solid #DFDFEB;
}

.regionBlock:last-child {
  border-bottom: none;
}

.regionTitle {
  color: var(--text-color);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 6px;
}

.cityItem {
  color: var(--text-color);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  padding: 6px 0;
  cursor: pointer;
}

.cityItem:hover {
  color: #3366ff;
}

.modalContentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  h2 {
    color: var(--text-color);
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
  }
}

.closeBtn {
  background: none;
  border: none;
  cursor: pointer;
}

.textClassName {
  border: 1px solid #DFDFEB;
}
