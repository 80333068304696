.tr {
    cursor: pointer;
}

.tr:hover td:before {
    background-color: #F3F3F5;
}

.isActive td:before {
    background-color: #4E6AFF;
}
.isActive * {
    color: #ffffff;
}
.isActive:hover td:before {
    background-color: #4E6AFF;
}
