.popupOffersModal {
  display: flex;
  align-items: flex-end;
  height: calc(100% - 131px);
  max-width: 400px;
  right: 0;
  top: auto;
  bottom: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 1024px) {
  .popupOffersModal {
    top: auto;
    left: 0;
    right: auto;
    bottom: 0;
    align-items: flex-start;
    height: var(--vh-100);
    max-width: 100%;
    z-index: 3;
  }
}

.popupOffersModal > div {
  max-width: 400px;
  height: calc(100% - 40px);
  background-color: var(--cottage-view-background);
  border-radius: 24px 24px 0 0;
  border: none;
  padding: 20px;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .popupOffersModal > div {
    width: 100%;
    height: 100%;
    border-radius: 0;
    max-width: 100%;
    overflow: hidden;
  }
}

@media screen and (max-width: 1024px) {
  .popupOffersModal > div {
    padding: 0;
  }
}

.addToOffersFormWrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .addToOffersFormWrapper {
    overflow: auto;
  }
}

@media screen and (max-width: 768px) {
  .addToOffersFormWrapper {
    height: var(--vh-100);
  }
}

.addToOffersButton {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 8px;
  background: #4E6AFF;
  border: none;
  outline: none;
  padding: 10px 12px;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  cursor: pointer;
}