.contentWrapper {
  padding: 0 40px 0 35px;
  margin-bottom: 0;
  margin-top: 80px;
  overflow-y: auto;
  position: relative;
}

@media (max-width: 768px) {
  .contentWrapper {
    overflow-y: unset;
  }
}

.layoutWrapper {
  background: #f3f3f5;
  width: 100%;
  display: flex;
  justify-content: center;
  height: calc(var(--vh-100) - var(--navbar-height));
}

.layout {
  display: grid;
  grid-template-columns: 340px 1fr;
  /*margin: 30px auto 60px;*/
  /*max-width: 1228px;*/
  /*min-height: 600px;*/
  background: #FFFFFF;
  width: 100%;
}

@media (max-width: 1024px) {
  .layout {
    grid-template-columns: 30% 1fr;
  }
}

@media (max-width: 768px) {
  .layout {
    grid-template-rows: max-content max-content;
    grid-template-columns: unset;
    padding: 0;
    margin: 0;
    min-height: auto;
    overflow-y: auto;
  }

  .contentWrapper {
    padding: 12px 12px 52px 12px;
  }
}
