.wrapper {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  /* Grey */

  color: #b9b9b9;

  text-align: left;
}

@media (min-width: 768px) {
  .wrapper {
    text-align: center;
  }
}

.iconWithTextWrapper {
  display: inline;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  text-align: left;
  /* Grey */

  color: #b9b9b9;
  text-decoration: underline;

  padding: 0;
  margin: 0;
  border: none;
}

.iconWithTextWrapper:hover{
  color: #4E6AFF;
}


@media (min-width: 1024px) {
  .iconWithTextWrapper{
    text-align: center;
  }
}