.wrapper {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    min-width: 130px;
    height: 24px;

    background: var(--stepper-background);
    border-radius: 4px;
    padding: 4px;
}

.wrapper {
  position: absolute;
  bottom: 5px;
  left: 0;
  min-width: auto;
  height: auto;
  border-radius: 12px;
  background: #404042;
}

@media screen and (max-width: 768px) {
  .wrapper {
    position: absolute;
    bottom: 0;
    min-width: auto;
    height: auto;
    border-radius: 12px;
    background: #404042;
  }
}


.button {
    background-color:transparent;
    padding: 0;
    height: 16px;
    width: 16px;
    border:none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.button {
  display: none;
}

@media screen and (max-width: 768px) {
  .button {
    display: none;
  }
}

.button:first-child {
  transform: rotate(180deg);
}

.button:disabled {
    opacity: 0.3;
    cursor: default;
}

.buttonIcon {
  display: block;
  width: 16px;
}


.counter {
  margin: 0 8px;
  color: var(--text-color);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.counter {
  color: #FFF;
  font-size: 9px;
  font-weight: 500;
  line-height: 140%;
}

@media screen and (max-width: 768px) {
  .counter {
    color: #FFF;
    font-size: 9px;
    font-weight: 500;
    line-height: 140%;
  }
}
