:root {
    --complex-list-header-height: 60px;
}

.heading {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headingText {
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
}

@media screen and (min-width: 768px)  {
    .headingText {
      color: var(--text-color);
      font-family: Inter;
      font-size: 18px;
      font-weight: 700;
      line-height: 140%;
    }
}
