.offersList {
  max-height: calc(100% - 140px); /*  высота = 100% - высота табов с поиском */
  overflow-y: auto;

  display: grid;
  grid-gap: 10px;
  gap: 10px;

  margin-bottom: 20px;
  max-width: 815px;
}

@media (max-width: 768px) {
  .offersList {
    overflow: inherit;
  }
}
