.container {
    position: relative;
    display: inline-block;
    font-family: Circe;
    font-style: normal;
    font-weight: normal;
}

.inputWrapper {
    position: relative;
}

.inputIcon {
    display: block;
    width: 24px;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    z-index: var(--z-index-3);
}

.input {
    display: block;
    width: 100%;
    box-sizing: border-box;
    height: 46px;
    padding-left: 48px;
    padding-right: 16px;

    color: var(--text-color);
    font-family: Inter;
    font-style: normal;
    font-size: 16px;
    line-height: 140%;

    border-radius: 8px;
    border: 1px solid #DFDFEB;
    background: #F8F8F8;
}

@media screen and (min-width: 1024px) {
  .input {
    width: 100%;
    max-width: 280px;
    min-width: 200px;
  }
}

@media screen and (min-width: 1600px) {
  .input {
    width: 280px;
  }
}

@media screen and (min-width: 1900px) {
  .input {
    width: 360px;
  }
}

.input::placeholder {
  color: #AFB4CB;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.inputFocused, .inputFocused:focus, .inputFocused:focus-visible, .input:focus, .input:focus-visible {
  outline: 2px solid #4E6AFF;
}

.suggestionsContainer {
    position: absolute!important;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    z-index: 2;
    min-width: 350px;
}

.suggestionsContainerOpen {
    border-radius: 16px;
    background: #FFF;
    /* pop-up */
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);
    max-height: calc(45px * 10);
    padding: 16px;
}

.sectionTitle {
  color: #AFB4CB;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 4px;
}

.suggestionBlock {
  display: none;
}

.suggestionsContainerOpen .suggestionBlock {
  display: block;
}

.suggestionEmpty {
  position: absolute!important;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  z-index: 2;

  border-radius: 16px;
  background: #FFF;
  /* pop-up */
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);
  max-height: calc(45px * 10);
  padding: 16px;

  color: #AFB4CB;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.suggestionsList {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0;
    margin: 0;
}

.suggestion {
    padding: 8px;
    cursor: pointer;
    transition: background-color .3s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.suggestion:hover {
  border-radius: 4px;
  background-color: #F3F3F5;
  transition: background-color .3s ease;
  /*overflow-x: hidden;*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.suggestionInner {
  display: flex;
  align-items: center;
  gap: 8px;
}

.suggestionImg {
  width: 68px;
  min-width: 68px;
  height: 44px;
  border-radius: 4px;
}

.suggestionImgDev {
  width: 45px;
  min-width: 45px;
  height: 45px;
  border-radius: 4px;
}

.suggestionImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.suggestionImgDev img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

/*.suggestionHighlighted {*/
/*    font-weight: bold;*/
/*}*/

.selectIcon {
    display: block;
    margin-right: 4px;
    max-width: 16px;
}

.suggestionTitle {
  color: #333338;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  transition: color .3s ease;

  display: inline-block;    /* или block, главное чтобы был блочный контекст */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.suggestion:hover .suggestionTitle {
  color: #4E6AFF;
  transition: color .3s ease;
}

.suggestionTextBlock {
  display: flex;
  flex-direction: column;

  flex: 1;
  min-width: 0;
}

.suggestionSubtitle {
  color: #AFB4CB;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.suggestionVerifiedIcon {
  width: 18px;
  height: 18px;
}

.suggestionVerifiedIcon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.suggestionTitleBlock {
  display: flex;
  align-items: center;
  gap: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
