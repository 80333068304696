.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.wrapperVillage {
  font-family: Inter;
  justify-content: flex-end;
  gap: 31px;
}

@media screen and (max-width: 768px) {
  .wrapperVillage {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.headerRightButton {
  border-radius: 24px;
  background: var(--toggle-active-background);
  padding: 8px;
}

@media screen and (max-width: 768px) {
  .headerRightButton {
    border: none;
    background: none;
    padding: 0;
    justify-content: flex-start;
  }
}

.headerRightFilter {
  button {
    background: transparent;
    color: var(--text-color);
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
    border-radius: 8px;
    border: 1px solid var(--border-icon-color);
  }

  button {
    height: 40px;
  }

  button:first-child {
    border-radius: 8px;
    background: #E3E3FF;
    color: #4E6AFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
}

.headerRightFilterChess {
  button {
    height: 40px;
  }
}

.villageFiltersBtn {
  padding: 0;
  justify-content: flex-end;
}

.wrapperBlocks {
  display: flex;
  align-items: center;
}

@media (min-width: 1200px) {
    .wrapper {
        min-width: 800px;
    }
}
@media (min-width: 1440px) {
    .wrapper {
        min-width: 860px;
    }
}
