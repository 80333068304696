.loaderWrapper {
    overflow: hidden;
    position: relative;
    min-width: 72px;
    min-height: 55px;
    border-radius: var(--border-radius-size-l);
    border: var(--border-base);
    background-color: var(--color-blue-200);
}

.loaderWrapper::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    content: '';
}
@media (min-width: 768px) {
    .loaderWrapper::after {
        animation: shimmer 2s infinite;
    }
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

.iconWithTextWrapper {
    display: block;
    height: 100%;
    text-decoration: none;
}


.gridCardLinkWrapper {

}

.gridCardLink {
    height: 100%;
}