.wrapper {
    position: relative;
    border: 1px solid #DFDFEB;
    border-radius: 8px;
    overflow: hidden;
}

.wrapperCircle {
    border-radius: 50%;
}

.image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
