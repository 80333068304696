.tutorial {
  position: fixed;
  top: calc(50% + 28px);
  right: 830px;
  transform: translate(0, -50%);
  width: 270px;
  background: #ffffff;
  border-radius: 16px;
  padding: 16px;
  z-index: 21;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);

  &Arrow {
    position: absolute;
    right: -12px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.video {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 8px;
}

.title {
  color: var(--text-color);
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  text-align: left;
  margin-bottom: 8px;
}

.text {
  color: var(--text-color);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
}

.button {
  width: 100%;
  background-color: #4E6AFF;
  padding: 10px 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  transition: opacity 0.2s ease;
}

.button:hover {
  opacity: .9;
}
