.header {
  height: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  bottom: -1px;
}

.map {
  background-color: #ffffff;
  object-fit: contain;
  margin: 0 auto;
  height: auto;
  width: auto;
  max-width: calc(100vw - 120px);
  max-height: calc(var(--vh-100) - 120px);
}

.mapWrapper {
  position: absolute;
  z-index: 99999;
  top: calc(50% - 100px);
  transform: translate(0%, -50%);
}
