.wrapper {
    overflow: hidden;
    /* 80px - header height */
    /* 52px - building tabs height */
    height: calc(var(--vh-100) - var(--layout-header-height) - var(--layout-building-tabs-height));
}
.scrollContainer {
    overflow: auto;
    height: 100%;
}
