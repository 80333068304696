.wrapper {
    background-color: #F1F1F1;
    display: grid;
    grid-template-areas:
    "header header"
    "floor grid";
    grid-template-columns: 100px min-content;
}

.header {
    grid-area: header;

    display: grid;
    grid-template-columns: repeat(5, 155px);
    height: 60px;

    margin-left: 99px;

    border: 1px solid #DFDFEB;
    width: min-content;
}

.headerTitle {
    height: 20px;
    width: 100%;
    grid-row-start: 1;
    grid-row-end: 2;
    padding: var(--indent-5);
}

.headerCol {
    height: 40px;
    border: 1px solid #DFDFEB;

    grid-row-start: 2;
    grid-row-end: 3;

    font-size: var(--size-text-xl);
    line-height: var(--size-text-2xl);
    color: #B9B9B9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.floor {
    grid-area: floor;

    width: 100px;
}
.floorRow {
    width: 100px;
    height: 105px;
    border: 1px solid #DFDFEB;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 700;
}


.grid {
    grid-area: grid;
    display: grid;
    grid-template-columns: repeat(5, 150px);
    grid-template-rows: repeat(5, 100px);
    gap: 5px;
    padding: 5px;
    background-color: #F1F1F1;
    height: 100%;
}

.item {
    border-radius: 8px;
    display: block;
}