
.form {
    display: grid;
    gap: 20px;
}

.title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: #000000;
}