.advantages {
  padding: 50px 32px;
}

.sectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #333338;
  font-size: 65px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 68px;
  border-bottom: 8px solid #333338;
  padding-bottom: 32px;
}

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 32px;
}

.item {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #333338;
  font-size: 32px;
  font-weight: 600;
  line-height: 140%;
  padding: 11px 10px;
}

.icon {
  width: 48px;
}
