.content {
    width: 100%;
    height: 100%;
}

@media (min-width: 768px) {
    .content {
        max-width: 384px;
    }
}
@media (orientation: landscape) {
    .content {
        max-width: 100%;
    }
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: var(--z-index-13);
    width: 100vw;
    height: calc(var(--vh) * 100);
    background: rgba(0,0,0,0.5);
}

@media screen and (max-width: 768px) {
  .overlay {
    right: auto;
    left: 0;
    z-index: 13;
    height: var(--vh-100);
  }
}

.scrollbarWrapper {
    margin-top: var(--navbar-height);
    height: calc(100% - var(--navbar-height));
    width: 100%;
    background-color: var(--color-white);

    overflow: auto;
}

@media screen and (max-width: 768px) {
  .scrollbarWrapper {
    margin-top: 0;
    height: var(--vh-100);
  }
}