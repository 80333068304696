.wrapper {
  padding: 16px 12px;
  background-color: #F3F3F5;
}

@media screen and (min-width: 1024px){
  .wrapper {
      padding: 0;
      background-color: transparent;
  }

}

.item  {
  display: flex;
  justify-content: space-between;
}
