.list {
    width: 100%;
}

.iconWithTextWrapper {
    display: block;
    text-decoration: none;
    /*max-width: ;*/
}

.swiperItem {
    width: calc(100% - 12px)!important;
    padding-right: 12px;
}
