#albumPageSlider .swiper-button-prev,
#albumPageSlider .swiper-button-next {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    background-size: 50%;
    background: #FFFFFF no-repeat center;
}

@media (max-width: 768px) {
    #albumPageSlider .swiper-button-prev,
    #albumPageSlider .swiper-button-next {
        display: none;
    }
}

#albumPageSlider .swiper-button-prev::after,
#albumPageSlider .swiper-button-next::after {
    content:"none";
}

#albumPageSlider .swiper-button-prev {
    background-image: url('assets/darkArrowLeft.svg');
}

#albumPageSlider .swiper-button-next {
    background-image: url('assets/darkArrowRight.svg');
}

#albumPageSliderCottage .swiper-button-prev,
#albumPageSliderCottage .swiper-button-next {
  height: 64px;
  width: 48px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  border: 1px solid #DFDFEB;
  background: rgba(255, 255, 255, 0.08) no-repeat center;
  backdrop-filter: blur(7.5px);
  cursor: pointer;
}

@media (max-width: 768px) {
  #albumPageSliderCottage .swiper-button-prev,
  #albumPageSliderCottage .swiper-button-next {
    display: none;
  }
}

#albumPageSliderCottage .swiper-button-prev::after,
#albumPageSliderCottage .swiper-button-next::after {
  content:"none";
}

#albumPageSliderCottage .swiper-button-prev {
  background-image: url('assets/lightArrowLeft.svg');
}

#albumPageSliderCottage .swiper-button-next {
  background-image: url('assets/lightArrowRight.svg');
}

#swiper-thumbnails .swiper-wrapper {
    width: auto;
    margin: 0 auto;
}

.img-fit {
    object-fit: cover;
}

#albumsHeader {
    overflow-x: auto;
}
