.feedItemWrapper {
  display: grid;
  gap: 8px;
}

@media (min-width: 1024px) {
  .feedItemWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.feedItemUpdateAt {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;


  color: #404042;
}


@media (min-width: 1024px) {
  .feedItemUpdateAt {
    text-align: right;
  }
}

.feedItemFieldWrapper {
  display: flex;
  width: 100%;
}

.feedItemFieldWrapper > * {
  margin-right: 12px;
}


@media (min-width: 1024px) {
  .feedItemFieldWrapper {
    max-width: 60%;
  }
}