.medium {
    width: 40px;
    height: 40px;
}
.large {
    width: 60px;
    min-width: 60px;
    height: 60px;
}

.xLarge {
    width: 100px;
    height: 100px;
}
