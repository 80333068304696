.formWrapper {
  display: grid;
  gap: 20px;

}

.previewImageWrapper {
  background-color: #dfdfeb;
  justify-content: flex-end;
  display: flex;
}
.previewImageWrapper img{
  margin: inherit;
}