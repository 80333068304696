.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 60px;
}

.icon {
  width: 84px;
  height: 84px;
  border-radius: 24px;

  margin-bottom: 30px;
}

.title {
  font-weight: bold;
  font-size: 32px;
  line-height: 47px;

  color: #000000;

  margin-bottom: 20px;
}

.description {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #404042;
}

@media (max-width: 767px) {
  .wrapper {
    align-items: center;
    padding: 12px;
    height: calc(100% - 164px);
  }

  .title,
  .description {
    text-align: center;
  }

  .title {
    font-size: 22px;
    line-height: 28px;

    margin-bottom: 8px;
  }

  .description {
    font-size: 18px;
    line-height: 24px;
  }
}
