:root{
  --fullpopup-top-header-height: 56px;
  --fullpopup-header-height: 36px;
}

@media(min-width: 1024px) {
  :root{
    --fullpopup-header-height: 50px;
  }
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: var(--z-index-4);
  top: 8px;
  right: var(--indent-20);
  width: 35px;
  height: 35px;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid var(--border-icon-color);
  background-color: transparent;
  border-radius: 8px;
}

@media screen and (min-width: 1024px) {
  .header.headerAlbum ~ .closeButton {
    top: 30px;
    right: 40px;
  }
}

@media(max-width: 768px) {
  .topHeader ~ .closeButton {
    width: 16px;
    height: 16px;
    top: 18px;
    justify-content: flex-start;
    right: auto;
    left: 16px;
    border: none;
    outline: none;
    padding: 0;
  }
}

.closeButtonIcon {
  display: block;
  width: 16px;
  height: 16px;
}

.modalOverlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-14);
  inset: 0;
  display: flex;
  width: 100vw;
  height: var(--vh-100);
  overflow: hidden;
  flex-direction: column;
}

.modalOverlayAlbum {
  background: rgba(0, 0, 0, 1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.modalContent {
  background-color: var(--background-color);
  position: relative;
  width: 100vw;
  height: calc(var(--vh) * 100);
}

.modalContentAlbum {
  background: transparent;
}

.header {
  composes: border_position_bottom from '../../Border/styles.module.css';

  height: 50px;
  width: 100%;
}

.header.headerAlbum {
  display: none;
}

@media (min-width: 1024px) {
  .header {
    width: 100%;
    padding-left: 20px;
    border: none;
    border-bottom: 1px solid var(--border-icon-color);
    box-sizing: border-box;
    background: var(--background-color);
  }

  .header.headerAlbum {
    display: flex;
    background: transparent;
    padding-left: 40px;
    height: 65px;
    border: none;
    padding-top: 30px;
  }
}

.headerContent {
  width: calc(100% - 50px);
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.headerAlbum .headerContent {
  overflow: visible;
}

.headerContentWithTopHeader {
  width: 100%;
}
@media (min-width: 1024px) {
  .headerContentWithTopHeader {
    width: calc(100% - 50px);
  }
}

.content {
  height: calc(var(--vh) * 100 - var(--fullpopup-header-height));
  background: var(--modal-content-background);
}

.contentAlbum {
  background: transparent;
}

@media (min-width: 1024px) {
  .content {
    height: calc(var(--vh) * 100 - var(--fullpopup-header-height));
  }
}

.contentWithTopHeader {
  height: calc(var(--vh) * 100 - var(--fullpopup-header-height) - var(--fullpopup-top-header-height));
}

@media screen and (max-width: 1024px) {
  .modalContentAlbum .contentWithTopHeader {
    height: calc(var(--vh) * 100 - 57px);
    background: #000000;
  }
}

@media (min-width: 1024px) {
  .contentWithTopHeader {
    height: calc(var(--vh) * 100 - var(--fullpopup-header-height));
  }
}

.topHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  box-shadow: inset 0 -1px 0 #DFDFEB;
  min-height: var(--fullpopup-top-header-height);

  color: var(--text-color);
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
  border-bottom: 1px solid var(--border-filter-bottom);
}

.topHeaderDark {
  box-shadow: none;
}

.topHeaderAlbum {
  background: #000000;
  box-shadow: none;
  border: none;
}

@media (min-width: 1024px) {
  .topHeader {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .closeButtonCottageShow {
    opacity: 1;
    transition: opacity .3s ease-in;
  }

  .closeButtonCottageHidden {
    opacity: 0;
    transition: opacity .3s ease-in;
  }
}