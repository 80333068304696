.root {
  margin-top: 0;
}

.typeList {
  max-width: 200px;
  padding: 10px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .typeList {
    width: 1px !important;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
  }
}

.listHeading {
  padding: 20px 15px;

  font-weight: bold;
  font-size: 20px;
  line-height: 16px;
}

.map {
  height: 450px;
  position: relative;
}

.icon {
  filter: invert(43%) sepia(87%) saturate(4105%) hue-rotate(221deg) brightness(101%) contrast(102%);
}

.iconRoot {
  width: 50px;
  height: 64px;
  padding-bottom: 16px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconWrapper {
  background: white;
  border-radius: 50px;
  width: 38px;
  height: 38px;
  padding: 7px;
  display: flex;
}
