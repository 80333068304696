.wrapper {
    display: grid;
}

.scrollWrapper {
    composes: indent_padding_bottom_5 from "../../../../../../components/atoms/Indents/styles.module.css";

    background-color: #F1F1F1;
    max-height: calc((var(--vh, 1vh) * 100) - var(--layout-header-height) - var(--layout-building-tabs-height) - var(--navbar-height));
    overflow: auto;
}

.scroll {
    width: max-content;
}