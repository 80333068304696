.row {
    width: 26px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: var(--indent-2);
}
.icon {
    display: block;
    width: 12px;
    height: 12px;
    margin-left: auto;
}
