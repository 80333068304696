.root {
  position: relative; /* Для dragHandle position:absolute/relative */
  background: var(--color-white);
  padding: 0 12px;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  max-height: calc(var(--vh-100) - var(--navbar-height));
  height: 100%;
  /*box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);*/
}

@media (min-width: 768px) {
  .root {
    padding: 0 24px;
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);
  }
}

@media (min-width: 1024px) {
  .root {
    padding: 0 20px 20px;
  }
}

.dragHandle {
  position: absolute;
  left: -19px;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
  height: 40px;
  border-radius: 24px 0 0 24px;
  background: #FFFFFF;
  padding: 8px 5px 8px 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grabbing;
  z-index: 10;
}

.emptyDataWrapper {
  margin-top: -40px;
}

@media screen and (min-width: 1024px) {
  .emptyDataWithTelegram {
    height: calc(100% - 340px);
  }
}

/* На мобильных — auto-fit изначально */
.list {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  gap: 8px;
  margin-bottom: 20px;
  padding: 0;
  list-style: none;
}

@media (min-width: 768px) {
  .list {
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }
}

@media (min-width: 1024px) {
  .list {
    gap: 8px;
    grid-template-columns: auto;
    margin-bottom: 0;
  }
}

@media (min-width: 1440px) {
  .list {
    grid-template-columns: 1fr 1fr;
  }
}

.listItem {
  width: 100%;
  list-style: none;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #F3F3F5;
  background: #FFF;
  padding: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
  text-decoration: none;
}

.cardHover {
  background-color: var(--color-blue) !important;
  color: #ffffff;
}

.cardHover span {
  color: #ffffff !important;
}

.cardHover * {
  color: #ffffff;
}
