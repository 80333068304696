.squareListWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 4px;
    border-radius: 16px;
    border: 1px solid var(--border-icon-color);
    padding: 8px 0;
}

.squareListWrapper > * {
    margin-right: 12px;
}
.squareListWrapper > *:last-child {
    margin-right: 0;
}


.squareItemLabel {
    color: var(--plan-toggle-count-color);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media screen and (max-width: 768px) {
  .squareItemLabel {
    font-size: 12px;
    margin-bottom: 4px;
  }
}

.squareItemContent {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--text-color);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

@media screen and (max-width: 768px) {
  .squareItemContent {
    font-size: 14px;
    font-weight: 700;
  }
}

.squareItemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  padding: 11px;
  border-radius: 8px;
  background: transparent;
}

.squareTextBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  text-align: left;
}
