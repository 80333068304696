.filterButton {
  border-radius: 24px;
  outline: 1px solid var(--border-icon-color);
  background: transparent;
  padding: 10px 16px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  transition: all 0.1s ease;
  border: none;
}

@media screen and (max-width: 768px) {
  .filterButton {
    outline: 1px solid var(--border-icon-color);
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 500;
  }
}

@media screen and (min-width: 1024px) {
  .filterButton:hover {
    outline: 1px solid #DFDFEB;
    background: rgba(78, 106, 255, 0.10);
    color: #4E6AFF;
  }
}

.selectedButton {
  color: #4E6AFF;
  outline: 2px solid #4E6AFF;
  background: rgba(78, 106, 255, 0.10);
  border: none;
  @media screen and (min-width: 1024px) {
    &:hover {
      outline: 2px solid #4E6AFF;
    }
  }
}

@media screen and (max-width: 768px) {
  .selectedButton {
    outline: 1px solid #4E6AFF;
  }
}

.disabledButton {
  opacity: 0.5;
  cursor: not-allowed;
}
