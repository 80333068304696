.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerText {
  color: #000000;

  font-size: 24px;
  line-height: 35px;
}

@media (max-width: 768px) {
  .headerText {
    font-size: 18px;
    line-height: 27px;
  }
}
