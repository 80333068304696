.businessCard {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 32px;
  background-color: #ffffff;
  gap: 24px;
  page-break-inside: avoid;
  break-inside: avoid;
}

.profile {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.profilePhoto {
  width: 129px;
  height: 129px;
  border-radius: 50%;
  object-fit: cover;
}

.details {}

.title {
  color: #AFB4CB;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 2px;
}

.name {
  color: #333338;
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 24px;
}

.description {
  color: #333338;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 2px;
}

.contact {
  color: #4E6AFF;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 3px;
}

.company {
  min-width: 295px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 25px;
  border-left: 1px solid #DFDFEB;
  padding-left: 24px;
}

.companyLogo {
  width: 87px;
  height: 87px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #DFDFEB;
}

.companyInfo {}

.companyName {
  color: #333338;
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 7px;
}

.companySlogan {
  color: #333338;
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
}
