.wrapper {
    display: flex;
    flex-direction: row;

    text-align: left;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    margin-left: 10px;
    min-width: 0;
}

.title {
    margin-bottom: 3px;

    /* Blue */
    color: #4E6AFF;
    font-size: 14px;
    line-height: 16px;
}

.address {
    margin-bottom: 3px;

    font-size: 12px;
    line-height: 14px;

    /* Hard black */
    color: #000000;
}

.wrapperCottage {
  font-family: Inter;
  min-width: 0;
  max-width: 100%;
}

.wrapperCottage .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;

  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 0;
}

.wrapperCottage .address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;

  color: var(--text-color);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 0;
}

.wrapperCottage .refreshButton {
  background: transparent;
  color: var(--plan-toggle-count-color);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  padding: 0;
}




