.rootTabRoot {
  padding: 0;
  margin: 20px;
  border-bottom: 1px solid #dfdfeb;

  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media (max-width: 1024px) {
  .rootTabRoot {
    order: -2;
    margin-bottom: 0;
    border-bottom: none;
  }
}

.rootTabRoot::-webkit-scrollbar {
  display: none;
}

.rootTabItems {
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
  width: 100%;
  white-space: nowrap;
  position: relative;
  padding: 0;
}

.rootTabItem {
  display: inline-block;

  border: 0;
  background: transparent;
  margin: 0 20px 0 0;

  cursor: pointer;
  outline: none;

  padding: 0;
  font-size: 18px;
  line-height: 16px;

  font-weight: bold;
}

.rootTabItemActive {
  border-bottom: 3px solid #4E6AFF;
  padding-bottom: 5px;
  color: #404042;
}
