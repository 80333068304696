.table {
  position: relative;
  width: calc(100% - 24px);
  text-align: left;
  margin: 0 12px;
  border-spacing: 0;
}

@media (min-width: 1280px) {
  .table {
    width: calc(100% - 20px - 60px);
    margin-left: 20px;
    margin-right: 60px;
  }
}
