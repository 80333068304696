.complexCardWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-blue-50);
  padding: 12px;
}

@media (min-width: 1024px) {
  .complexCardWrapper {
    padding: 0;
    background-color: var(--color-white);
  }
}

.complexCardRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 1024px) {
  .complexCardRight {
    width: 40%;
  }
}
