.cookieBanner {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 16;
  padding: 16px 24px;
  background-color: #ffffff;
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 768px) {
    //display: none;
    padding: 16px;
  }

  &__Inner {
    width: 100%;
    max-width: 950px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-end;
      gap: 4px;
    }
  }

  &__toogle {
    display: inline-flex;
    align-items: center;
    gap: 2px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    color: var(--text-color);
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }

    &Header {
      display: flex;
      align-items: center;
    }

    p {
      span {
        text-decoration: underline;

        &.link {
          text-decoration: none;
        }
      }
    }
  }

  &__arrowDown {
    transform: rotate(0);
    transition: transform .3s ease;
  }

  &__arrowUp {
    transform: rotate(180deg);
    transition: transform .3s ease;
  }

  button {
    color: #ffffff;
    text-align: center;
    padding: 10px 12px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
    border-radius: 8px;
    background: #4E6AFF;
    border: none;
    cursor: pointer;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: .9;
    }
  }
}

.link {
  color: #4E6AFF;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  cursor: pointer;
  margin-left: 8px;
}
