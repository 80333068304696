.btn {
  padding: 5px 10px;
  margin: 0;
  border: 0;
  background: 0;
  cursor: pointer;
}

.img {
  width: 15px;
}