.imageWrapper {
  position: relative;
  border-radius: 12px;
}

.images {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 210px;
  min-height: 223px;
  border-radius: 12px;
}

@media (max-width: 540px) {
  .images {
    width: 100%;
    height: auto;
  }
}

.mainComplexInfo {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: auto;
}

.mainComplexInfo div {
  max-width: 249px;
}

.mainComplexInfoBlock {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.mainComplexInfoImg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 8px;
  margin-right: 8px;

}

.mainComplexInfoImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.iconFavorites {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 8px;
  right: 8px;

  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.mainComplexInfoOffers {
  position: absolute;
  top: 8px;
  right: 8px;
}

.initial path:first-of-type {
  fill: none;
}

.initial path:last-of-type {
  stroke: #ffffff;
}

.red path {
  fill: #dd6465;
  stroke: #dd6465;
}

/*.infoBlock {*/
/*  height: 60px;*/
/*  padding: 0 5px;*/
/*  display: grid;*/
/*  align-items: center;*/
/*  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));*/
/*  grid-gap: 20px;*/
/*}*/

.infoBlock {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  grid-gap: 20px;
  padding: 8px 8px 12px;
}

.emptyInfoBlock {
  height: 60px;
  padding: 0 5px;
  display: grid;
}

.informationOverlay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  padding: 8px;

  box-shadow: 0 -100px 100px 0 rgba(0, 0, 0, 0.55) inset;
  border-radius: 12px;
}

.builder {
  color: #FFF;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
}

.name {
  color: #FFF;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.address {
  font-size: 13px;
  color: white;
}

.builder,
.name,
.address {
  display: inline-block;
  width: 100%;
  max-width: 249px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.apartmentData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.apartmentDataType {
  color: var(--text-color);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  opacity: 0.35;
  margin-bottom: 2px;
}

.priceWrapper {
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  text-overflow: ellipsis;
  overflow: hidden;
}

.priceWrapper span {
  color: var(--text-color);
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
  opacity: 1;
}

span.apartmentDataPrice {
  color: var(--text-color);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  margin: 0 2px;
}

.tagList {
  display: inline;
}

.noFlats {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;

  text-transform: uppercase;

  color: #404042;

  margin: auto;
}
