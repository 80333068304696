.flatInfoWrapper {
  display: grid;
  grid-template-columns: max-content 1fr;
  margin-bottom: 16px;
}

@media (max-width: 600px) {
  .flatInfoWrapper {
    display: grid;
    grid-template-columns: auto;
    margin-bottom: 16px;
  }
}

.flatInfo {
  margin-left: 40px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1024px) {
  .flatInfo {
    max-width: 400px;
  }
}


@media (max-width: 600px) {
  .flatInfo {
    max-width: unset;
    margin-left: 0;
  }
}

.flatName {
  font-weight: bold;
  font-size: 32px;
  line-height: 47px;

  color: #000000;

  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .flatName {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }
}

.apartmentComplexText {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;

  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .apartmentComplexText {
    display: none;
  }
}

.apartmentComplexLink {
  text-decoration: none;
  font-weight: bold;
  color: #4E6AFF;
}

.complexAddressWrapper {
  display: flex;
}

.complexAddress {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #000000;

  margin-right: 20px;

  max-width: 310px;
}

.watchOnMapButton {
  max-width: 159px;
  margin-left: auto;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .watchOnMapButton {
    display: none;
  }
}

.badgeWrapper {
  display: flex;
  align-items: baseline;

  margin-bottom: 12px;
}

.offerDate {
  margin-left: auto;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  color: #B9B9B9;
}

@media (max-width: 767px) {
  .offerDate {
    display: none;
  }
}

.offerDateMobile {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  color: #B9B9B9;

  margin-top: 4px;
}

@media (min-width: 768px) {
  .offerDateMobile {
    display: none;
  }
}


.apartmentPreviewWrapper {
  width: 253px;
  max-height: 253px;
}

@media (max-width: 600px) {
  .apartmentPreviewWrapper {
    margin-bottom: 8px;
  }
}

.apartmentPreview {
  display: block;
  width: 253px;
  max-height: 253px;
  object-fit: contain;
}