.layoutPageHeaderDesktopWrapper {
    display: none;
}

.layoutPageHeaderDesktopWrapperVillage {
  display: block;
}

@media (min-width: 1200px) {
    .layoutPageHeaderDesktopWrapper {
        display: block;
    }
}

.layoutPageHeaderDesktopHeader {
    position: relative;
    z-index: var(--z-index-4);
    width: 100%;
    /*height: var(--layout-header-height);*/
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--background-color);
    border-bottom: 1px solid #F3F3F5;
}

@media (max-width: 768px) {
  .layoutPageHeaderDesktopHeader {
    box-shadow: none;
    flex-direction: column-reverse;
    height: auto;
    align-items: flex-start;
    padding: 10px 16px;
    border-bottom: 1px solid var(--border-filter-bottom);
  }
}


.layoutPageHeaderDesktopLeftContent {
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: 100%;
}


.layoutPageHeaderDesktopRightContent {
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {
  .layoutPageHeaderDesktopRightContent {
    width: 100%;
    margin-bottom: 14px;
  }
}