.wrapper {
    min-width: 50px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.wrapper > * {
    margin-right: 5px;
    flex-grow: 1;
}
.wrapper > *:last-child {
    margin-right: 0;
}