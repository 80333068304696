

.button {
  transition: all 200ms;
}

.buttonLink {
  font-weight: bold;
  color: #4E6AFF !important;
  padding: 0;
}

.buttonLink:hover {
  text-decoration: underline;
}

.button.animate {
  background-color: #20CB6A;
  color: #FFFFFF;
  animation: scale 1s cubic-bezier(1, -0.5, 0, 1.5) forwards;
}
.buttonLink.animate {
  background-color: transparent;
  color: #20CB6A !important;
}
