.wrapper {
  background-color: var(--color-blue-50);
  border-radius: var(--border-radius-size-m);

  padding: var(--indent-20);
  max-width: 330px;
}

.date {
  font-size: 12px;
  line-height: 14px;
  color: #404042;
}

.companyWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.companyLogo {
  margin-right: var(--indent-10);
}


.buttonGroup {
  display: grid;
  gap: var(--indent-10);
  grid-template-columns: 1fr 1fr;
}
