.wrapper {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    min-width: 120px;
    height: 24px;

    background: #F1F6FC;
    border-radius: 4px;
}


.button {
    background-color:transparent;
    padding: 0;
    height: 24px;
    width: 24px;
    border:none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button:disabled {
    opacity: 0.3;
    cursor: default;
}

.buttonIcon {
    display: block;
    width: 16px;
}


.counter {
    margin: 0 4px;
}
