.card {
  width: 100%;  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.card:visited  {
  color: black;
}
.card:link {
  color: black;
}