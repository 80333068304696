.wrapper {
  margin-top: 36px;
}

@media (min-width: 1024px) {
  .wrapper {
    margin-top: 40px;
  }
}

.title {
  font-weight: bold;
  font-size: 32px;
  line-height: 47px;

  color: #000000;
}

@media (max-width: 1024px) {
  .title {
    font-size: 24px;
    line-height: 35px;
  }
}

.flatsList {
  padding: 0;
  margin: 16px 0 0 !important;
  list-style: none;

  display: flex;
  flex-direction: column;
  max-height: 290px;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .flatsList {
    max-height: unset;
  }
}

.listItem {
  border-bottom: 1px solid #DFDFEB;
  max-width: 378px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemTitle {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #404042;

  margin-top: 12px;
  margin-bottom: 8px;
}

.value {
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;

  color: #000000;
}

.secondColumn {
  margin-left: 60px;
}

@media (max-width: 767px) {
  .secondColumn {
    margin-left: 0;
  }
}
