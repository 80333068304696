.wrapper {
    display: flex;
    flex-direction: row;

    text-align: left;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    margin-left: 10px;
}

.logo {
    border-radius: 8px;
}
.title {
    margin-bottom: 3px;
}

.address {
    margin-bottom: 3px;
}
