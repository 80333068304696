.wrapper {
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 8px 12px;
  height: 100%;
  cursor: pointer;
  color: #404042;
  font-weight: 700;
}

.wrapper:hover {
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);
  outline: 1px solid #4E6AFF;
  transition: all 0.1s ease;
}

/* STATUSES */

.active > * {
  color: #fff;
}

.active.wrapper {
  background-color: #4e6aff;
  background-image: none !important;
  opacity: 1 !important;
}

.active.wrapper:hover {
  box-shadow: 0 2px 7px 0 rgba(78, 106, 255, 0.40);
}

.sold {
  opacity: 0.35;
  color: var(--text-color)!important;
}

.sold span {
  color: var(--text-color)!important;
}

.notPublished {
  opacity: 0.35;
  cursor: default;
  color: var(--text-color)!important;
}

.notPublished span {
  color: var(--text-color)!important;
}

.notPublished:hover {
  box-shadow: none;
  outline: 0 solid white;
}

/*.booked {*/
/*  background-image: linear-gradient(*/
/*    45deg,*/
/*    #f2f6fc 27.78%,*/
/*    #e6ebf1 27.78%,*/
/*    #e6ebf1 50%,*/
/*    #f2f6fc 50%,*/
/*    #f2f6fc 77.78%,*/
/*    #e6ebf1 77.78%,*/
/*    #e6ebf1 100%*/
/*  );*/
/*  background-size: 25.46px 25.46px;*/
/*}*/

.booked {
  background-image: linear-gradient(
    45deg,
    #f7f7f7 27.78%,
    #e3e3e3 27.78%,
    #e3e3e3 50%,
    #f7f7f7 50%,
    #f7f7f7 77.78%,
    #e3e3e3 77.78%,
    #e3e3e3 100%
  );
  background-size: 25.46px 25.46px;
  opacity: 0.35;
}

.booked span {
  color: var(--text-color);
}

.active.booked span {
  color: #FFFFFF;
}

