/*
    indent_padding + _{position} + _{size}
*/
.indent_padding_top_2 {
    padding-top: var(--indent-2);
}
.indent_padding_right_2 {
    padding-right: var(--indent-2);
}
.indent_padding_bottom_2 {
    padding-bottom: var(--indent-2);
}
.indent_padding_left_2 {
    padding-left: var(--indent-2);
}

.indent_padding_top_5 {
    padding-top: var(--indent-5);
}
.indent_padding_right_5 {
    padding-right: var(--indent-5);
}
.indent_padding_bottom_5 {
    padding-bottom: var(--indent-5);
}
.indent_padding_left_5 {
    padding-left: var(--indent-5);
}

.indent_padding_top_10 {
    padding-top: var(--indent-10);
}
.indent_padding_right_10 {
    padding-right: var(--indent-10);
}
.indent_padding_bottom_10 {
    padding-bottom: var(--indent-10);
}
.indent_padding_left_10 {
    padding-left: var(--indent-10);
}

.indent_padding_top_15 {
    padding-top: var(--indent-15);
}
.indent_padding_right_15 {
    padding-right: var(--indent-15);
}
.indent_padding_bottom_15 {
    padding-bottom: var(--indent-15);
}
.indent_padding_left_15 {
    padding-left: var(--indent-15);
}


.indent_padding_top_20 {
    padding-top: var(--indent-20);
}
.indent_padding_right_20 {
    padding-right: var(--indent-20);
}
.indent_padding_bottom_20 {
    padding-bottom: var(--indent-20);
}
.indent_padding_left_20 {
    padding-left: var(--indent-20);
}

.indent_padding_top_25 {
    padding-top: var(--indent-25);
}
.indent_padding_right_25 {
    padding-right: var(--indent-25);
}
.indent_padding_bottom_25 {
    padding-bottom: var(--indent-25);
}
.indent_padding_left_25 {
    padding-left: var(--indent-25);
}

.indent_padding_top_30 {
    padding-top: var(--indent-30);
}
.indent_padding_right_30 {
    padding-right: var(--indent-30);
}
.indent_padding_bottom_30 {
    padding-bottom: var(--indent-30);
}
.indent_padding_left_30 {
    padding-left: var(--indent-30);
}

.indent_padding_top_35 {
    padding-top: var(--indent-35);
}
.indent_padding_right_35 {
    padding-right: var(--indent-35);
}
.indent_padding_bottom_35 {
    padding-bottom: var(--indent-35);
}
.indent_padding_left_35 {
    padding-left: var(--indent-35);
}

.indent_padding_top_40 {
    padding-top: var(--indent-40);
}
.indent_padding_right_40 {
    padding-right: var(--indent-40);
}
.indent_padding_bottom_40 {
    padding-bottom: var(--indent-40);
}
.indent_padding_left_40 {
    padding-left: var(--indent-40);
}




/*
    indent_margin + _{position} + _{size}
*/
.indent_margin_top_2 {
    margin-top: var(--indent-2);
}
.indent_margin_right_2 {
    margin-right: var(--indent-2);
}
.indent_margin_bottom_2 {
    margin-bottom: var(--indent-2);
}
.indent_margin_left_2 {
    margin-left: var(--indent-2);
}

.indent_margin_top_5 {
    margin-top: var(--indent-5);
}
.indent_margin_right_5 {
    margin-right: var(--indent-5);
}
.indent_margin_bottom_5 {
    margin-bottom: var(--indent-5);
}
.indent_margin_left_5 {
    margin-left: var(--indent-5);
}

.indent_margin_top_10 {
    margin-top: var(--indent-10);
}
.indent_margin_right_10 {
    margin-right: var(--indent-10);
}
.indent_margin_bottom_10 {
    margin-bottom: var(--indent-10);
}
.indent_margin_left_10 {
    margin-left: var(--indent-10);
}

.indent_margin_top_15 {
    margin-top: var(--indent-15);
}
.indent_margin_right_15 {
    margin-right: var(--indent-15);
}
.indent_margin_bottom_15 {
    margin-bottom: var(--indent-15);
}
.indent_margin_left_15 {
    margin-left: var(--indent-15);
}


.indent_margin_top_20 {
    margin-top: var(--indent-20);
}
.indent_margin_right_20 {
    margin-right: var(--indent-20);
}
.indent_margin_bottom_20 {
    margin-bottom: var(--indent-20);
}
.indent_margin_left_20 {
    margin-left: var(--indent-20);
}

.indent_margin_top_25 {
    margin-top: var(--indent-25);
}
.indent_margin_right_25 {
    margin-right: var(--indent-25);
}
.indent_margin_bottom_25 {
    margin-bottom: var(--indent-25);
}
.indent_margin_left_25 {
    margin-left: var(--indent-25);
}

.indent_margin_top_30 {
    margin-top: var(--indent-30);
}
.indent_margin_right_30 {
    margin-right: var(--indent-30);
}
.indent_margin_bottom_30 {
    margin-bottom: var(--indent-30);
}
.indent_margin_left_30 {
    margin-left: var(--indent-30);
}

.indent_margin_top_35 {
    margin-top: var(--indent-35);
}
.indent_margin_right_35 {
    margin-right: var(--indent-35);
}
.indent_margin_bottom_35 {
    margin-bottom: var(--indent-35);
}
.indent_margin_left_35 {
    margin-left: var(--indent-35);
}

.indent_margin_top_40 {
    margin-top: var(--indent-40);
}
.indent_margin_right_40 {
    margin-right: var(--indent-40);
}
.indent_margin_bottom_40 {
    margin-bottom: var(--indent-40);
}
.indent_margin_left_40 {
    margin-left: var(--indent-40);
}
