.overlay {
  position: fixed;
  inset: 0;
  z-index: 3;
  pointer-events: auto; /* если backdrop=true */
  display: flex;
  flex-direction: column;
}

.overlayNoBackdrop {
  pointer-events: none; /* пропускаем клики (если withBackdrop=false) */
}

.backdrop {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
}

/* Обёртка шторки */
.sheet {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  /* Можно чуть уменьшить, чтобы не вылезала за край */
  height: calc(var(--vh-100) - 10px);
  background: var(--bottom-sheet-background);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);
  z-index: 4;
  will-change: transform;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
}

/* Верхняя "полоса" (ручка),
   здесь drag: pointerDown/Move/Up
*/
.dragHandle {
  height: 20px; /* увеличьте при желании */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;

  /* Чтоб жесты не передавались скроллу */
  touch-action: none;
}

/* Кнопка, на которой рисуем "полоску" */
.showFullInfoButton {
  height: 24px;
  width: 100%;
  background: transparent;
  border: none;
  position: relative;
}

.showFullInfoButton:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 48px;
  height: 4px;
  border-radius: 2px;
  background-color: #dfdfeb;
}

/* Контейнер для скролла контента */
.contentScrollbar {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

