.wrapper {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
}

.input[type=checkbox], .input[type=radio]{
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
}

.label {
    cursor: pointer;
    text-indent: -9999px;
    width: 35px;
    height: 20px;
    background-color: #DFDFEB;
    display: block;
    border-radius: 100px;
    position: relative;
}

.label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 90px;
    transition: 0.3s;
}
.input:disabled + .label {
    background-color: rgba(223, 223, 235, 0.3);
}

.input:checked + .label {
    background-color: #4E6AFF;
}
.input:checked:disabled + .label {
    background-color: rgba(78, 106, 255, 0.3);;
}

.input:checked + .label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.label:active:after {
    width: 31px;
}
