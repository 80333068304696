.feedListWrapper {

}

.feedListWrapper > * {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #DFDFEB;
}

.feedListWrapper > *:last-child {
  border-bottom: none;
}

@media (min-width: 1024px) {
  .feedListWrapper > * {
    padding: 0;
    margin-bottom: 20px;
    border-bottom: none;
  }
}