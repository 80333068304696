
.border_base {
    border: var(--border-base);
}
.border_position_top {
    border-top: var(--border-base);
}
.border_position_right {
    border-right: var(--border-base);
}
.border_position_bottom {
    border-bottom: var(--border-base);
}
.border_position_left {
    border-left: var(--border-base);
}

.border_size_xs {
    border-width: var(--border-size-1);
}
.border_size_m {
    border-width: var(--border-size-2);
}
.border_size_l {
    border-width: var(--border-size-3);
}



.border_radius_xs {
    border-radius: var(--border-radius-size-xs);
}

/* border_radius + _{position} + _{size} */
.border_radius_top_left_xs {
    border-top-left-radius: var(--border-radius-size-xs);
}
.border_radius_top_right_xs {
    border-top-right-radius: var(--border-radius-size-xs);
}
.border_radius_bottom_left_xs {
    border-bottom-left-radius: var(--border-radius-size-xs);
}
.border_radius_bottom_right_xs {
    border-bottom-right-radius: var(--border-radius-size-xs);
}


.border_radius_m {
    border-radius: var(--border-radius-size-m);
}

.border_radius_top_left_m {
    border-top-left-radius: var(--border-radius-size-m);
}
.border_radius_top_right_m {
    border-top-right-radius: var(--border-radius-size-m);
}
.border_radius_bottom_left_m {
    border-bottom-left-radius: var(--border-radius-size-m);
}
.border_radius_bottom_right_m {
    border-bottom-right-radius: var(--border-radius-size-m);
}

.border_radius_l {
    border-radius: var(--border-radius-size-l);
}

.border_radius_top_left_l {
    border-top-left-radius: var(--border-radius-size-l);
}
.border_radius_top_right_l {
    border-top-right-radius: var(--border-radius-size-l);
}
.border_radius_bottom_left_l {
    border-bottom-left-radius: var(--border-radius-size-l);
}
.border_radius_bottom_right_l {
    border-bottom-right-radius: var(--border-radius-size-l);
}





/* для элементов с "position: sticky" */
.border_shadow_position_bottom {
    box-shadow: 0 1px 0 #dfdfeb;
}
.border_shadow_position_right {
    box-shadow: 1px 0 0 0 #dfdfeb;
}
.border_shadow_position_left {
    box-shadow: -1px 0 0 0 #dfdfeb;
}
.border_shadow_position_top {
    box-shadow: 0 -1px 0 0 #dfdfeb;
}
