.contactCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background: var(--stepper-background);
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .logoWrapper {
    display: flex;
    align-items: center;
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    background: #ffffff;
    border-radius: 8px;
  }

  .logo {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid #DFDFEB;
    object-fit: contain;
  }

  .companyInfo {

    .companyName {
      color: var(--text-color);
      font-family: Inter;
      font-size: 18px;
      font-weight: 700;
      line-height: 140%;
      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }

    .companyType {
      color: var(--text-color);
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }

  .companyInfoBlock {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
    @media screen and (max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  .salesDepartment {
    width: 100%;
    margin-bottom: 13px;

    .departmentTitle {
      color: var(--text-color);
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 4px;
    }

    .contacts {
      display: flex;
      align-items: center;

      &Block {
        display: flex;
        gap: 8px;
      }

      .contactAvatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 4px;
        object-fit: cover;
      }

      .contactAvatarTwo {
        margin-left: -12px;
        border: 1px solid var(--border-filter-bottom);
      }

      .moreContacts {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: var(--background-color);
        color: var(--text-color);
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        margin-left: -12px;
      }
    }

    .status {
      display: flex;
      align-items: center;
      gap: 6px;

      span {
        color: var(--plan-toggle-count-color);
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }

      &BlockTop {
        display: flex;
        align-items: center;

        span {
          overflow: hidden;
          color: var(--text-color);
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          line-height: 140%;
          @media screen and (max-width: 768px) {
            font-size: 12px;
          }
        }
      }

      .onlineIndicator {
        width: 8px;
        height: 8px;
        background-color: #20CB6A;
        border-radius: 50%;
      }
    }
  }

  .button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #E9EBF3;
    background: #4E6AFF;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    transition: opacity 0.3s;
    line-height: 140%;

    &:hover {
      opacity: 0.8;
    }
  }
}

