.zoom {
  position: absolute;
  top: calc(50% - 40px);
  left: var(--indent-20);
  height: 81px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: var(--z-index-1000);

  background: var(--zoom-background);
  border-radius: 8px;
  /*padding: 6px 0;*/
  box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 769px) {
  .zoom {
    left: var(--indent-10);
    /*top: auto;*/
    /*bottom: 80px;*/
    top: 50%;
    transform: translate(0,-100%);
  }
}

.zoom button {
  border-radius: 50%; /* Делаем кнопки круглыми */
  border: none; /* Убираем рамку */
  background: transparent; /* Делаем фон прозрачным */
  padding: 12px;
}

.divider {
  width: 50%;
  height: 1px;
  min-height: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin: auto;
}

