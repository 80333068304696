
.contactViewLink {
  display: block;
  text-decoration: none;
  color: var(--color-gray-900);

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;

}
@media screen and (min-width: 1024px) {
  .contactViewLink {
    font-size: 16px;
    line-height: 22px;
  }
}