.addToOffersListWrapper {
  padding: 0 12px 60px 24px;
  overflow: auto;
}

@media screen and (max-width: 1024px) {
  .addToOffersListWrapper {
    padding: 0 0 20px 0;
    overflow: scroll;
    max-height: calc(100% - 135px);
  }
}

@media screen and (max-width: 768px) {
  .addToOffersListWrapper {
    padding: 0 20px;
  }
}

@media (min-width: 768px) {
  .addToOffersListWrapper {
    padding: 0;
    padding-bottom: 60px;
    height: calc(100% - 136px);
  }
}

.addToOffersListWrapper > * {
  margin-bottom: 20px;
}
