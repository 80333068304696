

.closeButton {
    position: fixed;
    top: 40px;
    right: 40px;
    width: 38px;
    height: 38px;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.closeButtonIcon {
    display: block;
    width: 38px;
    height: 38px;
}

.modalOverlay {
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-index-13);
    inset: 0;
    display: flex;
    width: 100vw;
    height: calc(var(--vh) * 100);
    height: -webkit-fill-available;
    min-height: calc(var(--vh) * 100);
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    padding-top: var(--indent-40);
    padding-bottom: var(--indent-40);
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.modalContent {
    background-color: var(--background-color);
    position: relative;
    border-radius: 24px;
}


.modalOverlay.sizeXL {
    padding: 0 106px 0;
    justify-content: flex-start;
}

.sizeSM .modalContent {
    width: 100%;
    max-width: 448px;
}
.sizeMD .modalContent {
    width: 100%;
    max-width: 713px;
}
.sizeLG .modalContent {
    width: 100%;
    max-width: 930px;
}
.sizeXL .modalContent {
    width: 100%;
    max-width: 1228px;
}


.center {
    justify-content: center;
}