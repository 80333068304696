.wrapper {
    display: flex;
    flex-direction: row;
    border: var(--border-base);
    background-color: var(--color-white);
    text-decoration: none;
}

.left {
    display: flex;
    flex-direction: column;
    width: calc(100% - 142px);
    padding: 12px;
    align-items: flex-start;
}

@media(min-width: 1024px) {
    .left {
        padding: 20px;

        width: calc(100% - 200px);
    }
}

.right {
    width: 142px;
    height: 142px;
}

@media(min-width: 1024px) {
    .right {
        width: 200px;
        height: 200px;
    }
}


.imagePreview {
    display: block;
    width: 100%;
    height: 142px;
    object-fit: cover;
}

@media(min-width: 1024px) {
    .imagePreview {
        height: 200px;
    }
}


.title {
    flex-grow: 1;

    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    color: var(--color-black);

    margin-top: 8px;
    margin-bottom: 8px;

}

@media(min-width: 1024px) {
    .title {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.date {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #404042;
}