.planToggleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  background: var(--village-wrapper-background);
  position: relative;
  margin: 0 auto 16px;
  border-bottom: var(--border-size-1) solid var(--border-color);
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
    justify-content: flex-start;
    padding: 8px 16px;
  }
}

/* Десктопные табы */
.planToggle {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.planToggle div:first-child button {
  margin-left: 0;
}

.planToggle button {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  padding: 4px 0 13px;
  margin: 0 0 0 32px;
  font-size: var(--size-text-s);
  font-weight: 700;
  color: var(--text-color);
  background: transparent;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  transition: color 0.4s ease;
}

.planToggle button.active {
  color: #4e6aff;
}

.activeIndicator {
  position: absolute;
  bottom: 0;
  height: 5px;
  background: #4e6aff;
  transition: all 0.3s ease;
  border-radius: 11px 11px 0 0;
}

.objectCount {
  display: inline-block;
  font-size: var(--size-text-xs);
  color: var(--plan-toggle-count-color);
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .objectCount {
    margin-left: 0;
  }
}

/* Мобильная версия */
.mobileToggleButton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  background: transparent;
  border-radius: 8px;
  font-weight: 700;
  font-size: var(--size-text-s);
  cursor: pointer;
  position: relative;
  border: none;

  span {
    color: var(--text-color);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 140%;
  }

  span.objectCount {
    display: inline-block;
    font-size: 10px;
    color: var(--plan-toggle-count-color);
  }
}

.bottomSheetHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  h2 {
    color: var(--text-color);
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
  }
}

.closeBtn {
  background: none;
  border: none;
  cursor: pointer;
}

.mobileTogglePlans {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;
}

.bottomSheetItem {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  background: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-weight: 700;
  font-size: var(--size-text-s);
  color: var(--text-color);
  border-radius: 4px;
}

.bottomSheetItem.active {
  background: var(--toggle-active-background);
  color: #4E6AFF;
}

/* Скрываем десктопный переключатель на маленьких экранах */
@media (max-width: 768px) {
  .planToggle {
    display: none;
  }
}
