.swipeArea {
  height: 30px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.openSidebarButton {
  display: none;
}

@media (max-width: 767px) {
  .openSidebarButton {
    display: block;
    font-size: 0;

    width: 48px;
    height: 4px;

    background: #DFDFEB;
    border: 1px solid #DFDFEB;
    border-radius: 2px;

    position: absolute;
  }
}
