.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: visible;
  overflow-y: auto;
  height: calc(var(--vh-100) - 280px);
  scrollbar-width: none;
  -ms-overflow-style: none;
  @media screen and (max-width: 768px) {
    height: calc(var(--vh-100) - 156px);
  }

  &Main {
    height: calc(var(--vh-100) - 310px);
    @media screen and (max-width: 768px) {
      height: calc(var(--vh-100) - 230px);
    }
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}


.formColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px 40px;
}


@media screen and (min-width: 1024px){
  .formColumn {
    padding: 0 32px 40px;
  }
}

.floorsCheckboxes {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;

  div {
    width: auto;
  }
}

.formItem {
  display: flex;
  flex-direction: column;
  color: #404042;

  padding: 0;
  flex-basis: 50%;
  max-width: 50%;
}

.formItem + .formItem {
  margin-left: 24px;
}

.formSingleItem {
  position: relative;
}

.formSingleItem:not(:last-of-type) {
  margin-bottom: 24px;
}

.formSubColumnWrapper {
  display: flex;
}

.formSubColumnWrapper:not(:last-of-type) {
  margin-bottom: 24px;
}

.complexInputLeft {
  border-radius: 8px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.complexInputRight {
  border-radius: 8px;
}

.complexInputLeft input:disabled,
.complexInputRight input:disabled {
  background-color: #f3f3f3;
  pointer-events: none;
  cursor: not-allowed;
}

.formInputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 24px;
  gap: 4px;
  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px; 
  }
}

.formLabel {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  width: 100%;
  max-width: 100%;
  display: block;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}
