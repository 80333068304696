.buttonViewFilter {
  height: 40px;
  border-radius: 8px;
  border: 1px solid #DFDFEB;
  padding: 11px 12px;
  color: var(--text-color);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
}

.buttonViewFilterInner {
  display: flex;
  align-items: center;
  gap: 4px;
}

.buttonViewFilterCount {
  border-radius: 12px;
  background: #EE5656;
  padding: 0 6px;
  color: #FFF;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
  text-transform: uppercase;
}