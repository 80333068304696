#queuesSwiper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  overflow-x: auto;
}

#queuesSwiper .swiper-wrapper {
  margin-bottom: 10px;
}
