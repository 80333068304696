.benefitsList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 12px 6px;
}

@media (max-width: 540px) {
  .benefitsList {
    overflow-x: auto;
    overflow-y: hidden;
    grid-template-columns: repeat(2, 200px);
    grid-gap: 12px;
  }
}

.benefit {
  display: flex;
  align-items: center;
}

.benefitsWrapper {
  margin-top: var(--indent-60);
}

.icon {
  width: 32px;
  height: 32px;
  background-color: var(--color-blue-100);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--indent-5);

  border-radius: 32px;

  margin-right: 8px;
}

.disabled {
  color: #B9B9B9;
}

.title {
  display: flex;
  flex-direction: column;
}

.iconDisabled {
  background: #b9b9b93b;
  filter: grayscale(100%) opacity(0.5);
}
