.headerRightContent {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 60px;
}

.wrapper {
    height: calc(var(--vh-100) - var(--navbar-height));
    min-height: calc(100vh - var(--navbar-height));
}

@media (max-width: 1024px) {
    .wrapper {
        /* mobile viewport bug fix */
        /*min-height: -webkit-fill-available;*/
        min-height: calc(var(--vh-100) - var(--navbar-height));
    }
}
