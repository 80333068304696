.sliderWrapper {
  position: relative;
}

.swiper {
  width: 100%;
}

.swiperSlide {
  border: 1px solid var(--color-blue-200);
  padding: 16px;

  .previewInfo {
    display: flex;
    gap: 16px;
    flex-direction: column;

    .price {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--color-blue-200);
    }
  }
}

.swiperCard {
  height: 248px; 
  align-content: center;
  display: flex;
  justify-content: center;
}

.image {
  object-fit: contain;
  max-height: 100%;
  max-width: 250px,
}

.pagination {
  height: 20px;
}


