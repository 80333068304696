.wrapper {
  width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: var(--text-color);
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
}

.infoBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  &Inner {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #AFB4CB;
    font-size: 12px;
    font-weight: 600;
    line-height: 140%;
    text-transform: uppercase;
  }

  &Price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: #4E6AFF;
    font-family: Inter, serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;

    &Meter {
      color: #AFB4CB;
      font-size: 12px;
      font-weight: 400;
      line-height: 140%;
    }
  }

  &:last-child {
    align-items: flex-end;
    margin-bottom: 0;
  }
}