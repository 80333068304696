@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -135% 0;
  }
}

@keyframes wave {
  0% {
    transform: translateX(-100%);
  }

  60% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.skeletonWrapper {
  margin: 0;
  padding: 0;

  line-height: 1;

  display: flex;
}

.skeleton {
  display: inline-block;

  /** default width */
  width: 100%;
  /** default height */
  height: 16px;
  line-height: 1;

  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;

  border-radius: 4px;

  /* it's better to give margins via skeleton's className like */
  /* margin-bottom: 4px; */
}

.gradientSkeleton {
  background-image: linear-gradient(
    -90deg,
    rgba(223, 226, 228, 0.72) 0%,
    rgba(223, 226, 228, 0.6) 50%,
    rgba(223, 226, 228, 0.72) 100%
  );
  background-color: rgba(223, 226, 228, 0.72);
  background-size: 400% 400%;

  animation: gradient 3s ease-in-out infinite;
}

.pulseSkeleton {
  background-color: #0000001c;
  background-image: none;

  animation: pulse 1.5s ease-in-out 0.2s infinite;
}

.waveSkeleton {
  overflow: hidden;
  position: relative;

  background-color: rgba(0, 0, 0, 0.11);
  background-image: none;
}

.waveSkeleton::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  position: absolute;

  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.04),
    transparent
  );

  animation: wave 1.6s linear 0.5s infinite;
}

/** styles if skeleton is Array */
.skeletonArray {
  display: flex;
  flex-direction: column;
}

.skeletonArray span {
  flex: 1 1 auto;
  height: 20px;
}

.skeletonWrapperDirection {
  flex-direction: row;
}
