.dropdownOverlay {
    width: 165px;
    min-height: 113px;
    margin-top: 5px;
    padding: 0;
    background-color: #FFFFFF;

    border: 1px solid #DFDFEB;
    box-sizing: border-box;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    overflow: hidden;
}

.dropdownItem {
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background-color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;

    color: #404042;
    padding: 10px;

    cursor: pointer;

    text-decoration: none;
}

.dropdownItem:hover {
    background-color: var(--color-blue);
    color: var(--color-white);
}

.dropdownItemAlert {
    color: var(--color-red);
}

.dropdownItemDark {
    color: var(--color-black);
}
.dropdownItemBorder {
    border-bottom: 1px solid #DFDFEB;
}
.dropdownItemWithSwitch:hover {
    background-color: #fff;
    color: var(--color-black);
}
