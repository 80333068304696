.albumsList {
  padding: 0;
  list-style: none;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .albumsList {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
}