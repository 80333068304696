.wrapper {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .wrapper {
    display: grid;
    grid-template-columns: auto 330px;

    padding-right: 60px;
    padding-left: 60px;
    padding-top: 25px;
    padding-bottom: 40px;
  }
}

.left {
  margin: 0 12px 24px;
}

@media (min-width: 1024px) {
  .left {
    margin: 0 60px 0 0;
  }
}

.right {
}

.content {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #404042;
}

@media (min-width: 1024px) {
  .content {
    padding-top: 15px;
  }
}

.content p {
  padding-bottom: 12px;
}
@media (min-width: 1024px) {
  .left {
    margin-right: 60px;
  }
}

.content ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.content li {
  position: relative;
  padding-left: 28px;
}

@media (min-width: 1024px) {
  .content li {
    padding-left: 40px;
  }
}

.content li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 12px;
  width: 20px;
  height: 2px;
  background-color: #4e6aff;
}
