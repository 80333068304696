.generalPlanTable {
  display: grid;
  padding: var(--indent-16) var(--indent-24);
  box-sizing: border-box;

  .topRow {
    position: relative;
  }

  .sortButton {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      display: block;
    }

    img {
      width: 24px;   // подберите нужный размер иконок
      height: 24px;
    }
  }
}

.tableHeader {
  display: grid;
  grid-template-columns: 1.1fr 0.7fr 0.5fr 0.5fr 0.7fr 0.7fr 1.1fr 1fr 0.9fr 1fr 1fr 0.9fr 1fr;
  align-items: center;
  column-gap: var(--indent-14);
  padding: 12px 20px 12px 22px;
  color: var(--plan-toggle-count-color);
  font-size: var(--size-text-s);
  font-weight: 500;
  line-height: 140%;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.tableHeaderRow {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}


