.apartmentAndComplexListWrapper {
  padding-top: 20px;
  display: grid;
  width: 100%;
  gap: 20px;
  grid-template-columns: 100%;
}

.apartmentInfo {
  transform: translateY(-100%) translateZ(20px);
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.apartmentListWrapper:hover .apartmentInfo {
  transform: translateY(0) translateZ(0);
  opacity: 1;
}
