.root {
    position: relative;
    cursor: pointer;
    user-select: none;

    display: flex;
    margin: 0;
    padding: 4px 0;
}

.root .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-radius: 4px;

    position: relative;

    width: 24px;
    height: 24px;
}

.root .wrapper::before,
.root .wrapper::after {
    cursor: pointer;
    content: '';
}

.root .wrapper::before {
    display: inline-block;

    flex: 1 0 auto;

    width: 24px;
    height: 24px;

    border-radius: 4px;
    border: 1px solid var(--border-icon-color);

    background-color: var(--background-input-color);
}

.root .wrapper::after {
    display: block;
    position: absolute;

    left: 50%;
    top: 50%;

    height: 11px;
    width: 12px;

    background-size: cover;

    transform: translate(50%, 50%) scale(0);
}

.root .input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
}

.root > input[type=checkbox]:hover:not([disabled]) + .wrapper::before {
    border: 1px solid var(--plan-toggle-separator-color);
}

.root > input[type=checkbox]:focus:not([disabled]) + .wrapper::before {
    /*border: 1px solid #4E6AFF;*/
    border-radius: 4px;
}

.root > input[type=checkbox]:hover:not([disabled]) + .wrapper::before {
  border: 1px solid #4E6AFF;
  border-radius: 4px;
}

.checked > input[type=checkbox]:focus:not([disabled]) + .wrapper::before {
    background-color: #4E6AFF;
    border-radius: 4px;
}

.checked > input[type=checkbox]:hover:not([disabled]) + .wrapper::before {
    background-color: #4E6AFF;
    border-radius: 4px;
}

.checked .wrapper::before {
    background-color: #4E6AFF;
    border: 1px solid var(--plan-toggle-separator-color);
    border-radius: 4px;
}

.checked .wrapper::after {
    transform: scale(1) translate(-50%, -50%);
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 11a1 1 0 01-.707-.293l-3-3a1 1 0 011.414-1.414l2.2 2.2L10.21.386a1 1 0 111.58 1.228l-7 9a1 1 0 01-.728.386H4z' fill='%23fff'/%3E%3C/svg%3E");
}

:global(.dark-theme) .checked .wrapper::after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 11a1 1 0 01-.707-.293l-3-3a1 1 0 011.414-1.414l2.2 2.2L10.21.386a1 1 0 111.58 1.228l-7 9a1 1 0 01-.728.386H4z' fill='black'/%3E%3C/svg%3E");
}

.checked .disabled > .wrapper::before {
    background-color: grey;
    border: 1px solid grey;

    cursor: not-allowed;
}

.labelText {
    width: 100%;
    color: var(--text-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    margin-left: 12px;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
}


.disabled {
    cursor: not-allowed;
}

.disabled > input[type=checkbox] {
    pointer-events: none;
}

.disabled .wrapper::before,
.disabled .wrapper::after {
    cursor: not-allowed;
}

.disabled  .wrapper::before {
    opacity: 0.5;
}

.disabled .checked > .wrapper::before {
    opacity: 0.5;
}
