.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  overflow: hidden;
}

.play {
  position: absolute;
  width: 128px;
  height: 128px;
  z-index: 4;
  top: 50%;
  left: 50%;
  margin: -64px 0 0 -64px;
  cursor: pointer;
}

.iframe {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.photo {
  position: relative;
  z-index: 3;
  max-height: 100%;
}

.photoHide {
  opacity: 0;
  z-index: 1;
}
