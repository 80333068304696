.residentialComplex {
  width: 100%;
  position: relative;
  page-break-inside: avoid;
  break-inside: avoid;
}

.bottomBoxShedow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 47.49%, rgba(0, 0, 0, 0.65) 100%);
  z-index: 4;
}

.imageContainer {
  width: 100%;
  height: 597px;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.infoOverlay {
  position: absolute;
  bottom: 32px;
  left: 32px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 5;
}

.name {
  color: #ffffff;
  font-size: 50px;
  font-weight: 700;
}

.developer {
  display: flex;
  align-items: center;
  gap: 24px;
}

.developerLogo {
  width: 90px;
  height: 90px;
  object-fit: contain;
  border-radius: 8px;
}

.developerDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.developerName {
  color: #ffffff;
  font-size: 36px;
  font-weight: 500;
  line-height: 140%;
}

.developerStats {
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
}
