.complexCardAddressWrapper {
  max-width: 230px;
  display: none;
}

@media (min-width: 768px) {
  .complexCardAddressWrapper {
    max-width: 230px;
    display: block;
  }
}

.complexCardAddressShort {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;

  color: #000000;
}

.complexCardAddressFull {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #404042;
}
