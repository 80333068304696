.tableRow {
  height: 76px;
  position: relative;
  display: grid;
  grid-template-columns: 1.1fr 0.7fr 0.5fr 0.5fr 0.7fr 0.7fr 1.1fr 1fr 0.9fr 1fr 1fr 0.9fr 1fr;
  align-items: center;
  column-gap: var(--indent-14);
  padding: 8px 20px 8px 22px;
  border-bottom: var(--border-size-1) solid var(--border-color);
  color: var(--text-color);
  text-align: justify;
  font-size: var(--size-text-s);
  font-weight: 500;
  line-height: 140%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &:hover {
    background-color: var(--hover-toogle-plan);
    cursor: pointer;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 8px;
    background-color: var(--available-color);
    border-radius: 16px 0 0 16px;
  }

  &.available {
    &::before {
      background-color: var(--available-color);
    }
  }

  &.reserved {
    &::before {
      background-color: var(--reserved-color);
    }
  }

  &.sold {
    &::before {
      background-color: var(--sold-color);
    }
  }

  .tableCell {
    div {
      width: 54px;
      height: 54px;
    }
    img {
      max-width: 54px;
    }

    &Img {
      width: 54px;
      height: 54px;
      object-fit: contain;
    }

    &Status {
      display: flex;
      align-items: center;
      gap: var(--indent-12);
    }

    &Price {
      color: var(--price-color);
    }
  }
}

.actionButtons {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  button {
    background: none;
    border-color: var(--border-icon-color);
  }
}

.detailsButton,
.favoriteButton {
  width: 42px;
  background: none;
  cursor: pointer;
  border-radius: var(--border-radius-size-m);
  border: var(--border-size-1) solid var(--border-color);
  padding: 10px 12px;

  img {
    width: 16px;
  }
}

.tableCell .tableCellPlan {
  width: 54px;
  height: 54px;
  object-fit: contain;
  margin-bottom: 0;

  img:first-child {
    width: 54px;
    height: 54px;
    object-fit: contain;
  }
}

.alertWrapper {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  max-width: 300px;
}

/* --- Мобильная карточка --- */
.mobileRow {
  padding: 0 16px;
  cursor: pointer;

  &::before {
    content: none;
  }
}

.selected {
  background: #4E6AFF;
}

@media screen and (min-width: 1024px) {
  .selected {
    background: #4E6AFF;
    color: #ffffff;
    border-radius: 12px 0 0 12px;
    .tableCellPrice {
      color: #ffffff;
    }
    &:hover {
      background: #4E6AFF;
      color: #ffffff;
    }
  }
}

.mobileRowInner {
  width: 100%;
  padding: 12px 0 12px 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 12px;
  border-bottom: 1px solid var(--border-color);
}

/* Полоса слева */
.mobileLeftBar {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 8px;
  background-color: var(--available-color);
  border-radius: 16px 0 0 16px;
}

/* Применяем цвет фона в зависимости от статуса */
.available .mobileLeftBar {
  background-color: var(--available-color);
}
.reserved .mobileLeftBar {
  background-color: var(--reserved-color);
}
.sold .mobileLeftBar {
  background-color: var(--sold-color);
}

.mobileContent {
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
}

.mobileHeader {
  display: flex;
  align-items: center;
  gap: 12px;

  .mobileNumber {
    color: var(--text-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
}

.mobileFinishing {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.mobileFinishingFloors {
  border-radius: 4px;
  background: var(--floors-number-background);
  padding: 0 6px;
}

.selected {
  .mobileNumber, .mobileFinishing {
    color: #ffffff;
  }
}

.selected .mobileFinishingFloors {
  color: var(--text-color);
}

.mobileBedrooms {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.selected .mobileBedrooms {
  color: #ffffff;
}

.mobileMaterial {
  color: #c2c2c3;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.selected .mobileMaterial {
  color: #94A6FF;
}

.mobilePrices {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  min-width: 120px;
}

.mobileType {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
  opacity: .3;
}

.mobilePriceM2 {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  opacity: .3;
}

.mobileFullPrice {
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
  color: #4E6AFF;
}

.selected {
  .mobileType, .mobilePriceM2 {
    color: #94A6FF;
  }

  .mobileFullPrice {
    color: #ffffff;
  }
}

.mobileActionButtons {
  display: flex;
  gap: 8px;

  button {
    background: none;
  }
}

.mobileNothing {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}


