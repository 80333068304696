.mapWrapper {
    margin-top: 50px;
    height: calc(var(--vh-100) - 50px);
}

@media screen and (max-width: 768px) {
  .mapWrapper {
    margin-top: 0;
  }
}
