.formWrapper {
  padding: 24px;

  height: 100%;
}

@media (min-width: 1024px) {
  .formWrapper {
    padding: 60px;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  height: calc(var(--vh) * 100 - var(--navbar-height));
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1024px) {
  .wrapper {
    display: flex;
    height: 100%;
    width: auto;
  }
}

.alertWrapper {
  position: absolute;
  bottom: 0;

  width: 100%;

}


