.drawerOverlay {
  width: 100vw;
  height: var(--vh-100);
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: var(--z-index-13);
  display: flex;
}

.drawerOverlayRight {
  justify-content: flex-end;
}

.drawerOverlayLeft {
    justify-content: flex-start;
}

.drawerContent {
  position: relative;
  max-width: 400px;
  width: 100%;
  height: calc(var(--vh) * 100);
  background-color: #fff;
}
