.pinIcon {
  width: 15px;
  height: 15px;
  background: #4E6AFF;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  z-index: var(--z-index-4);
}

.pinWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
}

.pinDetailsText {
  padding: 10px;
  padding-right: 35px;
  font-family: 'Circe', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.title {
  color: var(--text-color);
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
}

.builder {
  color: var(--text-color);
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
}

.pinDetails {
  /*transform: translateX(-38%);*/
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  z-index: var(--z-index-6);
  background: rgba(255, 255, 255, 0.70);
  border-radius: 12px;
  padding: 4px;
  opacity: 1;
}


.pinDetailsImg img {
  width: 97px;
  border-radius: 12px;
}

.pinDetails::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -25px;
  width: 20px;
  height: 35px;

  left: calc(50% - 10px);
  right: calc(50% - 10px);
}

.pin {
  cursor: pointer;
}

