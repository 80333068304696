@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap');

.wrapper {
  width: 100%;
  max-width: 1060px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
  -webkit-print-color-adjust: exact;
  page-break-inside: avoid;
  break-inside: avoid;
  //height: 100vh;
  //overflow: scroll;
}

@page {
  /*size: A4 portrait; !* Позволяем странице автоматически подстраивать размер *!*/
  margin: 0;
  -webkit-print-color-adjust: exact;
  page-break-inside: avoid;
  break-inside: avoid;
}
