.realtorLogoWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.realtorLogo {
  margin-right: 10px;
}

.realtorLogoTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;

  color: #000000;
}

.realtorLogoSubTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: #404042;
}
