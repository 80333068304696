.wrapper {
  position: relative;
  padding: 24px 12px;
}

@media (min-width: 1024px) {
  .wrapper {
    padding: 60px 60px 20px 60px;
  }
}


.buttonBack {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  border: none;
  background-color: transparent;
  display: none;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .buttonBack {
    display: block;
  }
}