.apartmentInfoTooltipWrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
}

.apartmentInfoTooltipLeft {
  display: flex;
  align-items: center;
  width: 143px;
  height: 143px;
  background: #ffffff;
}

.apartmentInfoTooltipPlan {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.apartmentInfoTooltipBottom {
  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%);
}