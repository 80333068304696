
.sizeXXS {
    font-size: var(--size-text-xxs);
    line-height: var(--size-text-xs);
}
.sizeXS {
    font-size: var(--size-text-xs);
    line-height: var(--size-text-s);
}
.sizeS{
    font-size: var(--size-text-s);
    line-height: var(--size-text-m);
}
.sizeM {
    font-size: var(--size-text-m);
    line-height: var(--size-text-l);
}
.sizeL {
    font-size: var(--size-text-l);
    line-height: var(--size-text-xl);
}
.sizeXL {
    font-size: var(--size-text-xl);
    line-height: var(--size-text-2xl);
}
.size2XL {
    font-size: var(--size-text-2xl);
    line-height: var(--size-text-3xl);
}
.size3XL {
    font-size: var(--size-text-3xl);
    line-height: var(--size-text-3xl);
}

/**
@desc возможность переопределить интерлиньяж
*/
.lineHeightXS {
    line-height: var(--size-text-xs);
}
.lineHeightS{
    line-height: var(--size-text-s);
}
.lineHeightM {
    line-height: var(--size-text-m);
}
.lineHeightL {
    line-height: var(--size-text-l);
}
.lineHeightXL {
    line-height: var(--size-text-xl);
}
.lineHeight2XL {
    line-height: var(--size-text-2xl);
}
.lineHeight3XL {
    line-height: var(--size-text-3xl);
}
.lineHeight4XL {
    line-height: var(--size-text-4xl);
}



.weightbold {
    font-weight: bold;
}
.weightnormal {
    font-weight: normal;
}

.weight700 {
    font-weight: 700;
}

.inline {
    display: inline;
}
.block {
    display: block;
}
.inlineBlock {
    display: inline-block;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.uppercase {
    text-transform: uppercase;
}


.whiteSpace_nowrap {
    white-space: nowrap;
}

.wordBreak_break-all {
    word-break: break-all;
}
.wordBreak_break-word {
    word-break: break-word;
}
