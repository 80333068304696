.noResultsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background: transparent;
  height: calc(100vh - 136px);
  font-family: Inter;
}

.icon {
  margin-bottom: 16px;
  @media screen and (max-width: 768px) {
    width: 40px;
    margin-bottom: 12px;
  }
}

.message {
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  color: var(--text-color);
  margin-bottom: 16px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 12px;
  }
}

.suggestion {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: #404042;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.resetLink {
  color: var(--Color-Text-blue-basic-one, #445EE7);
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: block;
    font-size: 12px;
  }

  &:hover {
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }
}
