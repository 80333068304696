

.viewSidebarBlock {
  padding-top: 20px;
  padding-bottom: 20px;
}

.viewSidebarWrapper > .viewSidebarBlock {
  border-bottom: 1px solid #dfdfeb;
}

.viewSidebarWrapper > .viewSidebarBlock:first-child {
  padding-top: 0;
}
.viewSidebarWrapper > .viewSidebarBlock:last-child {
  border-bottom: none;
}

.viewSidebarContacts {
  display: grid;
  gap: 15px;
}

@media (max-width: 767px) {
  .viewSidebarTitle {
    padding-top: 0;
  }
}

.viewSidebarFromRealtorTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  color: #404042;
}

.viewSidebarFromRealtor {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;

  color: #404042;
}
