.alertWrapper {
  z-index: var(--z-index-7);
  position: sticky;
  top: 0;
}

.alertWrapper a {
  color: var(--color-white);
  font-weight: 700;
  text-decoration: underline;
}
