.codeInput {
    display: flex !important;
    flex-direction: row;
}

/* Chrome, Safari, Edge, Opera */
.codeInput > input::-webkit-outer-spin-button,
.codeInput > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.codeInput > input[type=number] {
    -moz-appearance: textfield;
}

.codeInput > input {
    text-align: center;
    font-size: 20px;
    background-color: var(--color-white);
    border: var(--border-base);
    border-radius: var(--border-radius-size-m);

    height: 40px;
    width: 40px;
    display: flex;

    position: relative;
    margin-right: 10px;
}
.codeInput > input:last-child {
    margin-right: 0;
}

.isError > input {
    border-color: var(--color-red);
}