
.button {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;

    cursor: pointer;
}

.button:before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: var(--z-index-4);

    display: block;
}

.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: var(--z-index-7);

    display: block;
    width: 32px;
    height: 32px;
    opacity: 0;
}


.button:hover:before {
    background-color: var(--color-black);
    opacity: 0.3;
}

.button:hover .icon {
    opacity: 1;
}
