.wrapper {
    position: sticky;
    left: 0;
    z-index: var(--z-index-3);

    background-color: #F1F1F1;
    border-top: var(--border-base);
    border-right: var(--border-base);
    grid-column: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-weight: 700;
}

.title {
    color: #B9B9B9;
}

.wrapperText {
  color: #AFB4CB;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.borderTopNone {
    border-top: none;
}
