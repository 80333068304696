.modalSelectWrapper {
  background-color: var(--color-white);
  padding: 24px 12px 0;
}

@media screen and (min-width: 1024px) {
  .modalSelectWrapper {
    padding: 40px 60px;
  }
}

.title {
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;

  display: none;
  color: var(--color-black);

  margin-bottom: 8px;
}

@media screen and (min-width: 1024px) {
  .title {
    display: block;
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0 -6px !important;
}


@media screen and (min-width: 1024px) {
  .list {
      max-height: 900px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
  }
}


.list li:not(:last-of-type) {
  margin-bottom: 10px;
}

.regionBtn:not(:first-of-type) {
  margin-top: 20px;
}


.desktopButtons {
    composes: border_shadow_position_top from '../Border/styles.module.css';
    background-color: #f3f3f5;
    padding: 10px 60px;

    display: none;
    justify-content: space-between;
}

@media screen and (min-width: 1024px){
    .desktopButtons {
        display: flex;
    }
}


.mobileButtons {
    padding: 16px 12px;
    display: grid;
    gap: 8px;
}

@media screen and (min-width: 1024px){
    .mobileButtons {
        display: none;
    }
}
