/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  -webkit-print-color-adjust: exact;
  page-break-inside: avoid;
  break-inside: avoid;
}

@page {
  /*size: A4 portrait; !* Позволяем странице автоматически подстраивать размер *!*/
  margin: 0;
  page-break-inside: avoid;
  break-inside: avoid;
}

/* Убираем внутренние отступы */
/* ul[class],
ol[class] {
  padding: 0;
} */

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  min-height: calc(var(--vh) * 100);
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  line-height: 1.5 !important;
  overflow: hidden;
  max-width: 100vw;
  padding: 0 !important;
  margin: 0 !important;
  @media print {
    overflow: visible;
  }
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
  list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.mapboxgl-popup-content {
  padding: 0;
  cursor: pointer;
}

.mapboxgl-popup-close-button {
  outline: 0;
}

.leaflet-container {
  z-index: var(--z-index-3);
}

@media (max-width: 768px) {
  .tox-editor-header {
    display: none !important;
  }
}

@media (max-width: 768px) {
  jdiv {
    position: relative;
    z-index: 13!important;
  }
}


