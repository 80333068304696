.addToOffersFooterWrapper {
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto;
  padding: 10px 20px;

  background-color: var(--color-white);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}

.container {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--stepper-background);
  background: var(--checkbox-background);
}

.containerActive {
  border: 1px solid #4E6AFF;
}

.alertWrapper {
  position: fixed;
  top: 10px;
  right: 50%;
  transform: translateX(50%);
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);
  margin-top: 0;
  border-radius: 8px;
}

.alertWrapper div {
  margin-top: 0;
}
