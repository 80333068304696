.iconWrapper {
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}

.iconWithTextWrapper {
  display: inline-flex;
  text-decoration: none;
  color: var(--color-gray-900);
}

.iconWithTextWrapperReverse {
  flex-direction: row-reverse;
}

.iconWithTextWrapperReverse .iconWrapper {
  margin-left: 10px;
}

@media screen and (min-width: 1024px) {
  .iconWithTextWrapper {
    font-size: 16px;
    line-height: 22px;
  }
}
