.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 11px;

    height: 40px;
    max-height: 50px;

    /* stroke blue */
    border: 1px solid #DFDFEB;
    box-sizing: border-box;
    border-radius: 8px;

    cursor: pointer;

    font-size: 14px;
    font-weight: bold;

    margin: 0;
}

.secondary {
    background: #FFFFFF;
}

.text {
    background: transparent;
    border: 0;
}

.primary {
    background: #4E6AFF;
    color: white;
}

.dropdownOverlay {
    background: #FFFFFF;
    border-radius: 16px;
    box-sizing: border-box;
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);
    padding: 12px;
    color: var(--text-color);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

@media screen and (max-width: 768px) {
  .dropdownOverlay {
    margin: 0 16px;
  }
}

.filterButton {
    display: flex;
    align-items: center;

    background-color: #FFFFFF;
    border: none;

    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #000000;

    cursor: pointer;
}

.selectedButton {
    background: #DFDFEB;
    border-radius: 4px;

    padding: 3px 6px;
}

.largeFilterBtn {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
}

.closeButton {
    padding: 0 3px 1px 0;

    color: #404042;
    background-color: transparent;
    border: none;
}
