.photosSection {
  padding: 50px 32px;
}

.sectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #333338;
  font-size: 65px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 68px;
  border-bottom: 8px solid #333338;
  padding-bottom: 32px;

  span {
    color: #AFB4CB;
    text-align: right;
    font-size: 36px;
    font-weight: 500;
    line-height: 140%;
  }
}

.photos {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.photoRow {
  .photoContainer {
    width: 100%;
    height: 707px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.twoPhotosRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;

  .photoContainer {
    height: 707px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
