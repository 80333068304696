
.item {
    padding: 8px 10px;
    cursor: pointer;
}
.item:hover {
    background-color: #DFDFEB;
}

.itemActive {
    background-color: #4e6aff;
}

.itemActive *{
    color: #ffffff;
}


.colorAlert {
    color: var(--color-red);
}
