.header_wrapper {
  margin-bottom: var(--indent-20);
}

.address_wrapper {
  margin-bottom: var(--indent-30);
}

.description_wrapper {
  margin-bottom: var(--indent-40);
}

.actionButtons {
  grid-row: 1/2;
  margin-bottom: var(--indent-20);
}

@media (max-width: 1280px) {
  .actionButtons {
    grid-row: 2/3;
    order: 2;
    justify-content: flex-end;
    display: flex;
    align-items: baseline;
  }
}

.complexAddress {
  grid-row: 2/3;
}

@media (max-width: 1280px) {
  .complexAddress {
    grid-row: 1/2;
    margin-bottom: var(--indent-15);
  }
}

@media (max-width: 768px) {
  .complexAddress {
    grid-column: 1/3;
  }
}

.complexInfoAddress {
  display: grid;
}

@media (max-width: 540px) {
  .complexInfoAddress {
    grid-column-gap: 50px;
  }
}

