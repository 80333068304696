.tooltip {
  position: absolute;
  color: var(--text-color);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  padding: 4px 8px;
  z-index: 12;
  width: 200px;
  text-align: left;
  border-radius: var(--Rounded-4, 4px);
  background: var(--background-color);
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);

  &.top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0;
  }

  &.bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0;
  }

  &.left {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    margin-right: 0;
  }

  &.right {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: 0;
  }
}

.triangle {
  position: absolute;
  width: 0;
  height: 0;

  &.triangle-top {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid var(--background-color);
  }

  &.triangle-bottom {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 6px solid var(--background-color);
  }

  &.triangle-left {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid var(--background-color);
  }

  &.triangle-right {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 6px solid var(--background-color);
  }
}