.planSection {
  padding: 50px 32px;
}

.sectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #333338;
  font-size: 65px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 68px;
  border-bottom: 8px solid #333338;
  padding-bottom: 32px;
}

.planFloor {
  height: 500px;
}

.planFloor > div {
  height: 500px;
  box-shadow: none;
  background: #f1f1f1;
}
