.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
}

.badgeOuter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px 4px;
    align-items: center;
}
