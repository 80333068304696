.container {
    composes: container from '../AutoSuggest/styles.module.css';
    width: 100%;
    height: 100%;
    margin-top: 52px;
}

.inputWrapper {
    composes: inputWrapper from '../AutoSuggest/styles.module.css';
    padding: 14px 16px;
    background-color: #FFFFFF;
}

.inputIcon {
    composes: inputIcon from '../AutoSuggest/styles.module.css';
    left: 27px;
    width: 16px;
    height: 16px;
}

.inputCloseIcon {
  composes: inputIcon from '../AutoSuggest/styles.module.css';
  left: auto;
  right: 27px;
  width: 16px;
  height: 16px;
}

.input {
  composes: input from '../AutoSuggest/styles.module.css';
  height: 40px;
  padding: 10px 11px 10px 31px;
  border-radius: 8px;
  /*border: 2px solid #4E6AFF;*/
  background: #F8F8F8;
}

.inputFocused, .inputFocused:focus, .inputFocused:focus-visible, .input:focus, .input:focus-visible {
  outline: 2px solid #4E6AFF;
}

.input::placeholder {
  color: #AFB4CB;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.suggestionsContainer {
    position: relative;
    width: 100%;
    height: calc(100% - var(--navbar-height));
}

.suggestionsContainerOpen {
    background-color: #fff;
    padding: 16px;
}

.sectionTitle {
  color: #AFB4CB;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 4px;
}

.suggestionsList {
    composes: suggestionsList from '../AutoSuggest/styles.module.css';
    /*padding: 0 12px;*/
}
.suggestion {
    composes: suggestion from '../AutoSuggest/styles.module.css';
    /*padding: 20px 0 ;*/
}

/*.suggestion:hover {*/
/*  border-radius: 4px;*/
/*  background-color: #F3F3F5;*/
/*  transition: background-color .3s ease;*/
/*  white-space: nowrap;*/
/*  overflow: hidden;*/
/*  text-overflow: ellipsis;*/
/*}*/

.suggestionInner {
  display: flex;
  align-items: center;
  gap: 8px;
}

.suggestionImg {
  composes: suggestionImg from '../AutoSuggest/styles.module.css';
}

.suggestionImgDev {
  composes: suggestionImgDev from '../AutoSuggest/styles.module.css';
}

.suggestionImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.suggestionImgDev img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

.suggestionHighlighted {
    composes: suggestionHighlighted from '../AutoSuggest/styles.module.css';
}

.selectIcon {
  display: block;
  margin-right: 4px;
  max-width: 16px;
}

.suggestionTitle {
  color: #333338;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  transition: color .3s ease;

  display: inline-block;    /* или block, главное чтобы был блочный контекст */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.suggestion:hover .suggestionTitle {
  color: #4E6AFF;
  transition: color .3s ease;
}

.suggestionTextBlock {
  display: flex;
  flex-direction: column;

  flex: 1;
  min-width: 0;
}

.suggestionSubtitle {
  color: #AFB4CB;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.suggestionVerifiedIcon {
  width: 18px;
  height: 18px;
}

.suggestionVerifiedIcon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.suggestionTitleBlock {
  display: flex;
  align-items: center;
  gap: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noResultsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 10px;
  gap: 12px;
}

.noResultsIcon {
  width: 32px;
  height: 32px;
}

.noResultsText {
  color: #AFB4CB;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
