.modalContent {
  --modal-width: 400px;
  width: var(--modal-width);
  height: 100%;
  background-color: var(--cottage-view-background);
  border-radius: 24px 24px 0 0;
  border: none;
  padding: 60px 20px 125px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  @media screen and (max-width: 768px) {
    height: var(--vh-100);
    border-radius: 0;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.modalOverlay {
  position: fixed;
  height: calc(100% - 131px);
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding-top: 40px;
  @media screen and (max-width: 768px) {
    height: var(--vh-100);
    padding-top: 0;
    background: transparent;
    z-index: 16;
  }
}

.contactMobileHeader {
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    border-bottom: 1px solid var(--border-filter-bottom);

    button {
      width: 24px;
      height: 24px;
      background: transparent;
      border: none;
      outline: none;
      padding: 0;
    }

    span {
      color: var(--text-color);
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
    }
  }
}

.contactList {
  @media screen and (max-width: 768px) {
    padding: 16px 16px 32px;
  }
}

.closeButton {
  position: absolute;
  top: 60px;
  right: 20px;
  background: var(--cottage-view-background);
  border: 1px solid var(--border-icon-color);
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.companyInfoBlock {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
}

.logo {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #DFDFEB;
  object-fit: contain;
}

.companyName {
  color: var(--text-color);
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 120%;
  }
}

.companyType {
  color: var(--text-color);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.companyTitle {
  color: var(--plan-toggle-count-color);
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 12px;
  }
}

.companyContacts {
  margin-bottom: 24px;
}

.companyContact {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;

  &Img {
    padding: 4px;
    border-radius: 4px;
    border: 1px solid var(--border-icon-color);
    background: transparent;
  }

  span {
    color: var(--text-color);
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 120%;
  }
}

.companySchedule {
  background: var(--stepper-background);
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 24px;

  &Text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    color: var(--text-color);
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.companyUsers {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .companyUser {
    display: flex;
    align-items: flex-start;
    gap: 16px;

    &Img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      @media screen and (max-width: 768px) {
        width: 48px;
        height: 48px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &Name {
      color: var(--text-color);
      font-family: Inter;
      font-size: 18px;
      font-weight: 700;
      line-height: 140%;
      max-width: 250px;
      margin-bottom: 6px;
      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }

    &Position {
      display: block;
      color: var(--plan-toggle-count-color);
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 140%;
      text-transform: uppercase;
      margin-bottom: 12px;
      @media screen and (max-width: 768px) {
        font-size: 9px;
      }
    }

    &Experience {
      color: var(--text-color);
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      margin-bottom: 12px;
      max-width: 300px;
      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
}

.companyDescription {
  color: var(--text-color);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 16px;
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
  }
}






