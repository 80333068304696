.wrapper {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    gap: var(--indent-20);
}

.wrapperTheme {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 12px;
  stroke-width: 1px;
  border-left: 1px solid var(--border-filter-bottom);
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -3px;
  right: -2px;
  box-shadow: 0px 2px 7px 0px rgba(78, 106, 255, 0.40);
  border-radius: 20px;
  padding: 4px 7px 4px;
  min-width: 20px;
  text-align: center;
  background: #4E6AFF;

  color: #FFF;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
  text-transform: uppercase;
}

.favoritesNavBtn {
  color: var(--text-color);
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.favoritesBtn {
  position: relative;
}
