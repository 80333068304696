.wrapper {
  position: relative;
  height: var(--vh-100);
  background-color: #f3f3f5;
  overflow: auto;
}

.content {
  padding-top: 40px;
}

@media (max-width: 767px) {
  .content {
    height: calc(100% - 40px - 164px);
  }
}
