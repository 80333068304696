.wrapper {
  position: relative;
  width: calc(100% - 20px - 60px);
  margin-left: 20px;
  margin-right: 60px;
  height: var(--layout-building-tabs-height);
  overflow-y: hidden;
  overflow-x: auto;
}

.wrapper:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 50px;
  z-index: var(--z-index-3);
  background-image: linear-gradient(
    270deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.wrapper :global(.simplebar-horizontal) {
  opacity: 0;
}

.tabListWrapper {
  min-height: 50px;
}

.tablistButtonChess {
  padding: 4px 0 8px;
}
