.layoutPageHeaderMobileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--layout-header-height);
  position: relative;
  z-index: var(--z-index-4);
  background-color: #ffffff;
  padding: 6px 16px;
}

@media (min-width: 1200px) {
  .layoutPageHeaderMobileHeader {
    display: none;
  }
}


.layoutPageHeaderMobileBuildingsAndSortWrapper {
  background-color: #F3F3F5;
  padding: 0 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 49px;
}
@media (min-width: 768px) {
  .layoutPageHeaderMobileBuildingsAndSortWrapper {
    display: none;
  }
}

.layoutPageHeaderMobileBuildingsTablet {
  display: none;
}

@media (min-width: 768px) {
  .layoutPageHeaderMobileBuildingsTablet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
  }
}

.layoutPageHeaderMobileBuildingNavBarWrapper {
  margin: 0;
}

.headerRightButton {
  border: none;
  background: none;
  padding: 0;
  justify-content: flex-start;
}
