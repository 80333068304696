.modalOverlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 14;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  background: var(--background-dropdown-color);
  border-radius: 24px;
  width: 450px;
  max-width: 90%;
  outline: none;
  position: relative;
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);
}

.popupInner {
  display: flex;
  flex-direction: column;
}

.popupHeader {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 32px;
  @media screen and (max-width: 768px) {
    padding: 16px;
    gap: 8px;
  }
}

.warningIcon {
  width: 41px;
  min-width: 41px;
  height: 41px;
  @media screen and (max-width: 768px) {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
  img {
    width: 41px;
    object-fit: contain;
    @media screen and (max-width: 768px) {
      width: 24px;
    }
  }
}

.title {
  color: var(--text-color);
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 16px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 8px;
  }
}

.popupBody {
  color: var(--text-color);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.popupFooter {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px 32px 32px;
  border-top: 1px solid var(--border-filter-bottom);
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}

.btn {
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 10px 12px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  @media screen and (max-width: 768px) {}
}

.btnPrimary {
  background: #445EE7;
  color: #fff;
}

.btnSecondary {
  background: var(--background-dropdown-color);
  color: var(--text-color);
  border: 1px solid var(--border-icon-color);
}
