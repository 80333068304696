.tabListContainer {
  display: flex;
  justify-content: center;
  background: var(--toggle-modal-background);
  border-radius: 11px;
  padding: 0 3px;
  position: relative;
  width: fit-content;
}

.tabList {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.activeIndicator {
  position: absolute;
  top: 3px;
  bottom: 3px;
  background: var(--village-wrapper-background);
  border-radius: 11px;
  transition: all 0.4s ease;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.17);
}

.separator {
  width: 1px;
  height: 39%;
  background-color: var(--plan-toggle-separator-color);
  align-self: center;
  z-index: 0;
}
