.offersNotFoundWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .offersNotFoundWrapper {
    margin-top: 50%;
  }
}

.offersNotFoundIcon {
  margin-bottom: 16px;
}

.offersNotFoundTitle {
  color: var(--text-color);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 8px;
}

.offersNotFoundText {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.offersNotFoundText button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  color: #4E6AFF;
  font-weight: 600;
  cursor: pointer;
}
