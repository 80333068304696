.container {
  width: 100%;
  min-width: var(--vh-100);
  //height: calc(var(--vh-100) - 52px);
  background-color: #F1F1F1;
  //overflow: auto;
  @media screen and (max-width: 768px) {
    min-width: auto;
    max-width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 742px;
  padding: 23px 16px 135px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding: 16px 16px 78px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 60px;
  margin-bottom: 32px;
  @media screen and (max-width: 768px) {
    margin-bottom: 22px;
  }
}

.title {
  width: 100%;
  max-width: 569px;
  color: var(--text-color);
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 57px;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 22px;
  }
}

.step {
  position: relative;
  width: 100%;
  max-width: 686px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 60px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    gap: 31px;
    margin-bottom: 92px;
  }

  &.step2 {
    justify-content: flex-end;
    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }
}

.image {
  width: 277px;
  @media screen and (max-width: 768px) {
    width: 230px;
  }
}

.imageBlock {
  position: relative;

  .imageBlockIcon1 {
    position: absolute;
    top: 64px;
    right: -6px;
    @media screen and (max-width: 768px) {
      width: 36px;
      top: 54px;
      right: 6px;
    }
  }

  .imageBlockIcon2 {
    position: absolute;
    bottom: 116px;
    right: 40px;
    @media screen and (max-width: 768px) {
      width: 36px;
      bottom: 110px;
      right: 50px;
    }
  }

  .imageBlockIcon3 {
    position: absolute;
    top: 70px;
    right: 10px;
    @media screen and (max-width: 768px) {
      width: 36px;
      top: 62px;
      right: 24px;
    }
  }
}

.stepText {
  position: relative;
  text-align: left;
  color: var(--text-color);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  z-index: 3;
  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: left;
    max-width: 228px;
  }

  h3 {
    position: relative;
    color: var(--text-color);
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 16px;
    z-index: 3;
    @media screen and (max-width: 768px) {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 2px;
    }
  }

  p {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 3;
    @media screen and (max-width: 768px) {
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      align-items: flex-end;
    }
  }
}

.stepNumber {
  position: absolute;
  bottom: 0;
  right: -56px;
  transform: translateY(42%);
  color: #FFF;
  font-family: Inter;
  font-size: 500px;
  font-weight: 400;
  line-height: 120%;
  z-index: 1;
  @media screen and (max-width: 768px) {
    font-size: 80px;
    right: auto;
    bottom: 14px;
    left: -64px;
  }
}

.step2, .step3 {
  .stepNumber {
    @media screen and (max-width: 768px) {
      bottom: 20px;
    }
  }
}

.stepNumberImg {
  @media screen and (max-width: 768px) {
    width: 14px;
  }
}

.step2 {
  .stepNumber {
    right: 40px;
  }
}

.step4 {
  margin-top: 38px;
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
}
