.documentsSection {
  padding-top: 20px;
  margin-bottom: 30px;
}

.docHeader {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.files {
  margin-top: 45px;
  display: grid;
  grid-gap: 20px 30px;
  grid-template-columns: repeat(3, minmax(200px, max-content));
}

@media (max-width: 1024px) {
  .files {
    grid-template-columns: repeat(2, minmax(200px, max-content));
  }
}

@media (max-width: 1024px) {
  .files {
    overflow: auto;
    grid-template-columns: repeat(2, minmax(200px, max-content));
  }
}

@media (max-width: 540px) {
  .files {
    overflow: auto;
    grid-gap: 10px 30px;
    grid-template-columns: 1fr;
  }
}

.docCard {
  display: flex;
}

.docCard img {
  width: 33px;
  height: 69px;
}

.docMeta {
  display: flex;
  flex-direction: column;
}

.docName {
  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #404042;
}

.docSize {
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #B9B9B9;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #4E6AFF;
  cursor: pointer;

  background-color: transparent;
  border: none;
  padding: 0;
}

.separator::before, .separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #DFDFEB;
}

.separator::before {
  margin-right: .25em;
}

.separator::after {
  margin-left: .25em;
}

.iconUp {
  margin-left: 4px;
}

.iconDown {
  margin-left: 4px;
  transform: rotate(180deg);
}
