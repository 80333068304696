.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  padding: 12px;
}

.container h2 {
  color: #000000;

  font-weight: bold;
  font-size: 24px;
  line-height: 35px;

  margin-bottom: 8px;
}

@media (min-width: 1024px) {
  .container h2 {
    color: #000;
    text-align: center;
    font-family: Circe;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    max-width: 730px;
    margin-bottom: 24px;
  }
}

.container p {
  color: #B9B9B9;
  text-align: center;
  font-family: Circe;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}
