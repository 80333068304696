.filterButton {
  display: flex;
  align-items: center;

  background-color: #ffffff;
  border: none;

  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #000000;

  cursor: pointer;
}

.selectedButton {
  display: flex;
  align-items: center;
  gap: 4px;
  background: #E3E6E8;
  border-radius: 4px;
  padding: 4px 8px;

  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

@media screen and (max-width: 768px) {
  .selectedButton {
    font-size: 14px;
  }
}

.largeFilterBtn {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: none;
}

.closeButton img {
  width: 12px;
}

.disabledButton {
  opacity: 0.5;
}
