.button {
  min-width: 118px;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  padding: 8px 10px;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color);
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 2;
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  transition: color 0.4s ease;
}

@media screen and (max-width: 768px) {
  .button {
    min-width: 44px;
    min-height: auto;
  }
}

.label {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 1px 0;
}

.active {
  color: #4E6AFF;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.helperText {
  font-size: 12px;
  color: #000000;
}

.badge {
  margin-top: 4px;
}
