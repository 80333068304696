.wrapperQueueBuildingsFilter {
  margin-top: -30px;
}

@media (max-width: 768px) {
  .wrapperQueueBuildingsFilter {
    margin-top: 0;
  }
}

.queuesWrapper {
  margin-top: var(--indent-60);
}

@media (max-width: 768px) {
  .queuesWrapper {
    font-size: 24px;
    line-height: 35px;
  }
}

.iconUp {
  margin-left: 4px;
}

.iconDown {
  margin-left: 4px;
  transform: rotate(180deg);
}
