.point {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #4e6aff;
    border: 1px solid #fff;
}

.point_active .point {
    background-color: #DD6465;
}
