
.socialButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  border: none;
  border-radius: 8px;

  cursor: pointer;
}

.socialButtonVK {
  background-color: #507299;
}

.socialButtonFB {
  background-color: #365899;
}

.socialButtonTG {
  background-color: #35A6DE;
}

.socialButtonWhatsApp {
  background-color: #67C15E;
}
