.apartmentDetails {
  width: 100%;
  padding: 50px 32px 50px;
  background-color: #ffffff;
  page-break-inside: avoid;
  break-inside: avoid;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

@media (min-width: 768px) {
  .content {
    flex-direction: row;
  }
}

.description {
  flex: 1;
}

.singleImageContainer {
  flex: 1;
}

.singleImageContainer img {
  width: 100%;
  max-width: 482px;
  border-radius: 8px;
  object-fit: contain;
  max-height: 650px;
}

.status {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 24px;
}

.statusBadge {
  padding: 4px 16px;
  color: #FFF;
  font-size: 18px;
  font-weight: 600;
  line-height: 140%;
  text-transform: uppercase;
  border-radius: 24px;
}

.green {
  background-color: #20CB6A;
}

.greenLight {
  background-color: #3ECBBE;
}

.red {
  background-color: #EE5656;
}

.blue {
  background-color: #4E6AFF;
}

.description h2 {
  color: #333338;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 32px;
  line-height: normal;
}

.description p {
  color: #333338;
  font-size: 32px;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 32px;
}

.description h2 span:last-child {
  white-space: nowrap;
}

.description p span span {
  color: #4E6AFF;
}

.description .pricePerM {
  color: #AFB4CB;
  font-size: 28px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 0;
}

.description .totalPrice {
  color: #4E6AFF;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}

.imagesContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin-top: 32px;
  gap: 32px;
}

.plan img {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}
