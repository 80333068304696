.formRender {
  position: relative;
  font-family: Inter;
}

.formRenderWrapper {
  padding: 32px 0;
  background-color: var(--background-color);
  border-radius: 24px;
}

@media screen and (max-width: 768px) {
  .formRenderWrapper {
    padding: 14px 0;
  }
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: var(--z-index-4);
  top: 32px;
  right: 32px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  border: 1px solid var(--border-icon-color);
  background-color: transparent;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .closeButton {
    display: none;
  }
}

.closeButtonIcon {
  display: block;
  width: 16px;
  height: 16px;
}

.formRenderHeader {
  margin-bottom: 27px;
  display: block;
  padding: 0 32px;
}

.formRenderHeaderMobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .formRenderHeader {
    display: none;
  }

  .formRenderHeaderMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding: 0 16px 14px;
    border-bottom: 1px solid var(--border-icon-color);
  }

  .formRenderHeaderMobile button {
    background: none;
    border: none;
    outline: none;
  }
}

.formRenderTitle {
  color: var(--text-color);
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
}

@media screen and (max-width: 768px) {
  .formRenderTitle {
    font-size: 20px;
  }
}

.formRenderBody {
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  padding: 0 32px;
  max-height: calc(100vh - 300px);
  overflow: auto;
}

@media screen and (max-width: 768px) {
  .formRenderBody {
    padding: 0 16px;
    margin-bottom: 26px;
    grid-template-columns: 1fr;
    max-height: calc(100vh - 113px);
    overflow: scroll;
  }
}

.formRenderBody textarea {
  height: 414px;
}

/* Левая колонка (группа инпутов) */
.formRenderInputGroup > * {
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .formRenderInputGroup > * {
    margin-bottom: 24px;
  }

  .formRenderInputGroup > *:last-child {
    margin-bottom: 0;
  }
}

.formRenderInputGroup label {
  margin-bottom: 8px;
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

@media (max-width: 768px) {
  .formRenderInputGroup label {
    font-size: 12px;
  }

  .formRenderInputGroup {
    padding-bottom: 32px;
  }
}

.formRenderInputGroup label ~ div {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid var(--border-icon-color);
  background: var(--background-input-color);
}

@media screen and (max-width: 768px) {
  .formRenderInputGroup label ~ div {
    height: 40px;
    padding: 10px 11px;
  }
}

.formRenderInputGroup label ~ div input {
  padding: 12px 0;
  background: var(--background-input-color);
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.formRenderInputGroup label ~ div input::placeholder {
  color: var(--plan-toggle-count-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

@media screen and (max-width: 768px) {
  .formRenderInputGroup label ~ div input::placeholder {
    font-size: 14px;
  }
}

.formInput li {
  grid-template-columns: auto 46px;
}

.formInput li div div {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid var(--border-icon-color);
  background: var(--background-input-color);
}

@media screen and (max-width: 768px) {
  .formInput li div div {
    padding: 10px 11px;
    height: 40px;
  }
}

.formInput li button {
  border: 1px solid var(--border-icon-color);
  background: transparent;
  padding: 0 5px;
}

.formInput li div div input {
  padding: 12px 0;
  background: var(--background-input-color);
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  border: none;
}

@media screen and (max-width: 768px) {
  .formInput li div div input {
    font-size: 14px;
    height: 30px;
  }
}

.formInput li div div input::placeholder {
  color: var(--plan-toggle-count-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

@media screen and (max-width: 768px) {
  .formInput li div div input::placeholder {
    font-size: 14px;
  }
}

.formTextArea textarea {
  padding: 12px 16px;
  background: var(--background-input-color);
  color: var(--text-color);
  border: 1px solid var(--border-icon-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.formTextArea textarea::placeholder {
  color: var(--plan-toggle-count-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.formRenderFooter {
  width: 100%;
  display: grid;
  gap: 8px;
  padding: 16px 32px 0;
  border-top: 1px solid var(--border-filter-bottom);
}

.formRenderFooter button {
  max-width: 100%;
}

@media (min-width: 768px) {
  .formRenderFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .formRenderFooter button {
    max-width: inherit;
  }
}

.formRenderDisclaimerWrapper {
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
}

.formRenderDisclaimerText {
  margin-left: 14px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #404042;
}

.requiredStar {
  color: red;
  margin-left: 4px;
}

.requiredStarLeft {
  margin-left: 0;
  margin-right: 4px;
  color: red;
}

.requiredInfo {
  color: var(--plan-toggle-count-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.textareaContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.textareaContainerMobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .formRenderBody .textareaContainer {
    display: none;
  }

  .textareaContainerMobile {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .textareaContainerMobile textarea {
    height: 140px;
  }
}

.textareaContainer label {
  margin-bottom: 8px;
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.remainingChars {
  color: var(--plan-toggle-count-color);
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  margin-top: 8px;
}

.formRenderFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.footerLeft,
.footerCenter,
.footerRight {
  display: flex;
  align-items: center;
}

.footerLeft button {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--border-icon-color);
  padding: 8px 12px;
  background: transparent;
}

.footerLeft button span {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
}

.footerCenter button {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--border-icon-color);
  padding: 8px 12px;
  background: transparent;
}

.footerCenter button span {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
}

.footerRight button {
  border-radius: 8px;
  background: #445EE7;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
}

.footerRight button span {
  display: flex;
  align-items: center;
  gap: 4px;
}

.footerLeft { justify-content: flex-start; }
.footerCenter { justify-content: center; }
.footerRight { justify-content: flex-end; }

.textareaContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.textareaContainer textarea {
  resize: none;
}

.footerRightBlock {
  display: flex;
  align-items: center;
  gap: 16px;
}

@media screen and (max-width: 768px) {
  .footerLeft,
  .footerCenter {
    display: none;
  }

  .footerRight {
    width: 100%;
  }

  .footerRight button {
    width: 100%;
  }

  .formRenderFooter {
    display: block;
    position: absolute;
    bottom: 0;
    padding-top: 4px;
    padding-bottom: 16px;
    background: var(--background-color);
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);
  }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: var(--background-input-color) !important;
  color: var(--text-color) !important;
  -webkit-text-fill-color: var(--text-color) !important;
  border: none !important;
  box-shadow: 0 0 0px 1000px var(--background-input-color) inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

