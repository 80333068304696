.apartmentCardWrapper {
  background-color: #ffffff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  min-height: 188px;
  overflow: hidden;
}

@media(min-width: 1280px){
  .apartmentCardWrapper {
    display: flex;
  }
}

.apartmentFloorPlanPreview {
  width: 100%;
}
@media(min-width: 1280px){
  .apartmentFloorPlanPreview {
    width: 232px;
    height: 188px;
  }
}

.apartmentCardLeft {
  width: 100%;
}
@media(min-width: 1280px){
  .apartmentCardLeft {
    width: 232px;
  }
}

.apartmentCardRight {
  width: 100%;
  padding: 12px 12px 16px 12px;
}

@media(min-width: 1280px){
  .apartmentCardRight {
    width: calc(100% - 232px);
    padding: 10px 10px 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.apartmentCardTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.apartmentCardTopStatusWrapper {
  margin-bottom: 10px;
}

.apartmentCardBottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.apartmentCardTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
}

.apartmentCardAddress {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  color: #404042;
}

.apartmentCardUpdateDate {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: right;

  color: #B9B9B9;
}