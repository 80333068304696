.wrapperBlock {
    margin-right: 0;
}



@media (max-width: 1024px) {
    .wrapperBlock {
        margin-right: 40px;
    }
}

@media (max-width: 768px) {
    .wrapperBlock {
        margin-right: -20px;
    }
}

@media (max-width: 540px) {
    .wrapperBlock {
        margin-right: -15px;
    }
}
