.default {
    background-color: #4E6AFF;
    color: #fff;
}

.alert {
    background-color: #DD6465;
    color: #fff;
}

