.complexLogoWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.complexLogo {
  margin-right: 10px;
}

.complexLogoTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;

  color: #000000;
}

.complexLogoSubTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  color: #000000;
}
