.addToOffersHeaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 16px;
  font-family: Inter;
}

.addToOffersHeaderWrapperInner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .addToOffersHeaderWrapperInner {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--border-filter-bottom);
    padding: 14px 16px;
  }
}

.closeButton {
  margin-bottom: 10px;
  background-color: var(--cottage-view-background);
  border-color: var(--border-icon-color);
}

@media screen and (max-width: 768px) {
  .closeButton {
    min-width: 16px;
    min-height: 16px;
    border: none;
    padding: 0;
    margin-bottom: 0;
  }
}

.addOffersBtn {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 10px 12px;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  background-color: var(--cottage-view-background);
  border-color: var(--border-icon-color);
}

@media (min-width: 768px) {
  .addToOffersHeaderWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 16px;
  }
}

.addToOffersHeaderTitle {
  color: var(--text-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

@media screen and (max-width: 768px) {
  .addToOffersHeaderTitle {
    font-size: 20px;
  }
}

.addToOffersHeaderTop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.addToOffersHeaderWrapperInnerMobile {
  display: none;
}

.addToOffersHeaderSearch {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .addToOffersHeaderWrapperInner {
    display: none;
  }

  .addToOffersHeaderWrapperInnerMobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--border-filter-bottom);
    padding: 14px 16px;
  }

  .addToOffersHeaderWrapperInnerMobile button {
    background: none;
    border: none;
    outline: none;
  }

  .addToOffersHeaderWrapperInnerMobile button > img {
    width: 24px;
    max-width: 24px;
    height: 24px;
    max-height: 24px;
  }

  .addToOffersHeaderSearch {
    padding: 0 20px;
  }
}









