.bannerApp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 18px;
  background: #F3F3F5;
  padding: 8px 12px;
  width: 266px;
}

.bannerAppImg {
  width: 44px;
}

.bannerAppTitle {
  display: block;
  color: var(--text-color);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.bannerAppText {
  display: block;
  color: #AFB4CB;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}