.pricesSquare {
  color: var(--color-gray-900);
}

@media (max-width: 768px) {
  .mainPrices {
    font-size: 14px;
    line-height: 24px;
  }
}
