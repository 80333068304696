.title {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;

  color: #000000;

  padding-bottom: 12px;
}

.subTitle {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #404042;

  margin-bottom: 20px;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1024px) {
  .wrapper {
    display: flex;
  }
}

.buttonGroup {
  width: 100%;
  margin-top: 24px;
}
@media (min-width: 1024px) {
  .buttonGroup {
    margin-top: 30px;
  }
}
