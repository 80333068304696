.reward {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: var(--stepper-background);
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  @media screen and (max-width: 768px) {
    padding: 8px 16px;
    margin-bottom: 16px;
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }

  .rewardIcon {
    width: 60px;
    height: 60px;
    @media screen and (max-width: 768px) {
      width: 48px;
      height: 48px;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .percent {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
  }

  .contentRight {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 32px;
  }

  .amount {
    color: var(--text-color);
    text-align: left;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
    @media screen and  (max-width: 768px) {
      font-size: 20px;
    }
  }

  .description {
    color: var(--text-color);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    max-width: 200px;
    @media screen and  (max-width: 768px) {
      font-size: 12px;
    }
  }

  .infoIcon {
    width: 16px;
    height: 16px;
    margin-left: 12px;
    cursor: pointer;
  }
}

.infoIconWrapper {
  position: relative;
  display: inline-block;
}
