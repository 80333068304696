.infoCardWrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f3f3f5;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 992px) {
  .infoCardWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  }
}

.infoCardLeft {
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  color: #404042;
  margin-bottom: 8px;
}

@media (min-width: 992px) {
  .infoCardLeft {
    margin-bottom: 0;
  }
}

.infoCardLeft > *:first-child {
  margin-bottom: 5px;
}

.infoCardRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
