.group {
    display: flex;
}

.group button:not(:first-child) {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.group button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
