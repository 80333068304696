.wrapper {
  position: relative;
  z-index: var(--z-index-4);
}

.childrenWrapper {
  position: relative;
  z-index: var(--z-index-6);
}

.actionsBarWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index-5);
  height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  

  transform: translateY(-100%) translateZ(20px);
  opacity: 0;
  transition: all 0.4s ease-in-out;

  background: #F3F3F5;
  border-radius: 0 0 4px 4px;
  padding: 0 8px;
}

.apartmentLink {
  padding: 0;
  margin: 12px;
}

.wrapper:hover {
  z-index: var(--z-index-6);
}

.wrapper:hover .actionsBarWrapper {
  transform: translateY(40px) translateZ(0);
  opacity: 1;
}