
.button {
    background-color: transparent;
    border: none;

    /*width: 16px;*/
    /*height: 16px;*/
    /*padding-right: 4px;*/
    /*padding-left: 4px;*/
    /*margin-left: -20px;*/
}

.dropdownPlaceholder {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.dropdownImage {
    margin: 0 8px 0 0;
}
