
.wrapper {
    padding: 4px 12px 24px;
}

@media (min-width: 1024px) {
    .wrapper {
        padding: 60px;
    }

}

.wrapper h2, .wrapper h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;

    /* Hard black */

    color: #000000;
}
.wrapper p, .wrapper li {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #404042;
}
.wrapper p, .wrapper ul, .wrapper h2, .wrapper h3 {
    margin: 20px 0;
}

.wrapper ul, .wrapper li {
    padding: 0;
    list-style-type: decimal;
}
.wrapper ul{
    padding: 0 0 0 20px;
}