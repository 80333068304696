.wrapper {
  width: max-content;
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 8px;
  border-radius: 8px;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  box-sizing: border-box;
}

.error {
  background-color: #FEEFEF;
  border: 1px solid #FBCBCB;
  color: #C62828;
}

.primary {
  background-color: #E6F4EA;
  border: 1px solid #BDE2C4;
  color: #2E7D32;
}

.standard {
  background: var(--background-color);
  border: 1px solid var(--border-icon-color);
  border-radius: 8px;
  margin-top: 10px;
  color: var(--text-color);
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.25);
}

.icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
