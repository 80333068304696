
.scrollBarWrapper {
  background: var(--background-color);
  padding: 8px 0 8px 12px;
  border-bottom: 1px solid var(--border-color);
}
@media (min-width: 768px) {
  .scrollBarWrapper {
    padding: 12px 0 12px 24px;
  }
}
@media (min-width: 1024px) {
  .scrollBarWrapper {
    padding: 8px 0 8px 20px;
  }
}
@media (min-width: 1440px) {
  .scrollBarWrapper {
    padding: 8px var(--indent-20);
  }
}

.wrapper {
  display: flex;
  width: 100%;
}
.filters {
  display: flex;
  flex-basis: 100%;
  justify-content: flex-start;
  margin-right: var(--indent-10);
}

.filters > * {
  margin-right: var(--indent-10);
}
.filters > *:last-child {
  margin-right: 0;
}

@media (min-width: 768px) {
  .filters > * {
    margin-right: var(--indent-10);
  }
}

.otherButtons {
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;
}

.otherButtons > * {
  margin-right: 10px;
}
.otherButtons > *:last-child {
  margin-right: 12px;
}

@media (min-width: 768px) {
  .otherButtons > *:last-child {
    margin-right: 24px;
  }
}
@media (min-width: 1024px) {
  .otherButtons > *:last-child {
    margin-right: 20px;
  }
}
@media (min-width: 1440px) {
  .otherButtons > *:last-child {
    margin-right: 0;
  }
}

.searchWrapper {
  display: none;
}

@media (min-width: 1024px) {
  .searchWrapper {
    display: block;
    margin-right: 12px;
  }
}

.priceWrapper {
  display: flex;
  align-items: center;
  max-width: 100%;
  height: 46px;
  border-radius: 8px;
  border: 1px solid #DFDFEB;
  background: #F8F8F8;
}

.priceWrapper.open {
  outline: 2px solid #4E6AFF;
}

@media (min-width: 768px) {
  .priceWrapper {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 1024px) {
  .priceWrapper {
    display: none;
  }
}

@media (min-width: 1100px) {
  .priceWrapper {
    display: flex;
    align-items: center;
  }
}

.priceWrapper span {
  color: var(--text-color);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  margin-right: 8px;
}

.roomWrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 46px;
  border-radius: 8px;
  border: 1px solid #DFDFEB;
  background: #F8F8F8;
}

.roomWrapper.open {
  outline: 2px solid #4E6AFF;
}

.roomWrapper span {
  margin-right: 8px;
  color: var(--text-color);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}
