.wrapper {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: auto;
}

@media (max-width: 768px) {
    .wrapper {
        overflow-x: auto;
    }
}

.navigateButtonGroup {
    width: 90px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

@media (max-width: 768px) {
    .navigateButtonGroup {
        display: none;
    }
}

.navigateButtonIcon {
    width: 16px;
}

.gradientInTheSwiperViewportEnd {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 57px;
    z-index: var(--z-index-3);
    background-image: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}


.queuesSwiperWrapper :global(.swiper-slide){
    width: auto!important;
}