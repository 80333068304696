.tabList {
    position: relative;
    border-bottom: var(--border-base);

    display: grid;
    grid-auto-flow: column;
    gap: 15px;
    grid-auto-columns: max-content;
}

@media (min-width: 1200px) {
    .tabList {
        gap: 30px;
      margin-right: 32px;
    }
}

.tabListActive{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background: #4e6aff;
    border-radius: 11px 11px 0 0;
    transition:all .2s linear;
}
