.albumDropdown {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 12px;
}

.albumDropdownName {
  color: #AFB4CB;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
}

@media screen and (max-width: 768px) {
  .albumDropdownName {
    display: none;
  }
}

.albumDropdownLine {
  height: 22px;
  color: #DFDFEB;
  border-left: 1px solid #DFDFEB;
}

@media screen and (max-width: 768px) {
  .albumDropdownLine {
    display: none;
  }
}

.albumDropdownHeader {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 8px;
}

.folderIcon {
  width: 20px;
  height: 20px;
}

.albumName {
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
}

.arrowIcon {
  width: 12px;
  height: 12px;
  transition: transform 0.3s ease;
}

.arrowIconOpen {
  transform: rotate(180deg);
}

.albumDropdownList {
  width: 200px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #333338;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  max-height: 600px;
  overflow-y: auto;
  margin-top: 5px;
  z-index: 9;
}

.albumDropdownItem {
  background: transparent;
  cursor: pointer;
  padding: 8px;
  color: #FFF;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.albumDropdownItem:hover {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
}

.albumNameBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;
}

.albumDropdownItemMobile {
  display: block;
  width: 100%;
  padding: 10px 8px;
  text-align: left;
  border: none;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  font-style: normal;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.albumDropdownItemMobileActive {
  background: rgb(243, 243, 245);
  color: #4E6AFF;
}

.bottomSheetHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.bottomSheetHeader h2 {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
}

.closeBtn {
  background: none;
  border: none;
  cursor: pointer;
}




