.previewSlideshowWrapper {
  padding: 4px 12px;
  height: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .previewSlideshowWrapper {
    padding: 4px 10px;
  }
}

.previewSlideshowImageWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.previewSlideshowImageWrapper :global(.swiper-slide) {
  display: flex;
  align-items: center;
}

@media (max-width: 1280px) {
  .previewSlideshowImageWrapper :global(.swiper-slide) {
    padding: 10px;
  }
}

.previewSlideshowImage {
  display: block;
  object-fit: contain;
  margin: auto;

  height: 166px;
  width: auto;
}

.previewSlideshowPagination {
  display: grid;
  gap: 4px;
  position: absolute;
  bottom: 0 !important;
  width: 100%;
}
.previewSlideshowPagination :global(.swiper-pagination-bullet) {
  opacity: 0.3;
  background-color: #000000;
  height: 2px;
  margin: 0 !important;
  border-radius: 0;
  width: auto;
}

.previewSlideshowPagination :global(.swiper-pagination-bullet-active) {
  opacity: 1;
}
