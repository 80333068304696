.wrapper {
    display: flex;
    padding: 12px;
    flex-direction: row;
    box-shadow: inset 0 -1px 0 #DFDFEB;
}
.left {
    width: 50%;
}
.right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.marginBottom4 {
    margin-bottom: 4px;
}
.marginBottom8 {
    margin-bottom: 8px;
}