.wrapper {
}

.topWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.topRight {
  flex-grow: 1;
}

.logo {
  width: 120px;
  margin-right: 20px;
  @media screen and (max-width: 768px) {
    width: 84px;
  }
}

.logoImage {
  display: block;
  width: 100%;
}

.infoWrapper {
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &Inner {
    width: 100%;

    &Mobile {
      @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
      }
    }

    //&Left {
    //  @media (max-width: 768px) {
    //  }
    //}
  }
}

.infoWrapperType {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-color);
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.infoWrapperStatus {
  div {
    color: #ffffff;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 140%;
    text-transform: uppercase;
    border-radius: 12px;
    div {
      padding: 4px 8px 2px;
      margin-bottom: 8px;
    }
  }
}

.infoWrapperPrice {
  margin-bottom: -23px;
}

.price {
  color: var(--text-color);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.price_m2 {
  display: block;
  color: var(--plan-toggle-count-color);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.actionButtonsWrapper {
  display: grid;
  gap: 12px;
  grid-template-columns: min-content min-content min-content min-content min-content;
  margin-top: 8px;

  button {
    background: transparent;
  }
}

.actionButtonsWrapper .btnReadMore {
  border-radius: 80px;
  background: var(--stepper-background);
  border: none;
  padding: 10px 24px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.squareListWrapper {
  padding-top: 16px;
  padding-bottom: 16px;
}

.floorSwitcher {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}

.floorSwitcher button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.floorSwitcher button:disabled {
  color: gray;
  cursor: default;
}

.floorSwitcher span {
  margin: 0 10px;
  font-size: 16px;
}

.actionButtonsWrapper .headerBtnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--stepper-background);
  border: none;
  //padding: 3px 0 0 0;
  cursor: pointer;
  span {
    width: 24px;
    max-width: 24px;
    height: 24px;
  }

  img {
    width: 24px;
    max-width: 24px;
    height: 24px;
  }
}


@media (min-width: 768px) {
  .squareListWrapper {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.alertWrapper {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  max-width: 300px;
}

.disabled {
  opacity: .3;
}

.buttonBlock {
  position: relative;
}