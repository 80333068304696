
.floorPlanPlaceholderWrapper {
  overflow: hidden;
  background-color: #f4f3f6;
}

.floorPlanPlaceholderImage {
  display: block;
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}