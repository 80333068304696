.offersCardWrapper {
  width: 100%;
}

.offersCardTitle {
  color: var(--text-color);
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 12px;
}

.offersCardDescription {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 12px;
}
