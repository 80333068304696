
.buttonGroup {
    display: flex;
    flex-direction: row;
    gap: var(--indent-10);
}

.avatarFioAndStatusWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: var(--indent-10);
}
.avatarAndFioWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: calc(100% - 110px);
}

.avatarWrapper {
    margin-right: var(--indent-5);
}

@media (max-width: 540px) {
    .showContacts {
        white-space: initial;
    }
}
