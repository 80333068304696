.modalContent {
  --modal-width: 400px;
  width: var(--modal-width);
  height: 100%;
  background-color: #fff;
}

.wrapper {
  padding: 12px;
}

.wrapperIsNotFloorPlan:after {
  height: 0!important;
}

@media (min-width: 768px) {
  .wrapper {
    position: relative;
    /*overflow: auto;*/
    height: 100%;
    width: 100%;
    padding: 20px;
  }
  .wrapper:after {
    content: '';
    display: block;
    width: calc(var(--modal-width) - 40px);
    height: 310px;
  }
}

.modalOverlay {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: var(--z-index-3);
  /* 80px - header height */
  height: calc(var(--vh-100) - var(--navbar-height));
}

.closeButton {
  position: absolute;
  top: 28px;
  left: -40px;
  z-index: var(--z-index-3);
  border: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: -5px 100px 80px rgba(0, 0, 0, 0.07),
    -5px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    -5px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    -5px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    -5px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    -5px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
}

.complexMap {
  height: 232px;
  width: 100%;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}

.mapWrapper {
  margin-bottom: 20px;
  border-radius: 16px;
}

@media screen and (max-width: 768px) {
  .mapWrapper {
    margin-bottom: 16px;
  }
}

.floorPlanWrapper {
}

@media (min-width: 768px) {
  .floorPlanWrapper {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: var(--z-index-4);
    background-color: #ffffff;
    width: var(--modal-width);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  }
}
