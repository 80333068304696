.group {
    display: flex;
}

.group input[type=text] {
    width: 100%;
}

.group input[type=text]:not(:first-child) {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.group input[type=text]:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

/* классы добавляются на элементы группы */
.group_item_first, .group_item_first input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
}
.group_item_last, .group_item_last input {
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
}
