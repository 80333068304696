.layout {
  background-color: #f3f3f5;
  padding-top: 40px;
}

.layoutWrapper {
  display: grid;
  grid-template-columns: calc(100% - 292px) 292px;
  background-color: var(--color-white);
  width: 100%;
  max-width: calc(936px + 292px);
  margin: 0 auto;
  min-height: var(--vh-100);
}

@media (max-width: 767px) {
  .layoutWrapper {
    grid-template-columns: auto;
    min-height: unset;
    height: 100%;
  }
}

.layoutContent {
  padding: 40px 12px;
  max-width: 100vw;
}

@media (min-width: 1280px) {
  .layoutContent {
    padding: 40px 60px;
  }
}

@media (max-width: 767px) {
  .layoutContent {
    height: calc(100% + 162px);
  }

  .layoutContentEdit {
    height: calc(100% + 115px);
  }
}

.layoutSidebar {
  padding: 20px;
  box-shadow: -1px 0 0 #dfdfeb, 0 -1px 0 #dfdfeb, inset -1px 0 0 #dfdfeb,
    inset 0 -1px 0 #dfdfeb;
}

@media (max-width: 767px) {
  .layoutSidebar {
    position: fixed;
    bottom: 0;
    height: 162px;
    left: 0;
    z-index: 22222222;
    width: 100%;
    background: #fff;
    transition: all 0.2s ease-in-out;

    padding-top: 0;
  }

  .layoutSidebarEdit {
    height: 115px;
  }

  .isShown {
    height: 100%;
    overflow-y: auto;
    transition: all 0.2s ease-in-out;
  }
}
