.blockCard {
    min-width: 100px;
    min-height: 57px;
    border: var(--border-size-2) solid var(--color-blue-200);
    border-radius: var(--border-radius-size-m);
    cursor: pointer;
    background-color: var(--color-white);
    text-align: left;
    padding: var(--indent-12);
}

.blockCard div {
    display: flex;
    flex-direction: column;
}


.activeCard {
    border: var(--border-size-2) solid var(--color-blue);
}

.queueText {
    font-size: 12px;
    height: 16px;
    font-weight: 400;
    color: #404042;
}

@media (max-width: 768px) {
    .queueText {
        height: 32px;
    }
}
