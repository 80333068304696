.squareListWrapper {
    display: flex;
    justify-content: space-between;
}

.squareListWrapper > * {
    margin-right: 12px;
}
.squareListWrapper > *:last-child {
    margin-right: 0;
}


.squareItemLabel {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    color: #404042;
}
.squareItemContent {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}
@media (min-width: 768px) {
    .squareItemContent {
        font-size: 16px;
        line-height: 24px;
    }
}
