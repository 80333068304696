.complexDescription {
  padding: 50px 32px;
}

.sectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #333338;
  font-size: 65px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 68px;
  border-bottom: 8px solid #333338;
  padding-bottom: 32px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
}

.imageLeft {
  width: 100%;
  height: 484px;
}

.imageLeft img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageRight {
  display: grid;
  grid-template-rows: repeat(2, 226px);
  gap: 32px;
}

.imageTop {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageBottom {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
}

.fullWidth {
  grid-template-columns: 1fr;
}

.imageBottom div {
  height: 100%;
  max-height: 226px;
}

.imageBottom img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text p {
  color: #333338;
  font-size: 32px;
  font-weight: 400;
  line-height: 140%;
}
