.block {
  display: flex;
  flex-direction: column;

  margin-top: var(--indent-60);
}

.blockTitle {
  font-weight: bold;
  font-size: 32px;
  line-height: 47px;
  margin-bottom: var(--indent-20);
}

@media (max-width: 768px) {
  .blockTitle {
    font-size: 24px;
    line-height: 35px;
  }
}
