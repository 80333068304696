#photocard .swiper-button-prev::after, #photocard .swiper-button-next::after{
  content:"none";
}

#photocard .swiper-button-prev {
  background-image: url('assets/arrowLeft.svg');
}

#photocard .swiper-button-next {
  background-image: url('assets/arrowRight.svg');
}

#photocard .swiper-button-prev,
#photocard .swiper-button-next {
  background-repeat: no-repeat;
  background-position: center;
}
