.complexLogoTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
}

@media (min-width: 768px) {
  .complexLogoTitle {
    font-size: 26px;
    line-height: 38px;
  }
}

.complexLogoDeveloperName {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #404042;
}

.complexLogoAddress {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #404042;
}

@media (min-width: 768px) {
  .complexLogoAddress {
    display: none;
  }
}

.complexLogoWrapper {
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .complexLogoWrapper {
  }
}

.complexLogoImageWrapper {
  width: 60px;
  height: 60px;
  margin-right: 12px;
  overflow: hidden;
  border: 1px solid #f1f6fc;
  border-radius: 8px;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .complexLogoImageWrapper {
    width: 100px;
    height: 100px;
    margin-right: 15px;
  }
}

.complexLogoImage {
  width: 100%;
  object-fit: contain;
}
