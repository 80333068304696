.adressWrapper {
  grid-row: 2/3;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-bottom: var(--indent-30);
}

@media (max-width: 1280px) {
  .adressWrapper {
    order: 1;
    justify-content: flex-start;
  }
}

.left_content {
  margin-right: var(--indent-20);
  flex-shrink: 1;
}

.right_content {
  flex-shrink: 0;
}
