.feedsPageTitle {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;

  color: #000000;
}

@media (min-width: 1024px) {
  .feedsPageTitle {
    margin-bottom: 20px;
  }
}

.feedsPageEmptyDataWrapper {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}