.wrapper {
    overflow: auto;
    height: 100%;
}

.mapWrapper {
    height: 300px;
    width: 100%;
}
.mapWrapper .leaflet-div-icon {
    background-color: transparent !important;;
    border: none !important;
}



.companyAvatarWrapper {
    margin: 0 12px;
}
@media screen and (min-width: 1024px){
    .companyAvatarWrapper {
        margin: 0;
    }
}



.title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;

    margin: 16px 12px;

    color: #000000;
}
@media screen and (min-width: 1024px){
    .title {
        font-size: 20px;
        line-height: 16px;
        margin: 20px 0;
    }
}

.description {
    margin: 16px 12px;
}
@media screen and (min-width: 1024px){
    .description {
        margin: 20px 0;

    }
}



.contentWrapper {
    padding: 24px 0 32px 0;
}
@media screen and (min-width: 1024px){
    .contentWrapper {
        padding: var(--indent-20) 60px 60px 60px;
    }
}

@media screen and (min-width: 1024px){
    .contactsWrapper {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding-bottom: var(--indent-20);
        border-bottom: var(--border-base);
    }

}

.managerList {
    margin-top: 32px;
    display: grid;
    gap: 32px;
    padding-right: 12px;
    padding-left: 12px;
}
@media screen and (min-width: 1024px){
    .managerList {
        padding: 0;
        margin-top: var(--indent-40);
        gap: var(--indent-40);
    }
}
