.mapWrapper {
    position: relative;
    height: 200px;
    width: 100%;
}

@media screen and (min-width: 1024px){
    .mapWrapper {
        height: 300px;
    }
}

.mapWrapper .leaflet-div-icon {
    background-color: transparent !important;
    border: none !important;
}

.mapWrapper .leaflet-pane {
    z-index: var(--z-index-8)!important;
}

.openMapButton {
    position: absolute;
    left: 12px;
    bottom: 12px;
    z-index: var(--z-index-1000);
}

@media screen and (min-width: 1024px){
    .openMapButton {
        display: none;
    }
}