.modal {
  width: 100%;
  max-width: 750px;
  height: 420px;
  background: white;
  padding: 32px;
  border-radius: 24px;
  position: relative;
  outline: none;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--vh-100);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
}
