.title {
    display: none;
    text-align: center;

    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;

    color: var(--color-black);

    margin-bottom: 20px;
}

@media (min-width: 1024px) {
    .title {
        display: block;
    }
}

.buttonGroup {
    margin-bottom: 20px;
    margin-top: 24px;
}
@media (min-width: 1024px) {
    .buttonGroup {
        margin-bottom: 12px;
        margin-top: 20px;
    }
}