.wrapper {
}

.controlBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}


.controlBarEdit {
  display: flex;
  flex-direction: column-reverse;
}

@media (min-width: 1024px) {
  .controlBarEdit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.controlBarEditRight {
  margin: 0 -10px;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 1024px) {
  .controlBarEditRight {
    margin: 0 -11px 0 0;
  }
}

.controlBarRight {
    margin-right: -10px;
}

.status {
  margin-top: 11px;

  font-size: 16px;
  line-height: 16px;
  color: #b9b9b9;
}

.statusConfirmed {
  color: #20cb6a;
}


.hide {
  display: none;
}

.controlBarEditStatus {
  margin-top: 0;
}

@media (min-width: 1024px) {
  .controlBarEditStatus {
    margin-top: 11px;
  }
}