.wrapperProfileSection {
    background-color: #F3F3F5;
    padding: 24px 24px 12px 24px;
}
.wrapperMenuSection {
    padding: 12px 24px;
}
.wrapperLoginButtonWrapper {
    margin-bottom: 12px;
}

@media screen and (max-width: 768px) {
  .wrapperProfileSection {
    padding: 84px 24px 12px 24px;
  }
}

.bottomSheet {
  z-index: 14;
}

.wrapperBannerApp {
  margin-top: 20px;
  margin-bottom: 20px;
}