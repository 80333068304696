.searchWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px 16px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid var(--border-icon-color);
  background-color: var(--background-input-color);

  span {
    padding: 0;
  }

  input {
    background-color: var(--background-input-color);
    color: var(--text-color);
    &::placeholder {
      color: var(--plan-toggle-count-color);
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
    }
  }
}