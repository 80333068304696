.wrapper{
  position: relative;
  width: 100%;
  min-height: calc(var(--vh-100) - var(--navbar-height));
  padding: 16px 12px;
  background-color:var(--color-blue-50);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 1024px){
  .wrapper{
    padding-top: 140px;
    padding-bottom: 140px;
    min-height: auto;
  }
}
