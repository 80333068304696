.offersHeaderWrapper {
  padding: 12px;
  position: sticky;
  z-index: var(--z-index-3);
  top: -1px;
  background-color: #f3f3f5;
  margin: 0 -12px 12px;
}

@media (min-width: 768px) {
  .offersHeaderWrapper {
    top: 0;
    margin: 0;
    padding: 0 0 20px 0;
    background-color: #fff;
    max-width: 815px;
  }
}

.offersHeaderTop {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .offersHeaderTop {
    margin-bottom: 20px;
  }
}
