.badge {
    border-radius: 12px;

    text-transform: uppercase;
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
}
