.root {
  width: 100%;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.placeholder {
  color: rgba(64, 64, 64, 0.3);
  font-size: 14px;
  margin-top: 8px;
}
