.apartmentPriceWrapper {
  text-align: right;
}

.apartmentOldPrice {
  display: inline-block;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #404042;
}

.apartmentOldPrice:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 44%;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background-color: #dd6465;
}

.apartmentDiffPrice {
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
}

.apartmentDiffPriceIcon {
  padding-right: 6px;
  position: relative;
}
.apartmentDiffPriceIcon:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.apartmentDiffPriceUp {
  color: #20cb6a;
}

.apartmentDiffPriceUp:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 2.5px 4px 2.5px;
  border-color: transparent transparent #20cb6a transparent;
}

.apartmentDiffPriceDown {
  color: #dd6465;
}

.apartmentDiffPriceDown:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 2.5px 0 2.5px;
  border-color: #dd6465 transparent transparent transparent;
}

.apartmentPrice {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.apartmentPriceM2 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #404042;
}
