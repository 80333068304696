.modalSelectWrapper {
  background-color: var(--color-white);
  padding: 24px 12px 0;
}

@media screen and (min-width: 1024px) {
  .modalSelectWrapper {
    padding: 16px;
  }
}

.title {
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;

  display: none;
  color: var(--color-black);

  margin-bottom: 8px;
}

@media screen and (min-width: 1024px) {
  .title {
    display: block;
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0!important;
}


@media screen and (min-width: 1024px) {
  .list {
      /*max-height: 900px;*/
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
  }
}

.list li:not(:last-of-type) {
  margin-bottom: 10px;
}

.regionBtn:not(:first-of-type) {
  margin-top: 20px;
}

.regionListBtn {
  width: 100%;
  padding: 8px;
  transition: background-color .3s ease;
}

.regionListBtn:hover {
  border-radius: 4px;
  background-color: #F3F3F5;
  transition: background-color .3s ease;
}

.regionBtnContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

.regionBtnContentText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.regionBtnContentTitle {
  color: var(--text-color);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.regionBtnContentSubtitle {
  color: #AFB4CB;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  transition: color .3s ease;
}

.regionListBtn:hover .regionBtnContentTitle {
  color: #4E6AFF;
  transition: color .3s ease;
}

.desktopButtons {
    composes: border_shadow_position_top from '../../../../atoms/Border/styles.module.css';
    background-color: #f3f3f5;
    padding: 10px 60px;

    display: none;
    justify-content: space-between;
}

@media screen and (min-width: 1024px){
    .desktopButtons {
        display: flex;
    }
}


.mobileButtons {
    padding: 16px 12px;
    display: grid;
    gap: 8px;
}

@media screen and (min-width: 1024px){
    .mobileButtons {
        display: none;
    }
}
