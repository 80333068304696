
.filtersHeader {
    display: none;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: var(--indent-20);
}


@media (min-width: 1024px){
    .filtersHeader {
        display: flex;
        padding: 0 32px;
        align-items: center;
    }
}

.filtersHeaderBottom {
  /*display: none;*/
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: var(--indent-20);
  padding: 0 16px;

  button {
    font-size: 12px;
  }
}

@media (min-width: 1024px){
  .filtersHeaderBottom {
    width: 100%;
    display: flex;
    padding: 0 32px;
    align-items: center;
    font-size: 14px;
  }
}

.tabListMain {
  width: 100% !important;
}

.title {
  color: var(--text-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.closeButton {
  border: 1px solid var(--border-icon-color);
  background-color: transparent;
  border-radius: 8px;
}
