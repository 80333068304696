.td {
    position: relative;
    padding-top: 20px;
    padding-bottom: 19px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    border-bottom: var(--border-base);
}

.td:before {
    background-color: #ffffff;
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: calc(100% + 20px + 60px);
    height: 100%;
    z-index: var(--z-index-1);
}
