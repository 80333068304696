.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
}

@media (min-width: 1024px) {
  .wrapper {
    height: 280px;
    display: grid;
    grid-template-columns: auto 280px;
  }
}

.leftContent {
  display: flex;

  padding: 12px 12px 16px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 1024px) {
  .leftContent {
    padding: 60px;
    background-color: #f3f3f5;
  }
}

.rightContent {
  width: 100%;
  background-color: #f3f3f5;
}
@media (min-width: 1024px) {
  .rightContent {
    width: 280px;
  }
}

.image {
  display: block;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

@media (min-width: 1024px) {
  .image {
    height: 280px;
  }
}

.title {
  margin-top: 10px;
  margin-bottom: 10px;

  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;

  color: var(--color-black);
}

@media (min-width: 1024px) {
  .title {
    font-size: 32px;
    line-height: 38px;
  }
}

.date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #404042;
}
