.wrapper {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: var(--indent-25);
}

@media (max-width: 768px) {
    .wrapper {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }
}
