.root {
  background: #FFFFFF;
  border: 1px solid #DFDFEB;
  box-sizing: border-box;
  border-radius: 8px;

  height: 40px;
  width: 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 4px;

  position: relative;
}

.root:before {
  display: block;
  content: '';

  position: absolute;
  top: 41%;
  bottom: 50%;
  right: 10px;

  height: 7px;
  width: 12px;

  background-size: cover;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 4L9 1' stroke='%23B9B9B9' stroke-width='1.5'/%3E%3C/svg%3E%0A");

  transition: .1s transform;
}

.withTags {
  padding: 12px 16px;
  flex-wrap: wrap;
  min-height: 46px;
  height: unset;
  border: 1px solid var(--border-icon-color);
  background: var(--background-input-color);
  border-radius: 8px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .withTags {
    padding: 10px 11px;
    min-height: 40px;
  }
}

.isOpen {
  border-radius: 8px;
  border: 2px solid #4E6AFF;
}

.isOpen:before {
  transform: rotate(180deg);
}

/* .tag {
  font-size: 12px;
  line-height: 1;
  background: #DFDFEB;
  border-radius: 4px;
  padding: 4px;
  width: max-content;
  overflow: hidden;
  max-width: 100%;
  white-space: pre;
} */

.tag:not(:last-child) {
  margin-right: 4px;
}

.withTags:not(:empty) {
  padding: 5px 28px 5px 16px;
  border-radius: 8px;
  min-height: 46px;
}

@media screen and (max-width: 768px) {
  .withTags:not(:empty) {
    min-height: 40px;
  }
}

.withTags:not(:empty) .tag {
  margin-bottom: 4px;
  border-radius: 8px;
}

.isOpen {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.option {
  padding: 5px;
  transition: background .3s;
  cursor: pointer;
  display: block;
}

.option:hover {
  background: var(--hover-toogle-plan);
}

.placeholder {
  color: #AFB4CB;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

@media screen and (max-width: 768px) {
  .placeholder {
    font-size: 14px;
  }
}

.dropdownOverlay {
  background: var(--background-dropdown-color);
  padding: 16px !important;
  border-radius: 8px;
}

.bottomSheetHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.bottomSheetHeader h2 {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
}

.closeBtn {
  background: none;
  border: none;
  cursor: pointer;
}

.disabledContainer {
  cursor: not-allowed;
}

.disabled {
  background-color: #f3f3f3;
  pointer-events: none;
}

.overlayWrapper {
  max-height: 298px;
  overflow-x: auto;
  box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media (max-width: 768px) {
  .overlayWrapper {
    width: calc(100% - 32px);
    /*min-width: auto!important;*/
  }
}

.disctrictsOverlay {
  z-index: var(--z-index-14);
  background-color: rgb(0 0 0 / 70%);
}

.dispalyingValueWrapper {
  margin-top: 3px;
}

.dispalyingValueWrapper:not(:last-of-type) {
  margin-right: 3px;
}
