.content {
  font-family: Circe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Base black */

  color: #404042;
}
