.header {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  bottom: -1px;
}

.map {
  background-color: #ffffff;
  object-fit: contain;
  margin: 0 auto;
  height: auto;
  width: auto;
  max-width: calc(100vw - 120px);
  max-height: calc(100vh - 120px);
}

@media screen and (max-width: 768px) {
  .map {
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 80px);
  }
}

.mapWrapper {
  position: absolute;
  z-index: 2;
  top: calc(50% - 30px);
  transform: translate(0%, -50%);
}
