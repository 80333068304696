.profileMenuHeader {
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
}

.offersHeaderTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .offersHeaderTop {
    margin-bottom: 20px;
  }
}
