.divider {
    display: block;
    width: 100%;
    border: 1px solid #DFDFEB;
    margin: 0;
}

.top {
    margin-top: 20px;
}
.bottom {
    margin-bottom: 20px;
}
