
.mainInfoAvatarWrapper {
  width: 120px;

  grid-column: 1/2;
  grid-row: 1/-1;
  margin: 0 auto 10px;
}

@media (max-width: 768px) {
  .mainInfoAvatarWrapper {
    width: 120px;
  }
}

.mainInfoAvatarError {
  text-align: center;
}

.mainInfoAvatar {
  display: block;
  width: 120px;
  height: 120px;
  position: relative;

  background: transparent;
  padding: 0;
  border: none;

  margin-top: 10px;

  cursor: pointer;
}

@media (max-width: 768px) {
  .mainInfoAvatar {
    justify-self: center;
    margin-bottom: 16px;
  }
}

.mainInfoAvatar:hover::after {
  position: absolute;
  display: block;
  content: '';

  background-image: url('../../../../../../assets/camera.svg');
  width: 48px;
  height: 48px;
  left: calc(50% - 24px);
  top: 15%;
}

.mainInfoAvatar:hover::before {
  position: absolute;
  display: block;
  content: 'Загрузить фото';

  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;

  bottom: 20px;
  left: 20px;
  z-index: 223;
  max-width: 70%;
}


.mainInfoAvatarLoader {
  display: block;
  width: 120px;
  height: 120px;

  margin-top: 10px;
  border-radius: 100px;
}


.avatarImage {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.userInfoBlock {
  display: none;
}

@media (max-width: 768px) {
  .userInfoBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 24px;
  }

  .userInitials {
    font-size: 16px;
    line-height: 16px;

    color: #000000;

    margin-bottom: 8px;
  }

  .userRole {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #404042;
  }
}
