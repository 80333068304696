.truncateHtmlWrapper {
    max-width: 100%;
}
.truncateHtmlWrapper * {
    width: auto!important;
}

.truncateHtmlContent, .truncateHtmlContent > * {
    color: var(--color-gray-900);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    width: 100%;
    overflow: hidden;
}

/* сброс стилей визивика */
.truncateHtmlContent > * {
    width: auto!important;
    min-width: auto!important;
    max-width: inherit!important;
    height: auto!important;
    min-height: auto!important;
    max-height: inherit!important;
}

.truncateHtmlContent a {
    color: var(--color-blue) !important;
}
.truncateHtmlContent a:visited {
    color: var(--color-red) !important;;
}


.is_hidden {
    height: 0;
    visibility: hidden;
}

.toggleButtonWrapper {
    display: grid;
    grid-gap: var(--indent-10);
    grid-template-columns: 1fr max-content 1fr;
    align-items: center;
    width: 100%;
}

@media (min-width: 1024px){
    .toggleButtonWrapper {
        grid-gap: var(--indent-30);
    }
}

.isOpen {
    transform: rotate(-180deg);
}

.oneLineButton {
    margin-bottom: 5px;
}

.oneLineButton + a {
    text-decoration: none;
    display: inline;
    align-items: center;
    text-align: center;
    font-family: Circe;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #4E6AFF;
    cursor: pointer;

    background-color: transparent;
    border: none;
    padding: 0;
    margin-top: 10px;
}
