.mainInfoWrapper {
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 40px;
  grid-template-rows: 1fr 1fr;
}

@media (max-width: 768px) {
  .mainInfoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.formField {
  width: 100%;
}
.formField:not(:last-of-type) {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .formField {
    margin-bottom: 24px;
  }
}

.formMainField {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .formMainField {
    margin-bottom: 24px;
  }
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .buttonGroup {
    flex-direction: column;
  }

  .buttonGroup button {
    margin: auto;
    width: 100%;
  }

  .buttonGroup button + button {
    margin-top: 8px;
  }
}
