.priceWrapper {
  display: flex;
  gap: 8px;
  max-width: 423px;

  input {
    width: 50%;
  }
}

.priceItem {
  padding: 9px 8px;
  color: #4E6AFF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.priceItem:hover {
  border-radius: 4px;
  background-color: #F3F3F5;
  transition: background-color 0.3s ease;
}

