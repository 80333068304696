.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: Circe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #4E6AFF;
  cursor: pointer;

  background-color: transparent;
  border: none;
  padding: 0;
  width: 100%;
  margin-top: 10px;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1 0 auto;
  border-bottom: 1px solid #DFDFEB;
}

.separator::before {
  margin-right: 30px;
}

.separator::after {
  margin-left: 30px;
}

.iconUp {
  margin-left: 4px;
}

.iconDown {
  margin-left: 4px;
  transform: rotate(180deg);
}
