.bannerWrapper {
  position: relative;
  width: 100%;
  //padding-top: 30px;
  margin-bottom: 14px;
  z-index: 1;
}

.bannerInner {
  display: block;
  position: relative;
  border-radius: 16px;
  background: #F3F3F5;
  //overflow: hidden;
  z-index: 1;
  min-height: 157px;
  overflow: hidden;
}

.banner {
  display: block;
  color: #333338;
  padding: 24px;
  text-decoration: none;
  font-family: Inter;
  border-radius: 16px;
  padding: 24px;

  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}

//.bannerColorRight {
//  position: absolute;
//  top: 0;
//  right: 0;
//  width: 700px;
//  height: 100%;
//  background: linear-gradient(110deg, #0C6EFF 12.22%, #5200D7 136.68%);
//  clip-path: ellipse(57% 144% at 105% 78%);
//  border-radius: 16px;
//}

.bannerColorRight {
  position: absolute;
  top: 0px;
  right: -40px;
  width: 100%;
  max-width: 420px;
  height: calc(100% + 20px);

  img {
    width: 100%;
    height: 100%;
  }
}

.phoneImgRight {
  position: absolute;
  right: 120px;
  bottom: -24px;
  z-index: 3;
  width: 280px;

  img {
    width: 100%;
    object-fit: contain;
  }
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 12px;
  right: 12px;
  background: transparent;
  border-radius: 8px;
  border: 1px solid #DFDFEB;
  cursor: pointer;
  z-index: 2;

  img {
    width: 16px;
    height: 16px;
    min-width: 16px;
  }
}

.content {
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media screen and (max-width: 1440px) {
    align-items: flex-start;
  }
}

.textContainer {
  width: 100%;
  max-width: 560px;

  .title {
    color: #333338;
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
  }

  .description {
    color: #333338;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }
}

.logoContainer {
  width: 60px;
  height: 60px;

  img {
    width: 100%;
    object-fit: contain;
  }
}

.bannerWrapperMiddle {
  .textContainer {
    max-width: 286px;
  }

  .title {
    font-size: 24px;
    line-height: 100%;
  }

  .bannerColorRight {
    max-width: 380px;
  }

  .phoneImgRight {
    right: 80px;
  }

}

.bannerWrapperSmall {
  .bannerInner {
    min-height: 252px;
  }

  .bannerColorRight {
    max-width: 400px;
    position: absolute;
    top: -20px;
    right: -214px;
    width: 100%;
    height: calc(100% + 20px);
  }

  .phoneImgRight {
    right: 4px;
    width: 290px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .textContainer {
    max-width: 210px;

    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 100%;
    }

    .description {
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      max-width: 195px;
    }
  }
}

.rustoreIcon {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.rustoreIconSmall {
  left: 24px;
  bottom: 16px;
}

@media screen and (max-width: 768px) {
  .bannerWrapper {
    padding-top: 0;
    .bannerInner {
      height: 149px;
      max-height: 149px;
      min-height: 149px;
    }

    .bannerColorRight {
      right: -241px;
    }

    .phoneImgRight {
      width: 160px;
      bottom: 0;
      right: 38px;
      z-index: 1;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    .textContainer {
      max-width: 208px;

      .title {
        font-size: 16px;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 4px;
      }

      .description {
        font-size: 12px;
        font-weight: 500;
        line-height: 140%;
        max-width: none;
      }
    }

    .logoContainer {
      width: 33px;
      height: 33px;
    }

    .rustoreIcon {
      width: 70px;
      min-width: 70px;
      position: absolute;
      left: auto;
      right: 10px;
      bottom: 10px;
      z-index: 4;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .bannerWrapper {

    .bannerColorRight {
      right: -210px;
    }

    .textContainer {
      max-width: 180px;
    }
  }
}
