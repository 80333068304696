.wrapper {
    display: flex;
    flex-direction: column;
    /*height: 255px;*/
    height: auto;
}

@media screen and (max-width: 768px) {
  .wrapper {
    margin-top: 20px;
  }
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 39px;
}

.mapWrapper {
    width: 100%;
    height: 0;
    transition: height 0.4s ease-in-out;
    @media screen and (max-width: 768px) {
      height: 255px;
    }
}

.mapWrapper img {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.mapWrapperExpanded {
  height: 216px;
  transition: height 0.4s ease-in-out;
}

.mapWrapperExpanded img {
  display: block;
}

.map {
    background-color: #FFFFFF;
    /*box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);*/
    height: 255px;
    width: 100%;
    z-index: var(--z-index-3);
}

.toggleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  gap: 10px;
  width: 100%;
  background: #FFFFFF;
  padding: 8px 20px;
  /*box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.1);*/
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.toggleButton {
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  color: var(--text-color);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.dropDownNotExpanded {
  rotate: 0;
  transition: rotate .4s ease-in-out;
}

.dropDownExpanded {
  rotate: 180deg;
  transition: rotate .4s ease-in-out;
}
