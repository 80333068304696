.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wrapper > * {
    margin-bottom: 10px;
}
