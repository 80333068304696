@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
  outline: none;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.grecaptcha-badge {
  display: none!important;
}

.react-tiny-popover-container {
  z-index: var(--z-index-14);
}

.rc-dropdown {
  max-width: 100%;
}

.ReactModal__Overlay {
  background-color: rgba(0,0,0,0);
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  background-color: rgba(0,0,0,0.7);
}

.ReactModal__Overlay--before-close{
  background-color: rgba(0,0,0,0);
}

.mainNavbar {
  width: 100%;  
  height: var(--navbar-height);
  position: relative;
}

iframe {
  pointer-events: none;
}


.pompon {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  div:not(:last-child):after {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #DFDFEB;
    border-radius: 50%;
    margin-left: 8px;
    vertical-align: middle;
  }
}
